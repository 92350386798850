import React, { useEffect, useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { pad } from '../../Common/home-common';
import { FootballContext } from '../../Home/FootballPage';
import MoreButton from '../../Common/ui-components/MoreButton'

const FGSTable = ({foPool, isMobile, getOddsVal ,DefaultDisplayumber,betType, foPools}) => {

    const context = useContext(FootballContext);
    const { t, i18n } = useTranslation();
    const [isShow,setIsShow] = useState(DefaultDisplayumber!=undefined) 
    const [showStatus,setShowStatus] = useState(false)
    const [isShowRed, setIsShowRed] = useState(false)
    const isResultsPage = context.content.page == 'RESULTS'
    let oddsLines = [];
    const line = foPool.lines[0];
    let homeCombs = []
    let awayCombs = []
    line.combinations.forEach(comb => {
        // if(['UNAVAILABLE'].includes(comb.status) && [...window.fbConfig.SingleMatchPages,...window.fbConfig.MixAllUpDetailPages ].includes(context.content.page)) {
        //     let isUnavailable = true
        //     foPools.forEach(pool =>{
        //         if(window.fbConfig.GSPools.includes(pool.oddsType)){
        //             let currentComb = pool.lines[0].combinations.find(i => i.str == comb.str)
        //             if(currentComb?.status != 'UNAVAILABLE') isUnavailable = false
        //         }
        //     })
        //     if(isUnavailable) return
        // }
        if(['UNAVAILABLE', 'REFUND'].some(i => comb.status.includes(i)) && !isResultsPage) return
        if(comb.str[0] == '1'){
            homeCombs.push(comb)
        }else if(comb.str[0] == '2'){
            awayCombs.push(comb)
        }
    });
    let sortGSComb = (a, b) => {
        if(a.currentOdds != b.currentOdds) return a.currentOdds - b.currentOdds
        return a.str - b.str
    }
    homeCombs = homeCombs.sort(sortGSComb)
    awayCombs = awayCombs.sort(sortGSComb)

    let playerSize = Math.max(homeCombs.length, awayCombs.length) - 1
    
    for (let i = 0; i <= playerSize; i++) {
        let homeComb = homeCombs[i]?.str;
        let awayComb = awayCombs[i]?.str;
        let homeOdds = homeCombs[i];
        let awayOdds =  awayCombs[i];

        if (homeOdds == null && awayOdds == null) {
            continue;
        }

        let hNo = null;
        let hName = null;
        let aNo = null;
        let aName = null;
        if ( homeOdds != null ) {
            hNo = homeOdds.selections?.[0]?.str;
            hName = homeOdds.selections?.[0]?.['name_' + i18n.language];
        }
        if ( awayOdds != null ) {
            aNo = awayOdds.selections?.[0]?.str;
            aName = awayOdds.selections?.[0]?.['name_' + i18n.language];
        }
        const homeOddsGetOddVal = homeOdds && getOddsVal(line, homeComb, isMobile, null, null, null, betType)
        const awayOddsGetOddVal = awayOdds && getOddsVal(line, awayComb, isMobile, null, null, null, betType)
        const homeOddsGetOddValCheck = homeOddsGetOddVal?.props?.children?.props?.children[1]?.props?.children?.props?.checked
        const awayOddsGetOddValCheck = awayOddsGetOddVal?.props?.children?.props?.children[1]?.props?.children?.props?.checked
        // console.log(homeOddsGetOddValCheck,awayOddsGetOddValCheck,i)

        if (!isShowRed &&i >= DefaultDisplayumber &&  (homeOddsGetOddValCheck || awayOddsGetOddValCheck)) {
            setIsShowRed(true)
        }
        oddsLines.push(<div className={`match-row${isMobile ? '-m' : ''}`}>
            <div className='odds'>
                <div>{hNo} {hName}</div>
                <span></span>
                {homeOddsGetOddVal}
            </div>
            <div className='odds'>
                <div>{aNo} {aName}</div>
                <span></span>
                {awayOddsGetOddVal}
            </div>
        </div>)
    }
    let noScorerComb = '000';
    let noScorerOdds = line.combinations.filter(x => x.str === noScorerComb)[0];
    let nNo = noScorerOdds?.selections?.[0]?.str;
    if ( nNo=="000")
        nNo = "00";
    let nName = noScorerOdds?.selections?.[0]?.['name_' + i18n.language];
    if( !nName || nName== "" ) {
        nName = t(`LB_FB_${betType}_NOFIRSTSCORER`);
    }
    if ( ['FGS', 'LGS'].includes(betType) &&  (!['UNAVAILABLE', 'REFUND'].some(i =>  noScorerOdds?.status?.includes(i)) || isResultsPage)) {
        const getOddsValDom= getOddsVal(line, noScorerComb, isMobile, null, null, null, betType)
        const getOddsValDomCheck = getOddsValDom?.props?.children?.props?.children[1]?.props?.children?.props?.checked
        if (!isShowRed && getOddsValDomCheck) {
            setIsShowRed(true)
        }
        oddsLines.push(<div className={`match-row${isMobile ? '-m' : ''} zero`}>
            <div className='odds'>
                <div>{nNo} {nName}</div>
                <span></span>
                {getOddsValDom}
            </div>
        </div>)
    }
    const newOddsLines = isShow ? oddsLines.slice(0,!showStatus ? DefaultDisplayumber : oddsLines.length) : oddsLines
    useEffect(()=>{
        setIsShowRed(false)
    },[context.content.oddsSelection])
    useEffect(()=>{
        setIsShow(DefaultDisplayumber!=undefined && oddsLines.length >3)
    },[DefaultDisplayumber, oddsLines])
    return <div className='match-row-container match-table FGS'>
        {newOddsLines}
        {isShow && <MoreButton showLessText="LB_FB_SHOW_LESS_TEXT" showMoreText="LB_FB_SHOW_MORE_TEXT" MoreButtonClick={()=>{setShowStatus(!showStatus)}} expand={showStatus} showLine={newOddsLines.length % 2} id={betType + '_showButton'} isShowRed={isShowRed}></MoreButton>}
        </div>;
}
export default FGSTable