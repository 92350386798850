import React, { useEffect, useState, useRef } from 'react';
import { formatNumberDecimals } from '../../GlobalFunc';
import { useWindowSize } from '../../CommonHooks';
import './index.scss';



const AmountInput = (props) => {
    const {
        onChange,
        value,
        disabled,
        className = '',
        maxLength = 13,
        min = 0,
        max = 999999999999,
        maxErrMsg,
        minErrMsg,
        decimals = 2
    } = props;
    const [amountCache, setAmountCache] = useState('-');
    const oldVlaueRef = useRef('-');
    const { isMobileSize } = useWindowSize();
    const [showValue, setShowValue] = useState('-');

    useEffect(() => {
        if (amountCache && amountCache != '-') {
            onChange(amountCache);
        }
        setShowValue(formatNumberDecimals(amountCache))
    }, [amountCache]);

    useEffect(() => {
        if (value) {
            oldVlaueRef.current = value;
        }
    }, []);

    useEffect(() => {
        if (value) {
            setAmountCache(value);
        } else if (value === '') {
            setAmountCache('-');
        }
    }, [value]);

    const onInputAmount = (e) => {
        let value = e.target.value;
        if (isMobileSize && e.target.getAttribute('data-init') == 1) {
            if (e.nativeEvent.data != null) {
                value = e.nativeEvent.data;
            }
            e.target.setAttribute('data-init', 0);
            e.target.setAttribute('maxLength', maxLength);
        }
        if (/[^\d.]/g.test(value)) {
            value = value.replace(/[^\d.]/g, '');
        }
        if (value === '.') {
            setAmountCache(value);
        } else if (!isNaN(Number(value))) {
            if (!/\.[0-9]{3,}/.test(value)) {
                setAmountCache(value);
            } else {
                setAmountCache(value.substring(0, value.indexOf('.') + 3));
            }
        } else {
            // alert('Illegal input');
        }
    };

    const [isFocus, setIsFocus] = useState(false);
    const onFocusAmount = (e) => {
        if (isMobileSize) {
            e.preventDefault();
            e.target.setAttribute('data-init', 1);
            e.target.removeAttribute('maxLength');
            e.target.setSelectionRange(10, 10);
            setTimeout(function () {
                e.target.setSelectionRange(10, 10);
            });
        }
        setIsFocus(true);
        if (e.target.value === '-') {
            setAmountCache('');
        }
    };

    const onBlurAmount = (e) => {
        setIsFocus(false);
        let isErr = false;
        if (isNaN(Number(amountCache)) || Number(amountCache) <= 0) {
            // alert('Illegal input')
            isErr = true;
        } else if (parseFloat(amountCache) < min) {
            isErr = true;
            minErrMsg && alert(minErrMsg);
        } else if (parseFloat(amountCache) > max) {
            isErr = true;
            maxErrMsg && alert(maxErrMsg);
        }
        if (isErr) {
            setAmountCache(oldVlaueRef.current);
            setShowValue(formatNumberDecimals(oldVlaueRef.current, decimals))
        } else {
            setShowValue(formatNumberDecimals(amountCache, decimals))
            oldVlaueRef.current = amountCache;
        }
    };
    // console.log(isFocus, amountCache, isFocus || amountCache === '-' || amountCache === '', showValue )
    return (
        <div className={`HKJCAmountInputContainer ${className}`}>
            <span>$</span>
            <input
                autoComplete="off"
                maxLength={maxLength}
                type="text"
                inputMode="numeric"
                disabled={disabled}
                onInput={onInputAmount}
                onFocus={onFocusAmount}
                onBlur={onBlurAmount}
                className="AmountInput"
                value={showValue}
            />
        </div>
    );
};

export default AmountInput;
