import React, { useEffect, useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';

import AddSlipBtn from '../Common/AddSlipBtn';
import OddsTable from '../Common/RCOddsTableCompact';
import OddsDropLegend from '../Common/OddsDropLegend';
import InvestmentCalculator from '../Common/InvestmentCalculator';
import Investment from '../Common/Investment';
import {getComingleMsgs, getMaxRunnerSize, getIsShowPmuText} from '../Common/Common';
import MultiLegSlider from '../Common/MultiLegSlider';
import { RacingContext } from '../../Home/RacingPage';
import ComingleMsg from '../Common/ComingleMsg'
import RefreshTime from '../../Common/ui-components/RefreshTime';
import Loading from '../../Common/ui-components/Loading';
import HandleMultiLeg3xTablesTrHeight from '../Common/HandleMultiLeg3xTablesTrHeight';
import { useWindowSize, getDeviceType } from '../../Common/CommonHooks';
import { Swiper, SwiperSlide } from 'swiper/react';

const Odds6UP = (props) => {
    const { t, i18n } = useTranslation();
    const context = useContext(RacingContext);
    const { isTablet, isMobile } = getDeviceType();
    const [isLogin, setIsLogin] = useState(true);

    HandleMultiLeg3xTablesTrHeight();
    const { width, isMobileSize } = useWindowSize();

    const RCColumns = {
        no: true,
        banker1: false,
        banker2: false,
        banker3: false,
        leg: true,
        horseName: true,
        rcNoteM: false,
        win: true,
        place: false,
        field: true,
        banker1F: false,
        banker2F: false,
        banker3F: false
    }

    const RCColumnLbls = {
        leg: t('LB_RC_ODDS_SEL')
    }

    if (context == null) {
        return <Loading/>
    } else {
        const mtg = context.content.meeting;
        let pool =  mtg.pmPools.filter((data) => data.leg.races[0] == context.content.raceNo && data.oddsType=="SixUP")[0];
        if ( pool==null )
            return null;

        let legList = pool.leg.races;

        let tmpMsgs = getComingleMsgs(mtg, context.content.page, context.content.raceNo);
        const isShowPmu = getIsShowPmuText(mtg, legList)
        let comingleMsg1 = [];
        if (tmpMsgs != null) {
            let comingleLang = i18n.language == "en" ? "E" : "C";
            if ( tmpMsgs['NOTE1_' + comingleLang]!='' )
            comingleMsg1.push(<div className="rc-comingle-remarks"><span style={{ fontWeight: "bold" }}>{t('LB_RC_COMINGLE_NOTE')}1: </span>{tmpMsgs['NOTE1_' + comingleLang]}</div>);
            if ( tmpMsgs['NOTE2_' + comingleLang]!='' )
                comingleMsg1.push(<div className="rc-comingle-remarks"><span style={{ fontWeight: "bold" }}>{t('LB_RC_COMINGLE_NOTE')}2: </span>{tmpMsgs['NOTE2_' + comingleLang]}</div>);
            if ( tmpMsgs['NOTE3_' + comingleLang]!='' )
            comingleMsg1.push(<div className="rc-comingle-remarks"><span style={{ fontWeight: "bold" }}>{t('LB_RC_COMINGLE_NOTE')}3: </span>{tmpMsgs['NOTE3_' + comingleLang]}</div>);
        }

        return (
            <section className="SIXUP">
                <Investment betType="SixUP" showJpt={true} showEstDiv={true} />
                <InvestmentCalculator enable={true} />
                <RefreshTime product="racing" />
                <MultiLegSlider cnt={legList.length} className="multi-leg-3x-table">
                    {legList.map((_cItem, _cIndex) => {
                        let defTableSize = 14;
                        let group = width < 1280 ? 2 : 3;
                        if (width < 1023 && ( isMobile || isTablet )) {
                            group = 6
                        }                     
                        const start = parseInt(_cIndex / group) * group
                        const end = (parseInt(_cIndex / group) + 1) * group
                        defTableSize = Math.max(defTableSize, getMaxRunnerSize(context, legList, start, end));
                        return <SwiperSlide><OddsTable betType="SixUP" columns={RCColumns} lbl={RCColumnLbls}
                            firstRace={legList[0]} raceNo={_cItem} startCnt={1}
                            selectedBetType="SixUP" showRaceHeader="true" defTableSize={defTableSize} /></SwiperSlide>
                    })}
                </MultiLegSlider>
                {isShowPmu && <div className="merge-comingle-remarks"><ComingleMsg></ComingleMsg></div>}
                <OddsDropLegend />
                <div className="multi-leg-footer-betSlip">
                    <div><AddSlipBtn /></div>
                </div>
                <InvestmentCalculator enable={!context.windowSize.isMobileSize} />
            </section>
        );
    }
}
export default Odds6UP;