import React, { useContext, useState } from 'react';
import { onClickCheckBox } from './AddBet';
import { Coupon, SingleBetypePageCoupon, sortTournOdds, AcceptInplayOnlyLink, isInplayMatch } from './TournamentTable';
import { useTranslation } from 'react-i18next';
import { FootballContext } from '../../Home/FootballPage';
import { formatOdds, isEliminated, isRefund, isSellingStarted } from './Common';
import { getOddsArrowCss } from './MatchBase';
import CheckBox from '../../Common/ui-components/CheckBox';
import ArrowIcon from '../../Common/ui-components/ArrowIcon';

const GeneralTournTable=({betType, isMatch, match, tourn, isSingleBetPage})=>{
    const { t, i18n } = useTranslation();
    const context = useContext(FootballContext);
    const [expand, setExpand] = useState(true);
    const [showAll, setShowAll] = useState(false);
    const [expandSel, setExpandSel] = useState(false);
    const [checkboxState, setCheckboxState] = useState([]);
    const foPool = (isMatch ? match : tourn).foPools.filter(x=> x.oddsType===betType)[0];
    const combs = foPool?.lines?.[0]?.combinations || [];

    const toggle = () => {
        setExpand(!expand)
    }

    const toggleSel = () => {
        setExpandSel(!expandSel)
    }

    const toggleShowAll = () => {
        setShowAll(!showAll)
    }



    let showTitle = false;
    let title = `${t('LB_FB_TITLE_'+betType)} ${tourn.frontEndId}`;
    switch ( foPool?.chpType ) {
        case "1":
            title = `${t('LB_FB_TITLE_WINCNTY')} ${tourn.frontEndId}`;
            break;
        case "2":
            title = `${t('LB_FB_TITLE_WINCNTT')} ${tourn.frontEndId}`;
            break;
        case "3":
            title = `${t('LB_FB_TITLE_FINALIST')} ${tourn.frontEndId}`;
            break;
    }
    if ( context.content.config.AllOddsPages.includes(context.content.page) ) {
        title = t('LB_FB_TITLE_'+betType);
        showTitle = true;
    }

    const TableProps ={
        combs, betType, tourn, foPool, context,
        isMatch, match, setCheckboxState, expandSel, toggleSel,
        showAll
    }

    return foPool!=null ?
        <section className={`${betType} coupon${betType}`}>
            {isSingleBetPage ?
                <>
                    <SingleBetypePageCoupon showTitle={true} handleOnClick={toggle} isChecked={expand} betType={betType}
                        esst={foPool.expectedSuspendDateTime} isShowAll={showAll} handleOnClickShowAll={toggleShowAll} singleTourn={tourn} isShowSupended={!isSellingStarted(tourn, foPool)} />
                    { expand ? <>
                        <GeneralSingleTournTable {...TableProps}/>
                    </> : null }
                </>:
                <>
                    <Coupon frontEndId={tourn.frontEndId} title={title} handleOnClick={toggle} isChecked={expand} showHelpBtn={true} betType={betType} singleTourn={tourn} isShowSupended={false /* betType=="CHP" && !isSellingStarted(tourn, foPool) 放在了下面 */} />
                    { expand ? <>
                        {betType=="CHP" && !isSellingStarted(tourn, foPool) && !context.state.windowSize.isMobileSize && <div className='lb-suspended'>{t('LB_FB_SUSPENDED')}</div>}
                        <SingleBetypePageCoupon showTitle={showTitle} betType={betType}
                            esst={foPool.expectedSuspendDateTime} isShowAll={showAll} handleOnClickShowAll={toggleShowAll} singleTourn={tourn} isShowSupended={!isSellingStarted(tourn, foPool) && context.state.windowSize.isMobileSize}/>
                        <GeneralSingleTournTable {...TableProps}/>
                    </> : null }
                </>
            }
        </section>
    : null
}
export default GeneralTournTable;

const GeneralSingleTournTable = (props) => {
    const {isMatch, foPool, betType, combs, showAll, match, context} = props
    if (!isMatch && foPool.match && foPool.inplay && isInplayMatch(foPool.match.status)) {
        return <AcceptInplayOnlyLink match={foPool.match} betType={betType} />
    }

    if(!isMatch){
        combs.sort(sortTournOdds);
    }
    let sels = combs.filter(x=> showAll || !isEliminated(x));
    if ( isMatch ) {
        let homeComb = sels.filter(x => x.selections[0].name_en===match.homeTeam.name_en)[0];
        let awayComb = sels.filter(x => x.selections[0].name_en===match.awayTeam.name_en)[0];
        if ( homeComb!=null && awayComb!=null ) {
            sels = sels.filter(x=> x.combId!=homeComb.combId && x.combId!=awayComb.combId );
            sels.unshift(homeComb, awayComb);
        }
    }

    return context.state.windowSize.isMobileSize ?
        <GeneralSingleTournTableMobile {...props} combs={sels} /> :
        <GeneralSingleTournTableDesktop {...props} combs={sels} />
}

const GeneralSingleTournTableDesktop = ({combs, betType, tourn, foPool, context, isMatch, match, setCheckboxState}) => {
    const { t, i18n } = useTranslation()
    let headerArr = [];
    let bodyArr = [];
    let size = combs.length;
    let gridCss = size > 2 ? 'tournSelections' : 'tournSelections2';
    headerArr.push(<>
        <div className="tournSelHeader">{t('LB_FB_'+ betType + '_TEAM')}</div>
        <div className="tournSelHeader"></div>
        <div className="tournSelHeaderOdds">{t('LB_ODDS')}</div>
        <div className="tournSelHeader">{t('LB_FB_'+ betType + '_TEAM')}</div>
        <div className="tournSelHeader"></div>
        <div className="tournSelHeaderOdds">{t('LB_ODDS')}</div>
        { size > 2 ? <>
            <div className="tournSelHeader">{t('LB_FB_'+ betType + '_TEAM')}</div>
            <div className="tournSelHeader"></div>
            <div className="tournSelHeaderOdds">{t('LB_ODDS')}</div>
            <div className="tournSelHeader">{size > 3 ? t('LB_FB_'+ betType + '_TEAM') : null}</div>
            <div className="tournSelHeader"></div>
            <div className="tournSelHeaderOdds">{size > 3 ?t('LB_ODDS') : null}</div>
            </> : null }
    </>);

    let col = [[], [], [], []];
    let rowSize = Math.floor(combs.length / 4);
    let colLength = [
        rowSize + (combs.length % 4 > 0 ? 1 : 0),
        rowSize + (combs.length % 4 > 1 ? 1 : 0),
        rowSize + (combs.length % 4 > 2 ? 1 : 0),
        rowSize];
    let idx = 0;
    for ( let i=0; i<4; i++ ) {
        for ( let j=0; j<colLength[i]; j++ ) {
            col[i].push(combs[idx++]);
        }
    }
    for ( let i=0; i<colLength[0]; i++ ) {
        let cssStr = i%2==0 ? 'odd': 'even';
        for ( let j=0; j<4; j++ ) {
            if (col[j][i]) {
                let comb = col[j][i];
                let odds = comb.currentOdds;
                let isDisabled = isEliminated(comb) || isRefund(comb) || !isSellingStarted(tourn, foPool, foPool.lines[0]) || comb.status!="AVAILABLE";
                let id = betType + '_' + foPool.lines[0].lineId + "_" + comb.combId  + "_"  + foPool.id + "_" + comb.str;
                let curState = context.content.oddsSelection.filter(x => x.id == id)[0];
                let isMatchNotPrev= match?.status != 'PREEVENT';
                curState = {...curState,
                    id: id,
                    checked: curState?.checked || false,
                    matchId: isMatch ? match.id : tourn.id,
                    oddsVal: odds
                }
                let arrowCss = getOddsArrowCss(curState, isMatch && isDisabled && isMatchNotPrev)
                let oddsVal = formatOdds(t, odds, comb.status)
                let isNaNVal = isNaN(oddsVal)
                bodyArr.push(<>
                    <div className={`tournSelName ${cssStr}`}>{comb.selections[0].str} {comb.selections[0]['name_'+i18n.language]}</div>
                    <div className={`tournSelChkbox ${cssStr}`}>
                        <CheckBox product='football' id={`fb_${id}`} classNameBox={`${id} ${isDisabled && 'dim'}`} onClick={() => onClickCheckBox(curState, isDisabled, context, setCheckboxState)} defaultChecked={curState.checked} disabled={isDisabled} />
                    </div>
                    <div className={`tournSelOdds ${cssStr} oddsColor ${arrowCss}`}>
                        <div className='odds'>{isMatch && isDisabled && isMatchNotPrev ? isNaNVal? oddsVal :  "---" : oddsVal}</div>
                        <div className={`oddsArrow ${arrowCss}`}></div>
                    </div>
                </>);
            }
            else if (size > 2) {
                bodyArr.push(<>
                    <div className={`tournSelName ${cssStr}`}></div>
                    <div className={`tournSelChkbox oddsCheckbox ${cssStr}`}></div>
                    <div className={`tournSelOdds ${cssStr}`}></div>
                </>);
            }
        }
    }

    return <div className={gridCss}>
            {headerArr}
            {bodyArr}
        </div>
}

const GeneralSingleTournTableMobile = ({combs, betType, tourn, foPool, context, isMatch, match, setCheckboxState, expandSel, toggleSel}) => {
    const { t, i18n } = useTranslation()
    let size = combs.length;
    return <>
        <div className="tournSelections">
        <div className="tournSelHeader">{t('LB_FB_'+ betType + '_TEAM')}</div>
        <div className="tournSelHeaderOdds">{t('LB_ODDS')}</div>
        <div className="tournSelHeader"></div>
        {combs.map((comb, index) => {
            if ( index >=8 && !expandSel ) {
                return null;
            }
            let odds = comb.currentOdds;
            let isDisabled = isEliminated(comb) || isRefund(comb) || !isSellingStarted(tourn, foPool, foPool.lines[0]) || comb.status!="AVAILABLE";
            let id = betType + '_' + foPool.lines[0].lineId + "_" + comb.combId  + "_"  + foPool.id + "_" + comb.str;
            let cssStr = index%2==0 ? 'odd': 'even';
            let curState = context.content.oddsSelection.filter(x => x.id == id)[0];
            curState = {...curState,
                id: id,
                checked: curState?.checked || false,
                matchId: isMatch ? match.id : tourn.id,
                oddsVal: odds
            }
            let arrowCss = getOddsArrowCss(curState, isMatch && isDisabled)
            let oddsVal = formatOdds(t, odds, comb.status)
            let isNaNVal = isNaN(oddsVal)
            let isMatchNotPrev= match?.status != 'PREEVENT';
            return <>
                <div className={`tournSelName ${cssStr}`}>{comb.selections[0].str} {comb.selections[0]['name_'+i18n.language]}</div>
                <div className={`tournSelOdds ${cssStr} oddsColor ${arrowCss}`}>
                    {isMatch && isDisabled && isMatchNotPrev ? isNaNVal? oddsVal :  "---" : oddsVal}
                    <div className={`oddsArrow ${arrowCss}`}></div>
                </div>
                <div className={`tournSelChkbox ${cssStr}`}>
                    <CheckBox product='football' id={`fb_${id}`} classNameBox={`${id} ${isDisabled && 'dim'}`} onClick={() => onClickCheckBox(curState, isDisabled, context, setCheckboxState)} defaultChecked={curState.checked} disabled={isDisabled} />
                </div>
            </>;
        })}
    </div>
    { size > 8 ? <div onClick={toggleSel} className="tournMoreRow">
        {expandSel ? t('LB_FB_LESS') : t('LB_FB_MORE')}
        <ArrowIcon className='' direction='down' change={expandSel} changeDirection='up' size={20} />
    </div> : null }
    </>
}

