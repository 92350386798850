import React from 'react';
import { useTranslation } from 'react-i18next';
const ComingleMsg = ({ textId = 'wp_pmu_wrap', linkId= 'wp_pmu_link' }) => {
    const { i18n } = useTranslation();
    const handleWindow = () => {
        let pLang = i18n.language==="en" ? "en-US" : "zh-HK";
        const guidePage  = window.specialPages["RC_COMMINGLE"]
        let url =  window.globalConfig.SPECIAL_URL + guidePage.replace('{0}', pLang);
        window.open(url, "_blank")
    }
    return (
        <div id={textId}>
            {
                i18n.language == "en" ? <>
                 Please refer to this <a id={linkId} onClick={handleWindow} style={{color: 'rgba(13,110,253,1)', textDecoration: 'underline', cursor:'pointer'}}>link</a> for more information on France PMU Outbound Commingling.</> :
                 <>
                 請查看此<a id={linkId} onClick={handleWindow} style={{color: 'rgba(13,110,253,1)', textDecoration: 'underline',  cursor:'pointer'}}>連結</a>中的法國PMU匯出彩池備註。
                 </>
            }
           
        </div>
    )
}

export default ComingleMsg