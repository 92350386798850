import React, { useContext, useState,useEffect,useRef } from 'react';
import { onClickCheckBox } from './AddBet';
import { useTranslation } from 'react-i18next';
import { FootballContext } from '../../Home/FootballPage';
import { getOddsArrowCss } from './MatchBase';
import { formatOdds, isEliminated, isRefund, isSellingStarted, getFootballHelpUrl } from './Common';
import CheckBox from '../../Common/ui-components/CheckBox';
import MoreButton from '../../Common/ui-components/MoreButton'

const SGATable = ({ match, isResultPage, expand, handleSetLineState }) => {
    const { t, i18n } = useTranslation();
    const context = useContext(FootballContext);
    const [isShowRed, setIsShowRed] = useState(false)
    const tableRef = useRef(null);
    let foPools = match.foPools.filter((x) => x.oddsType === 'SGA');
    foPools?.sort((x, y) => {
        return x.instNo - y.instNo;
    });
    foPools = foPools?.filter((x) => x.lines?.[0]?.combinations?.[0] != null);
    useEffect(()=>{
        setIsShowRed(false)
        if(!tableRef.current?.getShowStatus()){
            foPools.slice(4).map(foPool=>{ 
                let comb = foPool.lines[0].combinations[0];
                let id = `SGA_${foPool.lines[0].lineId}_${comb.combId}_${foPool.id}_${foPool.instNo}`;
                let curState = context.content.oddsSelection.filter((x) => x.id == id)[0];
                if (curState?.checked) {
                    setIsShowRed(true)
                }
            })
        }
    },[context.content.oddsSelection])
    return (
        <section className="SGA">
            <div className="sgaTable">
                {foPools?.map((foPool, idx) => {
                    return (
                        (isResultPage || expand || idx < 4) && (
                            <SGAItemTable key={idx} index={idx} match={match} isResultPage={isResultPage} foPool={foPool} isShowRed={isShowRed} setIsShowRed={setIsShowRed}/>
                        )
                    );
                })}
            </div>
            {!isResultPage && foPools.length > 4 && (
                <MoreButton showLessText="LB_FB_SHOW_LESS_SGA" showMoreText="LB_FB_SHOW_MORE_SGA" MoreButtonClick={()=>{handleSetLineState(match.matchDate, 'SGA');}} expand={expand}  isShowRed={isShowRed} ref={tableRef}></MoreButton>
                // <div
                //     className="sgaMoreButton"
                //     onClick={() => {
                //         handleSetLineState(match.matchDate, 'SGA');
                //     }}
                // >
                //     <div>{expand ? t('LB_FB_SHOW_LESS') : t('LB_FB_MORE')}</div>
                //     <ArrowIcon color="green" className="" direction={expand ? 'up' : 'down'} size={24} />
                // </div>
            )}
        </section>
    );
};

const SGAItemTable = ({ match, isResultPage, foPool, index,isShowRed,setIsShowRed }) => {
    const { t, i18n } = useTranslation();
    const context = useContext(FootballContext);
    const [checkboxState, setCheckboxState] = useState([]);

    let isSelling = isSellingStarted(match, foPool, foPool.lines[0]);
    let comb = foPool.lines[0].combinations[0];
    let sel = comb.selections[0];
    let id = `SGA_${foPool.lines[0].lineId}_${comb.combId}_${foPool.id}_${foPool.instNo}`;
    let curState = context.content.oddsSelection.filter((x) => x.id == id)[0];
    let isDisabled = isResultPage || isEliminated(comb) || isRefund(comb) || !isSelling || comb.status != 'AVAILABLE';
    if (curState == null) {
        curState = {
            id: id,
            checked: false,
            matchId: match.id,
            oddsVal: comb.currentOdds
        };
    }
    if (!isShowRed && index>=4 && curState.checked) {
        setIsShowRed(true)
    }
    return (
        <div className={`sgaCellGrid`}>
            <div className="sgaAns">{sel['name_' + i18n.language]}</div>
            <div className='oddsCheckboxGrid'>
                <div className={`sgaSelChkbox`}>
                    {!isResultPage && (
                        <CheckBox
                            product="football"
                            id={`fb_${id}`}
                            classNameBox={`${id} ${isDisabled && 'dim'}`}
                            onClick={() => onClickCheckBox(curState, isDisabled, context, setCheckboxState)}
                            defaultChecked={curState.checked}
                            disabled={isDisabled}
                        />
                    )}
                </div>
                <div className={`sgaSelOdds oddsColor ${getOddsArrowCss(curState)}`}>
                    {formatOdds(t, comb.currentOdds, isResultPage ? '' : comb.status)}
                    <div className={`oddsArrow ${getOddsArrowCss(curState)}`}></div>
                </div>
            </div>
        </div>
    );
};

export default SGATable;
