import moment from 'moment';
import { useQuery, useLazyQuery } from '@apollo/client';
import { getImportantNoticesQuery, getCommonControlQuery, getWebTVEnabledQuery, getFPSMessageQuery, getESShortcutQuery, getPoolConfig} from './DataQuery';
import { getMatchRemarksQuery } from '../../Football/Data/DataQuery';
import { errRedirectPage } from '../../Common/utils';

export const useImportantNoticeQuery = () => {
    const [loadQuery, { loading, data }] = useLazyQuery(getImportantNoticesQuery(), {
        fetchPolicy: 'no-cache',
        context: { clientName: 'wcas' },
        notifyOnNetworkStatusChange: true,
        onError: (err) => {
            //no need to redirect
        }
    });

    const [loadRemarksQuery, remarksGql] = useLazyQuery(getMatchRemarksQuery(), {
        fetchPolicy: 'no-cache',
        context: { clientName: 'wcas' },
        notifyOnNetworkStatusChange: true,
        onError: (err) => {
            //errRedirectPage(err);
        }
    });

    const load = (cats) => {
        loadQuery({
            variables: {
                category: cats
            }
        });

        if (cats.includes('FB')) {
            loadRemarksQuery();
        }
    };

    return [load, loading, data?.generalBettingRemarks, remarksGql.loading, remarksGql.data];
};

const wcasDefaultRespond = {
    peNotes: {
        JCBWButtonEnabled: true
    },
    jcbwControl: {
        mainlineOnTopDisplay: true,
        CWAButtonEnabled: true,
        CWBButtonEnabled: true,
        CWCButtonEnabled: true,
        enableFeaturedMatches: true,
        enableGlassBox: true,
        headToHeadIconEnabled: true,
        liveTIconEnabled: true,
        earlySettlementEnabled: true,
        earlySettlementIndicatorEnabled: true,
        enableEarlySettlementUIMessage: false,
        enableFOBetTypesFilterTab: false,
        enableOddsPushButton: true
    }
};

export const useCommonControlQuery = () => {
    try {
        const { loading, data } = useQuery(getCommonControlQuery(), {
            fetchPolicy: 'no-cache',
            notifyOnNetworkStatusChange: true,
            context: { clientName: 'wcas' },
            onError: (err) => {
                //use default rather than redirect
                //errRedirectPage();
            }
        });
        return [loading, (data && data.jcbwControl.enableGlassBox!=null) ? data : wcasDefaultRespond];
    } catch {
        return [false, wcasDefaultRespond];
    }
};

export const usePoolConfig = () => {
    try {
        const { loading, data } = useQuery(getPoolConfig(), {
            fetchPolicy: 'no-cache',
            notifyOnNetworkStatusChange: true,
            context: { clientName: 'wcas' },
            onCompleted: (data) =>{
                if(data){
                    data?.poolConfigs?.forEach(i=>{
                        window.fbConfig['allupInfo'][i.betType] = i.allUpMaxLeg
                        window.fbConfig['AllPoolsMinBet'][i.betType] = Number(i.minBet) || window.fbConfig['allupInfo'][i.betType];
                        let curBetType = window.personalSettings.football.find(item => i.betType == item.betType)
                        if(curBetType?.min){
                            curBetType.min= Number(i.minBet)
                        }
                    })
                };
            },
            onError: (err) => {
            }
        });
        
        return []
    } catch {
        return []
    }
}

export const useFPSMessageQuery = () => {
    try {
        const { loading, data } = useQuery(getFPSMessageQuery(), {
            fetchPolicy: 'no-cache',
            notifyOnNetworkStatusChange: true,
            context: { clientName: 'wcas' },
            onError: (err) => {
                //use default rather than redirect
                //errRedirectPage();
            }
        });
        return [loading, data || wcasDefaultRespond];
    } catch {
        return [false, wcasDefaultRespond];
    }
};

export const useWebTVEnabledMatchesQuery = () => {
    try {
        const [loadQuery, { loading, data }] = useLazyQuery(getWebTVEnabledQuery(), {
            fetchPolicy: 'no-cache',
            notifyOnNetworkStatusChange: true,
            context: { clientName: 'wcas' },
            onError: (err) => {
                //use default rather than redirect
                //errRedirectPage();
            }
        });
        return { loading, data: data?.jcbwControl?.liveWebTvControl || [], loadQuery };
    } catch {
        return { loading: false, data: [], loadQuery: () => {} };
    }
};

export const useGetESShortcutQuery = () => {
    try {
        const { loading, data } = useQuery(getESShortcutQuery(), {
            fetchPolicy: 'no-cache',
            notifyOnNetworkStatusChange: true,
            context: { clientName: 'wcas' },
            onError: (err) => {
            }
        });
    
        return { loading,esShortcutFeature: data || {} };
        
    } catch (error) {
        return { loading: false, esShortcutFeature: {} };
    }
};
