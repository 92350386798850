import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { RacingContext } from '../../Home/RacingPage';
const JackpotAllocatedAmount = ({showWin = true,showPlace = true, allup, raceNo, isUnComingle}) => {
    const { t } = useTranslation();
    const context = useContext(RacingContext);
    const mtg = context.content.meeting;
    let winAmount = null
    let plaAmount = null
    const paramRaceNo = raceNo || context.content.raceNo
    const poolWinInv =  mtg.poolInvs.filter((data) => data.leg.races[0] == paramRaceNo && data.oddsType=='WIN')[0];
    const poolPlaInv =  mtg.poolInvs.filter((data) => data.leg.races[0] == paramRaceNo && data.oddsType=='PLA')[0];
    const jpWinEst =  mtg.jpEsts.filter((data) => data.leg.races[0] == paramRaceNo && data.oddsType=='WIN')[0];
    const jpPlaEst =  mtg.jpEsts.filter((data) => data.leg.races[0] == paramRaceNo && data.oddsType=='PLA')[0];
    if ( poolWinInv?.status.indexOf("REFUND")<0 ) {
        if (jpWinEst?.jackpot != null && (isUnComingle ? true :  parseInt(jpWinEst?.jackpot) > 0)) {
            winAmount = '$' + parseInt(jpWinEst.jackpot).toLocaleString();
        }
    }
    if ( poolPlaInv?.status.indexOf("REFUND")<0 ) {
        if (jpPlaEst?.jackpot != null && (isUnComingle ? true : parseInt(jpPlaEst?.jackpot) > 0)) {
            plaAmount = '$' + parseInt(jpPlaEst.jackpot).toLocaleString();
        }
    }

    return (
        !!winAmount || !!plaAmount ? 
        <div className='jackpotWrap' style={{ display: `${allup? 'block' : ''}` }}>
            {
                showWin && !!winAmount &&  <div className='jackpotBg'>
                <div className="label" id={`winjackpot_label_${paramRaceNo}`} style={{ whiteSpace: "nowrap" }}>{t('LB_RC_WIN_JACKPOT')}:</div> 
                <div  className="amount-blue" style={{ paddingLeft: '4px' }} id={`winjackpot_text_${paramRaceNo}`}>{winAmount}</div>
            </div>
            }
            {
                showPlace && !!plaAmount && <div className='jackpotBg'>
                <div className="label" id={`placejackpot_label_${paramRaceNo}`} style={{ whiteSpace: "nowrap" }}>{t('LB_RC_PLACE_JACKPOT')}:</div> 
                <div className="amount-blue" style={{ paddingLeft: '4px' }}  id={`placejackpot_text_${paramRaceNo}`}>{plaAmount}</div>
            </div>
            } 
        </div> : <></>
    )
}

export default JackpotAllocatedAmount;