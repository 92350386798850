import React, { useContext, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import AddSlipBtn from '../Common/AddSlipBtn';
import AllUpFormulaDropdown from './AllUpFormulaDropdown';
import { RacingContext } from '../../Home/RacingPage';
import { formatDateTime } from '../../Common/GlobalFunc';
import { getMeetingHeaderTitle } from './Common';
import { isDisplayFoPool } from '../FO/FO_List';
import MobileMenu from '../../Menu/MobileMenu';
import Sticky from 'react-stickynode';
import { NewWindow, isLocalMeeting } from '../../Common/home-common';
import ArrowIcon from '../../Common/ui-components/ArrowIcon';

const hasCollapsePage = ['WP', 'HOME', 'WPQ', 'FCT', 'TCE', 'TRI', 'FF', 'QTT', 'PWIN']
const MeetingInfo = (props) => {
    const { t, i18n } = useTranslation();
    const context = useContext(RacingContext);
    const mtg = context.content.meeting;
    const raceObj = mtg.races.filter(x=> x.no==context.content.raceNo)[0];
    const showWorldPoolLogo = () => {
        return window.racingConfig.enableWPBanner && context.content.page=="HOME" && isLocalMeeting(mtg.venueCode) && raceObj?.claCode=="G1";
    }

    function RCSelTxt() {
        return raceObj==null ? null : t('LB_RC_SEL').replace('{0}', raceObj.no);
    }

    function RaceDescription() {
        if (raceObj==null)
            return null;
        let desc = [];

        let postDT = raceObj?.postTime ? formatDateTime(raceObj?.postTime,i18n.language,true,'DD/MM') : [];
        // desc.push(postDT[0]);
        if(hasCollapsePage.includes(context.content.page)) {
            desc.push(`${postDT[0]}`);//day
        } else {
            desc.push(`${postDT[0]} (${postDT[2]})`);//day
        }
        desc.push(postDT[1]);//time
        if ( raceObj['country_'+i18n.language]!=null && raceObj['country_'+i18n.language]!='' )
            desc.push(raceObj['country_'+i18n.language]);
        if ( raceObj['raceClass_'+i18n.language]!=null && raceObj['raceClass_'+i18n.language]!='' )
            desc.push(raceObj['raceClass_'+i18n.language]);
        if ( raceObj.distance!=null && raceObj.distance!='' )
            desc.push(raceObj.distance + t('LB_METER'));
        if ( raceObj.raceTrack['description_'+i18n.language]!=null && raceObj.raceTrack['description_'+i18n.language]!='' ){
            desc.push(raceObj.raceTrack['description_'+i18n.language]);
        }
        desc.push(raceObj['raceName_'+i18n.language]);
        if(isLocalMeeting(mtg.venueCode) &&  raceObj.raceCourse['displayCode']!=null && raceObj.raceCourse['displayCode']!=''){
            desc.push(t('LB_RACE_RACE_COURSE').replace('{0}', raceObj.raceCourse['displayCode']));
        }
        else if(!isLocalMeeting(mtg.venueCode) && raceObj.raceCourse[`description_${i18n.language}`]!=null && raceObj.raceCourse[`description_${i18n.language}`]!=''){
            desc.push(raceObj.raceCourse[`description_${i18n.language}`])
        }
        if ( raceObj['go_'+i18n.language]!=null && raceObj['go_'+i18n.language]!='' )
            desc.push(raceObj['go_'+i18n.language]);

        return desc.join(', ');
    }

    const mtgSize = context.content?.meetingList?.length;
    let top = mtgSize && mtgSize > 0 ? 52 : 0;
    if(['JKC', 'TNC', 'JTCOMBO'].includes(context.content.page) && mtgSize === 1) {
        top = 0
    }
    const hasCollapse = props.isMobileSize && hasCollapsePage.includes(context.content.page) && props.poolDefined && context.pageConfig.showMtgInfo
    const [isCollapse, setIsCollapse] = useState(hasCollapse);
    useEffect(() => {
        setIsCollapse(hasCollapse)
    }, [context.content.page])
    return (<>
        <Sticky innerZ={999} top={top} activeClass='Sticky-activeClass' enabled={context.windowSize.isMobileSize}>
            <div className={`meeting-info ${isCollapse ? 'meeting-info-isCollapse' : ''} ${(props.poolDefined || props.foPoolDefined) && context.content.page!="TURNOVER" ? '' : 'meeting-info-desktop-none'}`}>
                <div className="meeting-info-container">
                    {props.poolDefined && context.pageConfig.showMtgInfo ?
                        <div className="meeting-info-no" id="meetingDescNo">
                            <RCSelTxt />
                        </div> : null}
                    {props.poolDefined && context.pageConfig.showMtgInfo ?
                        <div className="meeting-info-content" id="meetingDescDtls">
                            <div className="meeting-info-content-text"><RaceDescription /></div>
                            {showWorldPoolLogo() ? <div className={`meeting-info-wplogo-m-${i18n.language}`} onClick={()=> { NewWindow(window.globalConfig[`WP_URL_${i18n.language}`])}}></div> : null}
                        </div> : null}
                    {props.poolDefined && context.pageConfig.showMtgDesc ?
                        <div className="meeting-info-content">
                            {getMeetingHeaderTitle(context, context.content.meeting, t, i18n.language)}
                        </div> : null}
                    { hasCollapse && <ArrowIcon onClick={() => {setIsCollapse(!isCollapse)}} direction={isCollapse ? 'down': 'up'} size={24}/>}
                </div>
                {context.pageConfig.showAllUpFormula ? <div className="meeting-info-allup">
                    <AllUpFormulaDropdown />
                </div> : null}
                {context.pageConfig.showFOInfo ? <div className="foTopInfo"><FOInfo mtg={mtg} context={context}/></div> : null}
                {context.pageConfig.showAddToSlip && (props.poolDefined || props.foPoolDefined) ? <div>
                    <div className={context.pageConfig.hiddenAddToSlip? "addSlip hiddenAddSlip": "addSlip"}>
                        <AddSlipBtn />
                    </div>
                </div> : null}
                <div className="meeting-info-menu">
                    {context.windowSize.isMobileSize && <MobileMenu product="racing" onPageChange={props.onPageChange} />}
                </div>
                {showWorldPoolLogo() ? <div className="meeting-info-wplogo" onClick={()=> { NewWindow(window.globalConfig[`WP_URL_${i18n.language}`])}}></div> : null}
            </div>
        </Sticky>
        <div className="foTopInfo-m"><FOInfo mtg={mtg} context={context}/></div>
        {props.poolDefined && context.pageConfig.showAllUpFormula ? <div className="meeting-info-allup-m">
            <AllUpFormulaDropdown />
        </div> : null}
    </>)
}
export default MeetingInfo;

const FOInfo = ({mtg, context}) => {
    const {t, i18n} = useTranslation();

    const updResultRaceNo = (foPool) => {
        if ( foPool.selections.length > 0 ) {
            return Math.max(0, ...foPool.selections[0].results.map(x=> x.raceNo));
        }
        return 0;
    }

    let foPool = mtg.foPools?.filter(x=> x?.oddsType==context.content.page && isDisplayFoPool(x))[0];
    if ( !foPool || ["REFUNDBEFORESUSPEND", "REFUND"].includes(foPool.status) )
        return null;
    else {
        let excludeTxt = foPool.excludeRaces.length>0 ? t('LB_RC_FO_EXCEPT').replace('{0}', foPool.excludeRaces.join(', ')) : '';
        let isPayout = foPool.status=="PAYOUTSTARTED";
        let isSuspend = foPool.status=="SUSPENDED";
        let isStartSell = foPool.status=="SELLINGSTARTED" && foPool.sellStatus=="Available";
        let firstIncludeRace = foPool.includeRaces[0];
        let lastIncludeRace = foPool.includeRaces[foPool.includeRaces.length-1];
        let raceInfo = "";

        if (isPayout) {
            raceInfo = t('LB_RC_' + context.content.page + '_PAYOUT_STARTED').replace('{0}', foPool.instNo);
        }
        else {
            if ((foPool.raceStopSellNo > firstIncludeRace || (updResultRaceNo(foPool) >= firstIncludeRace && mtg.currentNumberOfRace > firstIncludeRace)) && foPool.raceStopSellNo <= lastIncludeRace) {
                if (isSuspend && updResultRaceNo(foPool) < lastIncludeRace ) {
                    raceInfo = t('LB_RC_' + context.content.page + '_SUSPEND_INPLAY').replace('{0}', foPool.instNo);
                }
                else if (!isStartSell || ( isSuspend && updResultRaceNo(foPool) <= lastIncludeRace )) {
                    raceInfo = t('LB_RC_' + context.content.page + '_STOPSELL_INPLAY').replace('{0}', foPool.instNo);
                }
            }
            else if (foPool.raceStopSellNo <= firstIncludeRace) {
                // 2nd row: normal stop sell date time
                if (isSuspend) {
                    raceInfo = t('LB_RC_' + context.content.page + '_SUSPEND').replace('{0}', foPool.instNo);
                }
                else if (!isStartSell) {
                    raceInfo = t('LB_RC_' + context.content.page + '_STOPSELL').replace('{0}', foPool.instNo);
                }
                else if (foPool.raceStopSellNo < firstIncludeRace && foPool.expStopDateTime != '') {
                    raceInfo = `${foPool.expStopDateTime.substring(8,10)}/${foPool.expStopDateTime.substring(5,7)} ${foPool.expStopDateTime.substring(11,16)}`;
                    raceInfo = t('LB_RC_EXPECTEDSTOPSELL').replace('{0}', raceInfo)
                }
            }
        }

        return <div className="meeting-info-fo-topinfo-container">
            <div className={"meeting-info-allraces " + i18n.language}>{t('LB_RC_FO_ALLRACES')}{excludeTxt}</div>
            {raceInfo!='' ? <><div className="nextline"><br /></div>
            <div className="separator"></div>
            <div className="expectedstopsell">{raceInfo}</div></> : null}
            {!isPayout?<>
                <div className="nextline"><br /></div>
                <div className="separator"></div>
                <div className="inplaystopsell">{t('LB_RC_INPLAYSTOPSELL').replace('{0}', foPool.inplayUpTo)}</div>
            </>:null}
        </div>
    }
}