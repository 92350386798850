import React, { useEffect, useState, useMemo, useContext, useRef, useLayoutEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { getCurrentSessionRecords } from '../../Common/CurrentSessionRecords';
import { ConfigContext } from '../../Common/ConfigHelper';
import { GetChannelPara } from '../../Common/ChannelParaFunc';
import { getSessionId, getStatementRecall } from '../BetSlipAPI';
import './current_session.scss';
import { getSessionId_IB, getStatementRecall_IB } from '../BetSlipIBAPI';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { Loading } from '../../Common/ui-components';
import useBroadcastChannel from '../../Common/hooks/useBroadcastChannel';

const Table = ({ data, onChangePageNumber, isLoading, isNextPageLoading, hasMoreRecords }) => {
    const { t } = useTranslation();

    return data.length > 0 ? (
        <>
            <div className="table-header-shadow"></div>
            <tabledestop className={hasMoreRecords ? 'detail-table ShowMoreBtn' : 'detail-table'}>
                <Header />
                <TableBody data={data} />
            </tabledestop>
            <TableMobile
                data={data}
                onChangePageNumber={onChangePageNumber}
                isLoading={isLoading}
                isNextPageLoading={isNextPageLoading}
            />
        </>
    ) : (
        <p className="no-records">{t('LB_BETSLIP_ACCOUNTRECORDS_TR_NO_RECORDS')}</p>
    );
};

const Header = () => {
    const { t } = useTranslation();
    return (
        <thead>
            <tr>
                <td>{t('LB_BETSLIP_ACCOUNTRECORDS_CS_TH_REF_NO')}</td>
                <td>{t('LB_BS_AR_TABLE_HEADER_BETTYPE')}</td>
                <td>{t('LB_BETSLIP_ACCOUNTRECORDS_CS_TH_TRANSACTION_DETAILS')}</td>
                <td>{t('LB_BETSLIP_ACCOUNTRECORDS_CS_TH_AMOUNT')}</td>
            </tr>
        </thead>
    );
};

const formatDetails_unused = (raw) => {
    let formatted = raw.replace(/&nbsp;/g, ' ');
    const re = /Race \d+/g;
    const indexes = [];
    while (re.test(formatted)) {
        indexes.unshift(re.lastIndex);
    }
    for (const index of indexes) {
        formatted = formatted.slice(0, index) + '<br>' + formatted.slice(index);
    }

    formatted = formatted
        .split('<br>')
        .map((item) => item.trim())
        .join('\n');
    formatted = formatted.split('+\n').join('+ ');

    return formatted;
};

const formatDetails = (raw, lang) => {
    let formatted = raw.replace(/&nbsp;/g, ' ');
    const re = lang === 'eng' ? /\s\d+\s{5}\d/g : /\s場\s{4}\d/g;
    const indexes = [];
    while (re.test(formatted)) {
        indexes.unshift(re.lastIndex - 1);
    }
    for (const index of indexes) {
        formatted = formatted.slice(0, index) + '<br>' + formatted.slice(index);
    }

    // formatted = formatted.replace(/\+ <br>\s{16}/g, '+ ');
    formatted = formatted
        .split('<br>')
        .map((item) => item.trim())
        .join('<br>');

    return formatted;
};

const TableBody = ({ data }) => {
    const { t, i18n } = useTranslation();
    const lang = i18n.language === 'en' ? 'eng' : 'chi';
    return (
        <tbody>
            {data.map((item, index) => {
                const details = window.globalConfig.IS_IB ? formatDetails(item.detail?.[lang], lang) : item.detail?.[lang];
                return (
                    <tr key={index}>
                        <td>{item?.transactionNumber?.[lang]}</td>
                        <td className="mw74" dangerouslySetInnerHTML={{ __html: item.betType?.[lang] }}></td>
                        <td className="w480" dangerouslySetInnerHTML={{ __html: details }}></td>
                        <td>{item.totalAmount}</td>
                    </tr>
                );
            })}
        </tbody>
    );
};

const TableMobile = ({ data, isLoading, isNextPageLoading, onChangePageNumber }) => {
    const tableRef = useRef();

    return (
        <div className="detail-tables-m TR-detail-m" ref={tableRef}>
            {data.map((item, index) => (
                <TableRowM item={item} key={index} />
            ))}
            {(isLoading || isNextPageLoading) && (
                <div className="wait-mobile">
                    <Loading />
                </div>
            )}
        </div>
    );
};

const TableRowM = ({ item }) => {
    const { t, i18n } = useTranslation();
    const lang = i18n.language === 'en' ? 'eng' : 'chi';
    const details = window.globalConfig.IS_IB ? formatDetails(item.detail?.[lang], lang) : item.detail?.[lang];
    return (
        <div className="detail-table-m">
            <header>
                {t('LB_BETSLIP_ACCOUNTRECORDS_CS_TH_REF_NO')} {item?.transactionNumber?.[lang]}
            </header>
            <section>
                <div>
                    <span className="title">{t('LB_BETSLIP_ACCOUNTRECORDS_CS_TH_BET_TYPE')}</span>
                    <span className="content" dangerouslySetInnerHTML={{ __html: item.betType?.[lang] }}></span>
                </div>
                <div>
                    <span className="title">{t('LB_BETSLIP_ACCOUNTRECORDS_CS_TH_TRANSACTION_DETAILS')}</span>
                    <span className="content" dangerouslySetInnerHTML={{ __html: details }}></span>
                </div>
                <div>
                    <span className="title">{t('LB_BETSLIP_ACCOUNTRECORDS_CS_TH_AMOUNT')}</span>
                    <span className="content">{item.totalAmount}</span>
                </div>
            </section>
        </div>
    );
};

const TransactionRecords = ({
    isShow,
    setShowExportPrint,
    currentTab,
    setIsApiErr,
    isReturnErrorCode,
    setIsCallingApi
}) => {
    const configs = useContext(ConfigContext);
    const accessToken = sessionStorage.getItem('access_token');
    const { t, i18n } = useTranslation();
    const [mounted, setMounted] = useState(false);
    const [isNextPageLoading, setIsNextPageLoading] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [statementData, setStatementData] = useState([]);
    const [currentData, setCurrentData] = useState([]);
    const [paginationData, setPaginationData] = useState({
        pageSize: GetChannelPara('OnlineStmtRecPerPage') || 10,
        pageNumber: 1,
        totalPage: 1,
        nextPage: false,
        nextPageDate: 0,
        nextPageKey: 0
    });
    const throttleSwitchRef = useRef(true);
    const [isMobileSize, setIsMobileSize] = useState(true);
    const mobileSizeRef = useRef(true);
    const nextPageRef = useRef(false);
    const [lastTxnNo, setLastTxnNo] = useState('0000'); // IB
    const [hasMoreRecords, setHasMoreRecords] = useState(true);
    const broadcast_resetlogin = useBroadcastChannel('resetLoginTimeout');

    useEffect(() => {
        if (isShow) {
            handleSearch();
        }
    }, [isShow]);

    useEffect(() => {
        if (statementData.length > 0 && currentTab === 1) {
            setShowExportPrint(true);
        } else if (statementData.length === 0 && currentTab === 1) {
            setShowExportPrint(false);
        }
    }, [statementData, currentTab]);

    // useEffect(() => {
    //     if (statementData?.length > 0) {
    // const pageNumber = paginationData.pageNumber;
    // const pageSize = paginationData.pageSize;
    // const currentData = statementData.slice((pageNumber - 1) * pageSize, pageSize * pageNumber);
    // setCurrentData(currentData);
    //     }
    // }, [paginationData, statementData]);

    useEffect(() => {
        function handleResize() {
            let isMobileSize = window.innerWidth < 800 || document.documentElement?.clientWidth < 800;
            setIsMobileSize(isMobileSize);
            mobileSizeRef.current = isMobileSize;
        }
        window.addEventListener('resize', handleResize);
        handleResize();
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        let ARResultTable = document.querySelector('.ARInbox-scrollbars>div:first-child');
        let ARResultTableM = document.querySelector('.ARtableTM-scrollbars>div:first-child');
        ARResultTableM?.addEventListener('scroll', onScrollHandleTR);
        ARResultTable?.addEventListener('scroll', onScrollHandleTR);

        return () => {
            ARResultTableM?.removeEventListener('scroll', onScrollHandleTR);
            ARResultTable?.removeEventListener('scroll', onScrollHandleTR);
        };
    }, [onScrollHandleTR, statementData, isLoading, isNextPageLoading, isMobileSize, currentTab]);

    const onScrollHandleTR = (e) => {
        const isCurTab = currentTab == 1;
        if (isLoading || isNextPageLoading || !isCurTab) return;
        let scrollTop = e.target.scrollTop || document.documentElement.scrollTop;
        let scrollHeight = e.target.scrollHeight;
        let windowHeight = window.innerHeight;
        let triggerPoint = mobileSizeRef.current ? 215 : 0;
        const isBottom = scrollHeight <= windowHeight + scrollTop;
        if (isBottom && throttleSwitchRef.current) {
            throttleSwitchRef.current = false;
            onChangePageNumber('NEXT');
            setTimeout(() => {
                throttleSwitchRef.current = true;
                e.target.scrollTo(0, scrollTop - 1);
            }, 100);
        }
    };

    const handleSearch = async (nextPage) => {
        if (!accessToken) return;
        setIsCallingApi(true);
        if (nextPage) {
            setIsNextPageLoading(true);
        } else {
            setIsLoading(true);
            setStatementData([]);
            nextPageRef.current = false;
        }
        const sessionData = window.globalConfig.IS_IB ? await getSessionId_IB(false) : await getSessionId(accessToken);
        broadcast_resetlogin?.postMessage(false);
        if (sessionData?.sessionId) {
            const params = {
                language: i18n.language,
                numberOfTransaction: paginationData.pageSize,
                horsePerLine: window.innerWidth > 768 ? 2 : 1,
                nextPage,
                lastTxnNo: lastTxnNo
            };
            const responseData = window.globalConfig.IS_IB
                ? await getStatementRecall_IB(params, i18n.language, t)
                : await getStatementRecall(sessionData, accessToken, params);
            broadcast_resetlogin?.postMessage(false);

            if (isReturnErrorCode(sessionStorage.getItem('recall_status'), 'recall')) {
                setIsCallingApi(false);
                return;
            }
            setLastTxnNo(responseData?.lastTxnNo); // IB
            if (responseData?.transactionContent) {
                let pageNumber;
                let totalPage;
                let newStatementData = [];
                if (nextPage) {
                    pageNumber = paginationData.pageNumber + 1;
                    totalPage = paginationData.totalPage + 1;
                    newStatementData = [...statementData, ...responseData.transactionContent];
                    if (responseData.transactionContent.length > 0) {
                        setHasMoreRecords(true);
                    } else {
                        setHasMoreRecords(false);
                    }
                } else {
                    totalPage = 1;
                    pageNumber = 1;
                    newStatementData = [...responseData.transactionContent];
                }
                setStatementData([...newStatementData]);
                updatePaginationDate({
                    ...paginationData,
                    pageNumber,
                    totalPage,
                    nextPage: responseData.continue
                });
                nextPageRef.current = responseData.continue;
                if (responseData?.lastTxnNo == '0000') {
                    setHasMoreRecords(false);
                } else {
                    setHasMoreRecords(true);
                }
                setIsApiErr(false);
            } else if (sessionStorage.getItem('recall_status') == '2') {
                setIsApiErr(false);
                setHasMoreRecords(false);
            } else {
                setIsApiErr(true);
                setHasMoreRecords(false);
            }
            setIsLoading(false);
            setIsCallingApi(false);
            setIsNextPageLoading(false);
        } else {
            setIsApiErr(true);
        }
    };

    const onChangePageNumber = (action) => {
        //if (isLoading || isNextPageLoading) return;
        switch (action) {
            case 'PREV':
                if (paginationData.pageNumber > 1) {
                    updatePaginationDate({
                        ...paginationData,
                        pageNumber: paginationData.pageNumber - 1
                    });
                }
                return;
            case 'NEXT':
                if (
                    paginationData.pageNumber < paginationData.totalPage ||
                    paginationData.nextPage ||
                    nextPageRef.current
                ) {
                    if (paginationData.pageNumber < paginationData.totalPage) {
                        updatePaginationDate({
                            ...paginationData,
                            pageNumber: paginationData.pageNumber + 1
                        });
                    } else {
                        handleSearch(true);
                    }
                }
                return;
            default:
                return;
        }
    };

    const updatePaginationDate = (newData) => {
        setPaginationData(newData);
    };

    const onClickShowMore = () => {
        handleSearch(true);
    };

    return (
        <div className="transaction-records">
            {isLoading ? (
                <div className="wait">
                    <Loading />
                    {/* {t("LB_BETSLIP_INPLAYMSGTEXT2")}.. */}
                </div>
            ) : (
                <>
                    <Table
                        data={statementData}
                        currentData={currentData}
                        onChangePageNumber={onChangePageNumber}
                        isLoading={isLoading}
                        isNextPageLoading={isNextPageLoading}
                        hasMoreRecords={hasMoreRecords}
                    />
                    {hasMoreRecords ? (
                        <div className="FBMatchListLoadMoreContainer" onClick={onClickShowMore}>
                            <div className="FBMatchListLoadMoreButton">{t('LB_FB_SHOW_MORE')}</div>
                        </div>
                    ) : null}
                </>
            )}
        </div>
    );
};

export default TransactionRecords;
