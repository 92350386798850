import React, { useCallback, useContext, useEffect, useLayoutEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FootballContext } from '../../Home/FootballPage';
import { SingleMatchTable } from '../Common/MatchTable';
import CashOutTable from '../Common/CashOutTable';
import { MatchDropdown, Remark, MatchInfo, getFoPool } from '../Common/MatchBase';
import { isLogonHigh } from '../../BetSlip/LoginHooks';
import RefreshTime from '../../Common/ui-components/RefreshTime'
import Sticky from 'react-stickynode';
import { GlobalContext } from '../../../contexts/GlobalContext';
import { hasMatchInfoUpdate, MatchUpdateMsg } from '../Common/Common';
import { useWindowSize } from '../../Common/CommonHooks';
import getDistanceOfPageTop from '../../Common/utils/getDistanceOfPageTop';
import { FBMatchTypeTab } from '../../Common/ui-components/FBMatchTypeTab';
import { scrollTo } from '../../Common/GlobalFunc';

const ALLODDS = () => {
    const { t } = useTranslation();
    const context = useContext(FootballContext);
    const globalContext = useContext(GlobalContext);
    const [searchMatchIds,setSearchMatchIds] = useState([])
    const singleMatch = context.content.matchData.matches?.[0];
    const MatchBetTypeFilteringTab = context.content.config.MatchBetTypeFilteringTab;
    const { isMobileSize } = useWindowSize()
    const [matchTimeType, setMatchTimeType] = useState(MatchBetTypeFilteringTab?.[0]?.key)
    const [gsTab, setGsTab] = useState('GS')
    useLayoutEffect(() => {
        if ( window.location.hash!='' ) {
            setTimeout(() => {
                if(isMobileSize) {
                    window.scrollTo(0, getDistanceOfPageTop(document.querySelector(".coupon" + window.location.hash.substring(1))) - 60 - (document.querySelector(".matchDropdownContainer-MatchInfoContent")?.clientHeight || 0 ) - 20);
                } else {
                    scrollTo(0, (document.querySelector(".coupon" + window.location.hash.substring(1))?.offsetTop))
                }
            }, 1500);
        } else {
            scrollTo(0, 0)
        }
    }, [window.location.hash])

    useEffect(()=> {
        if(context.content.matchData.matches.length > 1){
            setSearchMatchIds(context.content.matchData.matches.map((match)=> match.id))
        }
    },[context.content.matchData.matches])


    const AllMatchTable = useCallback(({ singleMatch, matchTimeType, context, t,gsTab,setGsTab }) => {
        let AllOddsOrder = context.content.config.AllOddsOrder;
        if(matchTimeType != 'ALL'){
            const pools = MatchBetTypeFilteringTab.find(i=> i.key == matchTimeType)?.filterType
            AllOddsOrder = AllOddsOrder.filter(i => pools.includes(i))
        }
        if(hasMatchInfoUpdate(context.content)){
            return <MatchUpdateMsg/>
        }
        return AllOddsOrder.map(betType => {
            let foPool = getFoPool(singleMatch, betType, null);
            if(MatchBetTypeFilteringTab?.find(i=> i.key == matchTimeType)?.excludeType?.includes(betType) ){
                return null
            }
            if(matchTimeType ==='PLAYER'){
                if(betType == 'GS' && foPool.length <= 1) return null
                let GSBetTypes = window.fbConfig.GSPools
                let GSPools = singleMatch.foPools.filter(pool => GSBetTypes.includes(pool.oddsType))
                if(GSPools.length > 1 && GSBetTypes.includes(betType)) return null
            }
            return foPool!=null ? <SingleMatchTable betType={betType} match={singleMatch} foPool={foPool} key={betType} matchTimeType={matchTimeType} gsTab={gsTab} setGsTab={setGsTab}/> : null;
        })
    }, [])

    const onChangeMatchTimeType = (value) =>{
        setMatchTimeType(value)
        // if(value == 'ALL') return
        // const isFT = value == 'FT'
        // const curPools = isFT ? window.fbConfig.FullTimePools : window.fbConfig.FirstHalfPools
        // context.contentRef.current.oddsSelection.forEach(item =>{
        //     if(!curPools.includes(item.betType)){
        //         item.checked = false
        //     }
        // })
        // context.setContent({...context.contentRef.current})
    }

    return <>
        {singleMatch ? <>
                {context.content.pageConfig.showRefreshTime && <div className='refreshContainer'><RefreshTime product={'football'}/></div>}
        </> : null}
        <section className='ALLODDS'>
            {singleMatch ? <>
                {!context.state.windowSize.isMobileSize && <>
                    <div className='match-dropdown-container ddNoRSpace'><MatchDropdown singleMatch={singleMatch}  searchMatchIds={searchMatchIds}/></div>
                    <MatchInfo singleMatch={singleMatch} context={context}/>
                </>}
                {/*globalContext.globalState.commonCtrl.earlySettlementEnabled && isLogonHigh() && <CashOutTable match={singleMatch} /> */}
                {globalContext.globalState.commonCtrl.enableFOBetTypesFilterTab  && <FBMatchTypeTab allMatchTimeTypes={MatchBetTypeFilteringTab} selected={matchTimeType} onChange={onChangeMatchTimeType} match={singleMatch}/>}
                <AllMatchTable matchTimeType={matchTimeType} singleMatch={singleMatch}  context={context} t={t} gsTab={gsTab} setGsTab={setGsTab}/>
            </> : <MatchUpdateMsg/>}
        </section>
        <div className='mobileMarginLR'>
            <Remark betType={'ALLODDS'} />
        </div>
    </>
}
export default ALLODDS;

export const AllOddsMobileMatchInfo =({state,content, t})=>{
    const singleMatch = content.matchData.matches?.[0];
    const searchMatchIds = content.matchData.matches.length > 1 ? content.matchData.matches.map((match)=> match.id):[]
    const contextObj={state,content};

    return <Sticky innerZ={800} top={52}  enabled={state.windowSize.isMobileSize} innerClass='matchDropdownContainer-MatchInfoContent'>
        <div className='match-dropdown-container ddNoRSpace'><MatchDropdown singleMatch={singleMatch}  searchMatchIds={searchMatchIds}/></div>
        <MatchInfo context={contextObj} singleMatch={singleMatch} />
    </Sticky>
}