import React, { useEffect, useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import OddsHeader from './RCOddsHeader';
import RCOddsHeaderM from './RCOddsHeaderM';
import GearLegend from './GearLegend'
import RaceInfoDialog from './RaceInfoDialog';
import {
    HorseName, MobileHorseName,
    Jockey, Trainer, RaceNote, getRunnerData
} from './RCOddsTableBase';
import { SetBetSelect, IsBetSelected } from './RacingBetline';
import { isLocalMeeting } from '../../Common/home-common';
import { useQuickBet } from "./QuickBetHook";
import symbol_plus from '../../../info/Include/images/symbol_plus_blue_s.svg'
import symbol_star from '../../../info/Include/images/symbol_star_blue_s.svg'

import { RacingContext } from '../../Home/RacingPage';
import { GlobalContext } from '../../../contexts/GlobalContext';
import CheckBox from '../../Common/ui-components/CheckBox';
import { isStartSellpmPool } from './Common';
import { sprintf } from '../../Common/GlobalFunc';

import { clipboardIndicatorStyle } from './ClipboardCommon';

const RCOddsTable = (props) => {
    const [error, setError] = useState(false);
    const [scnUpdate, setScreenUpdate] = useState({});
    const context = useContext(RacingContext);
    const globalContext = useContext(GlobalContext);

    const { t, i18n } = useTranslation();
    let pageConfig = context.pageConfig;
    function TrumpPreference({ tp, id}) {
        const isIcon=tp.trumpCard || tp.priority;
        return <div className='rc-odds-table-tp' id={id}>
            {isIcon &&
                <div className='tp-tc-and-pri'>
                    <span>{tp.trumpCard && <img className="symbol-plus" src={symbol_plus} alt='' />}</span>
                    <span>{tp.priority && <img className="symbol-star" src={symbol_star} alt='' />}</span>
                </div>
            }
            <div className={`tp-pref ${isIcon?'':'tp-djaC'}`} >
                {tp.pref && <span className='number'>{tp.pref}</span>}
            </div>
        </div>
    }

    // useEffect(() => {
    //     globalContext?.setUserInfo({ isExpired: true })
    // }, [])

    const handleClickOdds = (_type, _race, _col, _no, e) => {
        let isChecked = e.target.checked;
        SetBetSelect(_type, _race, _col, _no, isChecked);

        if (props.oddsCheckboxClickCallback != null) {
            props.oddsCheckboxClickCallback(_type, _race, _col, _no, isChecked);
        }
        context.racingFuncs.resetInvCal();
    }

    function isinJTComboCriteria(runner, meeting, raceNo) {
        const jtcPara = globalContext.globalState.jtcPara;
        if (jtcPara == null || jtcPara.length == 0)
            return true;

        const isNoBetService = meeting.poolInvs.filter(x => x.leg.races[0] === raceNo).length === 0;
        if ( isNoBetService ) {
            return false;
        }

        let matchJ = false;
        let matchT = false;
        let matchW = false;
        if (jtcPara[0] == 'ALL')
            matchJ = true;
        else if (jtcPara[0] == 'TOP5') {
            if (jtcPara[3].indexOf(runner.jockyId) >= 0)
                matchJ = true;
        }
        else {
            if (jtcPara[0] == runner.jockyId)
                matchJ = true;
        }

        if (jtcPara[1] == 'ALL')
            matchT = true;
        else if (jtcPara[1] == 'TOP5') {
            if (jtcPara[4].indexOf(runner.trnId) >= 0)
                matchT = true;
        }
        else {
            if (jtcPara[1] == runner.trnId)
                matchT = true;
        }

        if (jtcPara[2] == 'ALL')
            matchW = true;
        else {
            let tOdds = runner.winOdds.odds;
            if (tOdds == '')
                matchW = true;
            else if (isNaN(tOdds))
                matchW = false;
            else {
                switch (jtcPara[2]) {
                    case "0":
                        if (parseFloat(tOdds) <= 5)
                            matchW = true;
                        break;
                    case "1":
                        if (parseFloat(tOdds) <= 15 && parseFloat(tOdds) > 5)
                            matchW = true;
                        break;
                    case "2":
                        if (parseFloat(tOdds) > 15)
                            matchW = true;
                        break;
                }
            }
        }

        return matchJ && matchT && matchW;
    }

    const displayRunnerRow = (columns, runner, isLocal) => {
        if (!columns.showStandby) {
            if ( isLocal )
                return runner.status.toUpperCase()!="STANDBY";
            else {
                if ( context.content.page=="HOME" ) {
                    return runner?.no!=null && !["STANDBY", "SCRATCHEDRESERVED"].includes(runner?.status?.toUpperCase());
                }
                return runner.no!=null;
            }
        }
        else {
            if ( isLocal )
                return runner.status.toUpperCase()=="STANDBY";
            else
                if ( context.content.page=="HOME" ) {
                    return runner.no!=null && ["STANDBY", "SCRATCHEDRESERVED"].includes(runner?.status?.toUpperCase());
                }
                return false;
        }
    }

    const meetingRowProps = {
        ...props,
        context,
        pageConfig,
        displayRunnerRow,
        isinJTComboCriteria,
        handleClickOdds,
        TrumpPreference,
        scnUpdate,
        setScreenUpdate,
        setError
    }

    // context.racingFuncs.copyClipboardLeftOddsTable = () => {
    //     return clipboardMeetingRow({...meetingRowProps}, t, i18n);
    // }
    // context.racingFuncs.copyClipboardRightOddsTable = null;

    if (error) {
        return <div>Error: {error.message}</div>;
    } else {

        return (
            <div>
                <table className="rc-odds-table pr">
                    <MeetingRow {...meetingRowProps}/>
                </table>
                <table className="rc-odds-table-m pr">
                    <MobileMeetingRow {...meetingRowProps}/>
                </table>
            </div>
        )
    }
}
export default RCOddsTable;
function MeetingRow(props) {

    const { handleClickCombOdds } = useQuickBet();
    const {
        context,
        pageConfig,
        displayRunnerRow,
        isinJTComboCriteria,
        handleClickOdds,
        TrumpPreference,
        scnUpdate,
        setScreenUpdate,
        setError
    } = props
    const { t, i18n } = useTranslation();
    let rows = [];
    let startRace = context.content.raceNo;
    let endRace = context.content.raceNo;
    let mtg = context.content.meeting;
    if (props.allRaces) {
        startRace = 1;
        endRace = Math.max(...mtg.races.map(x=>x.no));
    }

    let isLocal = isLocalMeeting(context.content.venue);
    let cnt = 0;
    let showIntRating = false;
    for (let j = startRace; j <= endRace; j++) {
        let race = mtg.races.filter((data) => data.no == j)[0];
        if ( !race ) {
            continue;
        }
        showIntRating = race.ratingType==="I";
        let runners = race.runners;
        // let tempWinSt = false;
        // let tempWinSellSt = false;
        // let tempPlaSt = false;
        // let tempPlaSellSt = false;
        let winPool = null;
        let plaPool = null;
        let currentRaceOrBefore = j <= mtg.currentNumberOfRace;
        if (mtg.poolInvs != null) {
            winPool = mtg.poolInvs.filter((data) => data.leg.races[0] == j && data.oddsType=="WIN")[0];
            plaPool = mtg.poolInvs.filter((data) => data.leg.races[0] == j && data.oddsType=="PLA")[0];
        }

        // let winDisable = tempWinSt != "START_SELL" || tempWinSellSt != "START_SELL";
        // let plaDisable = tempPlaSt != "START_SELL" || tempPlaSellSt != "START_SELL";
        let winDisable = !isStartSellpmPool(winPool);
        let plaDisable = !isStartSellpmPool(plaPool);
        let w_pDisable = winDisable || plaDisable;
        const disableF = runners?.filter((runner) => !['SCRATCHED', 'SCRATCHEDRESERVED', 'STANDBY'].includes(runner.status.toUpperCase())).length == 0;
        for (let i = 0; i < runners.length; i++) {
            if ( !displayRunnerRow(props.columns, runners[i], isLocal) ) {
                continue;
            }

            let runner = getRunnerData(props.columns, context, t, isLocal, mtg, runners[i], j, mtg.pmPools, i18n.language);

            if (!isinJTComboCriteria(runner, mtg, j))
                continue;
            if("JTCOMBO" == context.content.page && ['SCRATCHED', 'SCRATCHEDRESERVED', 'STANDBY'].includes(runner.st?.toUpperCase())){
                continue;
            }

            let enableWinQuickBet = !runner.selDisable && !winDisable && runner.winOdds.odds!="";
            let enablePlaQuickBet = !runner.selDisable && !plaDisable && runner.plaOdds.odds!="";
            let scratchCol = 4;
            if(context.content.page=="HOME")
            {
                scratchCol = (props.columns.rtg && showIntRating) ? 9 : 8;
            }
            let row = <tr className={`rc-odds-row ${cnt % 2 == 0 ? '' : 'sec'} ${runner.isScratch ? 'rc-row-scratch':''}`} key={`rc-odds-row-${cnt}`}>
                {props.columns.raceNo ? <td className="rc-tp" id={`raceNo_${j}_${runner.no}`}>{j}</td> : null}
                {props.columns.tp ? <td className="rc-tp" ><TrumpPreference tp={runner.tp} id={`tp_${j}_${runner.no}`}/></td> : null}

                {props.columns.no ? <td id={`runnerNo_${j}_${runner.no}`} className="rc-no">{runner.no}</td> : null}

                {props.columns.colour ? <td id={`colour_${j}_${runner.no}`} className="rc-colour">{runner.colorTxt}</td> : null}

                {props.columns.horseName ? <HorseName t={t} isLocal={isLocal} raceNo={j} runnerNo={runner.no} name={runner.name} racingColorFileName={runner.racingColorFileName} /> : null}

                {props.columns.rcNote ? <RaceNote isLocal={isLocal} raceNo={j} runnerNo={runner.no} horseId={runner.horseId} /> : null}

                {runner.isScratch ? <td className="rc-odds-scratch" id={`scratch_${j}_${runner.no}`} colSpan={scratchCol}><div><div style={{ display: "inline-block" }}>{`(${t('LB_RC_SCR')})`}</div></div></td> : null}
                {!runner.isScratch && props.columns.draw ? <td id={`draw_${j}_${runner.no}`} className="rc-odd-draw">{!props.columns.showStandby ? runner.draw : ""}</td> : null}
                {!runner.isScratch && props.columns.wt ? <td id={`handicapWt_${j}_${runner.no}`} className="rc-odd-wt">{runner.wt}</td> : null}
                {!runner.isScratch && props.columns.jocky && !props.columns.showStandby ?  <Jockey t={t} isLocal={isLocal} raceNo={j} runnerNo={runner.no} jockyId={runner.jockyId} jocky={runner.jocky}
                    alw={props.columns.alw ? runner.alw : ''} /> : runner.isScratch || !props.columns.showStandby ?  null : <td className='jocky'></td>}
                {!runner.isScratch && props.columns.trainer ? <Trainer t={t} isLocal={isLocal} raceNo={j} runnerNo={runner.no} trnId={runner.trnId} trn={runner.trn} /> : null}
                {!runner.isScratch && props.columns.bodyWT ? <td id={`horseWt_${j}_${runner.no}`} className="rc-wt">{runner.bodyWT}</td> : null}
                {!runner.isScratch && props.columns.rtg && showIntRating ? <td id={`intRating_${j}_${runner.no}`} className="rc-rtg">{runner.intRtg}</td> : null}
                {!runner.isScratch && props.columns.rtg ? <td id={`rating_${j}_${runner.no}`} className="rc-rtg">{runner.rtg}</td> : null}
                {!runner.isScratch && props.columns.gear ? <Gear raceNo={j} runnerNo={runner.no} gearinfo={runner.gear} /> : null}
                {!runner.isScratch && props.columns.last6 ? <td id={`last6run_${j}_${runner.no}`}
                className="rc-last6"
                dangerouslySetInnerHTML={{ __html: runner?.last6?.replace(/\//g, (match) => {
                    return '<wbr/>' + match
                }) }}></td> : null}
                {props.columns.pwin0 ? <td id={`odds_PWIN0_${j}_${runner.no}`} className={`rc-checkbox rc-odds`}>{runner.pwinOdds0}</td> : null}
                {props.columns.pwin1 ? <td id={`odds_PWIN1_${j}_${runner.no}`} className={`rc-checkbox rc-odds`}>{runner.pwinOdds1}</td> : null}
                {
                    props.columns.win ?
                        <td className="rc-checkbox rc-odds">
                            {!pageConfig.hideCheckbox ? <CheckBox  id={`wpleg_WIN_${j}_${runner.no}`} disabled={runner.selDisable || winDisable || IsBetSelected("WIN", j, 'leg', "F") ? 'disabled' : ''}
                                onChange={(e) => handleClickOdds('WIN', j, 'leg', runner.no, e)} defaultChecked={IsBetSelected('WIN', j, 'leg', runner.no)} /> : null}
                            <div id={`odds_WIN_${j}_${runner.no}`} className={`${runner.winOdds.className}`}  onClick={()=> (enableWinQuickBet) && handleClickCombOdds(runner, 'WIN',j)}>
                                {enableWinQuickBet ? <a>{runner.winOdds.odds}</a> : <span>{runner.winOdds.odds}</span>}
                            </div>
                        </td> : null
                }
                {
                    props.columns.place ?
                        <td className="rc-checkbox rc-odds">
                            {!pageConfig.hideCheckbox ? <CheckBox  id={`wpleg_PLA_${j}_${runner.no}`} disabled={runner.selDisable || plaDisable || IsBetSelected("PLA", j, 'leg', "F") ? 'disabled' : ''}
                                onChange={(e) => handleClickOdds('PLA', j, 'leg', runner.no, e)} defaultChecked={IsBetSelected('PLA', j, 'leg', runner.no)} /> : null}
                            <div id={`odds_PLA_${j}_${runner.no}`} className={`${runner.plaOdds.className}`} onClick={()=> (enablePlaQuickBet) && handleClickCombOdds(runner,"PLA",j)}>
                                {enablePlaQuickBet ? <a>{runner.plaOdds.odds}</a> : <span>{runner.plaOdds.odds}</span>}
                            </div>
                        </td> : null
                }
                {
                    props.columns.wp ? <td className="rc-checkbox">
                        <CheckBox  id={`wpleg_W-P_${j}_${runner.no}`} disabled={runner.selDisable || w_pDisable || IsBetSelected("W-P", j, 'leg', "F") ? 'disabled' : ''}
                            onChange={(e) => handleClickOdds('W-P', j, 'leg', runner.no, e)} defaultChecked={IsBetSelected('W-P', j, 'leg', runner.no)} />
                    </td> : null
                }
                {props.columns.scheduledRides ? <HorseName t={t} isLocal={isLocal} name={runner.name} racingColorFileName={runner.racingColorFileName} /> : null}
            </tr>;
            rows.push(row);
            cnt++;
        }

        if (pageConfig.showField) {
            let full = <tr className="rc-odds-row full">
                <td style={{ textAlign: "center" }}>F</td>
                <td></td>
                <td className="rc-checkbox rc-odds text-align-left">{t('LB_RC_POOL_FULL')}</td>
                {props.columns.rcNote && isLocalMeeting(mtg.venueCode) && <td></td>}
                {props.columns.wt && <td></td>}
                {props.columns.draw && <td></td>}
                {props.columns.jocky && <td></td>}
                {props.columns.trainer && <td></td>}
                {props.columns.win ? <td className="rc-checkbox rc-odds"><CheckBox  id={`wpleg_WIN_${j}_F`} disabled={winDisable || disableF} onClick={(e) => handleClickOdds('WIN', j, 'leg', 'F', e)} defaultChecked={IsBetSelected("WIN", j, 'leg', "F")} /><div><a></a></div></td> : null}
                {props.columns.place ? <td className="rc-checkbox rc-odds"><CheckBox  id={`wpleg_PLA_${j}_F`} disabled={plaDisable || disableF} onClick={(e) => handleClickOdds('PLA', j, 'leg', 'F', e)} defaultChecked={IsBetSelected("PLA", j, 'leg', "F")} /><div><a></a></div></td> : null}
                {props.columns.wp ? <td style={{ textAlign: "center" }} className="rc-checkbox"><CheckBox  id={`wpleg_W-P_${j}_F`} disabled={w_pDisable || disableF} onClick={(e) => handleClickOdds('W-P', j, 'leg', 'F', e)} defaultChecked={IsBetSelected("W-P", j, 'leg', "F")} /></td> : null}
            </tr>;

            rows.push(full);

        }
    }

    let output = [];
    if (cnt > 0) {
        output.push(<OddsHeader columns={{...props.columns, intRtg: props.columns.intRtg && showIntRating }} />);
    }
    else {
        rows.push(<tr style={{ padding: "12px" }}>{isLocalMeeting(mtg.venueCode) ? t('LB_RC_JTC_NOINFO') : <>{t('LB_RC_OVERSEA_NOINFO1')}<br/>{t('LB_RC_OVERSEA_NOINFO2')}</> }</tr>);
    }
    output = output.concat(rows);
    return output;

}

function MobileMeetingRow(props) {
    const {
        context,
        pageConfig,
        displayRunnerRow,
        isinJTComboCriteria,
        handleClickOdds
    } = props
    let rows = [];
    let mobileRows = [];
    const { t, i18n } = useTranslation();
    let startRace = context.content.raceNo;
    let endRace = context.content.raceNo;
    let mtg = context.content.meeting;
    const { userInfo } = useContext(GlobalContext);
    const isExpired = userInfo?.isExpired
    if (props.allRaces) {
        startRace = 1;
        endRace = mtg.totalNumberOfRace;
    }

    let isLocal = isLocalMeeting(context.content.venue);
    let cnt = 0;
    for (let j = startRace; j <= endRace; j++) {
        let race = mtg.races.filter((data) => data.no == j)[0];
        if ( !race ) {
            continue;
        }
        let runners = race.runners;
        // let tempWinSt = false;
        // let tempWinSellSt = false;
        // let tempPlaSt = false;
        // let tempPlaSellSt = false;
        let winPool = null;
        let plaPool = null;
        if (mtg.poolInvs != null) {
            winPool = mtg.poolInvs.filter((data) => data.leg.races[0] == j && data.oddsType=="WIN")[0];
            plaPool = mtg.poolInvs.filter((data) => data.leg.races[0] == j && data.oddsType=="PLA")[0];
        }
        // let winDisable = tempWinSt != "START_SELL" || tempWinSellSt != "START_SELL";
        // let plaDisable = tempPlaSt != "START_SELL" || tempPlaSellSt != "START_SELL";
        let winDisable = !isStartSellpmPool(winPool);
        let plaDisable = !isStartSellpmPool(plaPool);
        let w_pDisable = winDisable || plaDisable;
        const disableF = runners?.filter((runner) => !['SCRATCHED', 'SCRATCHEDRESERVED', 'STANDBY'].includes(runner.status.toUpperCase())).length == 0;
        for (let i = 0; i < runners.length; i++) {
            if ( !displayRunnerRow(props.columns, runners[i], isLocal) ) {
                continue;
            }

            let runner = getRunnerData(props.columns, context, t, isLocal, mtg, runners[i], j, mtg.pmPools, i18n.language);

            if (!isinJTComboCriteria(runner, mtg, j))
                continue;
            if("JTCOMBO" == context.content.page && ['SCRATCHED', 'SCRATCHEDRESERVED', 'STANDBY'].includes(runner.st?.toUpperCase())){
                continue;
            }
            let rcInfo = {
                betType: context.content.page,
                no: runner.no,
                horName: runner.name,
                color: runner.isReserve ? '-' : runner.racingColorFileName,//runner.st === "Reserved" ? runner.racingColorFileName : '-',
                horseId: runner.horseId,
                jockyId: runner.jockyId,
                jockyName: !runner.isScratch ? runner.jocky : '-',
                trnId: runner.trnId,
                trnName: !runner.isScratch ? runner.trn : '-',
                draw: runner.draw,
                handicapWT: runner.wt,
                bodyWT: isLocal ? runner.bodyWT : '-',
                last6: runner.last6,
                rating: runner.rtg,
                intRating: runner.intRtg,
                age: runner.age,
                sex: runner.sex,
                bestTime: runner.bestTime,
                gear: runner.gear,
                wtAgeAllowance: runner.wtAgeAllowance,
                tCard: runner.tp.trumpCard,
                pRun: runner.tp.priority,
                OrderOfTrnPre: runner.tp.pref,
                isScratch: runner.isScratch,
                isReserved: runner.isReserve,
                alw: runner.alw,
                isLocal: isLocal,
                rcNote: props.columns.rcNote,
                showStandby: props.columns.showStandby,
                showIntRating: race.ratingType==="I"
            }
            let row = <tr className={`rc-odds-row-m ${cnt % 2 == 0 ? '' : 'sec'}`} key={`rc-odds-row-m-${cnt}`}>
                {props.columns.raceNo ? <td className="no">{j}</td> : null}
                {props.columns.no ? <td className="no" role="button" aria-label={`${t('LB_AS_NUMBER_X').replace('X', runner.no)}${runner.resTxt}`}>
                    <div className="runner-no-colour font-size-15 font-color-blue bold"><div className="runner-no">{runner.no}{runner.resTxt}</div>{props.columns.colour ? <div id={`colour_${j}_${runner.no}`} className="rc-colour">{runner.colorTxt}</div> : null}</div>
                </td> : null}

                {props.columns.horseName ? <MobileHorseName t={t} columns={props.columns} runner={runner} isLocal={isLocal} /> : null}
                {props.columns.pwin0 ? <td className="rc-checkbox rc-odds-m rc-odds-text">{runner.pwinOdds0}</td> : null}
                {props.columns.pwin1 ? <td className="rc-checkbox rc-odds-m rc-odds-text">{runner.pwinOdds1}</td> : null}
                {
                    props.columns.win ?
                        <td className="rc-checkbox rc-odds-m">
                            <div id={`odds_WIN_${j}_${runner.no}`} className={`${runner.winOdds.className} win`}><span className='rc-odds-data-text' role="button" aria-label={`${sprintf(t('LB_AS_READ_ODDS_WITH_BETTYPE'), j, runner.no, t('LB_RC_ODDS_TABLE_WIN'), runner.winOdds.odds)}`}>{runner.winOdds.odds}</span>
                                <CheckBox  id={`wpleg_WIN_${j}_${runner.no}`} disabled={isExpired ||runner.selDisable || winDisable || IsBetSelected("WIN", j, 'leg', "F") ? 'disabled' : ''}
                                    onChange={(e) => handleClickOdds('WIN', j, 'leg', runner.no, e)} defaultChecked={IsBetSelected('WIN', j, 'leg', runner.no)} />
                            </div>
                        </td> : null
                }
                {
                    props.columns.place ?
                        <td className="rc-checkbox rc-odds-m">
                            <div id={`odds_PLA_${j}_${runner.no}`} className={`${runner.plaOdds.className} pla`}><span className='rc-odds-data-text' role="button" aria-label={`${sprintf(t('LB_AS_READ_ODDS_WITH_BETTYPE'), j, runner.no, t('LB_RC_ODDS_TABLE_Place'), runner.plaOdds.odds)}`}>{runner.plaOdds.odds}</span>
                                <CheckBox  id={`wpleg_PLA_${j}_${runner.no}`} disabled={isExpired || runner.selDisable || plaDisable || IsBetSelected("PLA", j, 'leg', "F") ? 'disabled' : ''}
                                    onChange={(e) => handleClickOdds('PLA', j, 'leg', runner.no, e)} defaultChecked={IsBetSelected('PLA', j, 'leg', runner.no)} />
                            </div>
                        </td> : null
                }
                {
                    props.columns.wp ? <td className="rc-checkbox rc-odds-m">
                        <div className={`wp`}>
                            <span className='rc-odds-data-text'></span>
                            <CheckBox  id={`wpleg_W-P_${j}_${runner.no}`} disabled={isExpired || runner.selDisable || w_pDisable || IsBetSelected("W-P", j, 'leg', "F") ? 'disabled' : ''}
                                onChange={(e) => handleClickOdds('W-P', j, 'leg', runner.no, e)} defaultChecked={IsBetSelected('W-P', j, 'leg', runner.no)} />
                        </div>
                    </td> : null
                }
                {
                    props.columns.draw && props.columns.standby ? <td role="button"  aria-label={runner.isScratch ? t('LB_RC_SCR') : t('LB_AS_DRAW_X').replace('X', runner.draw)} className={`rc-text rc-odds-m draw ${runner.isScratch ? 'rc-text-scratch' : ''}`}>
                        {runner.isScratch ? t('LB_RC_SCR'): runner.isReserve ? ''  : <label>{runner.draw}</label>}
                    </td> : null
                }
                {
                    props.columns.wt && props.columns.standby ? <td role="button"  aria-label={runner.isScratch ? '' : t('LB_AS_WT_X').replace('X', runner.wt)} className="rc-text rc-odds-m wt">
                        <label>{runner.isScratch ? '' : runner.wt}</label>
                    </td> : null
                }
                <RaceInfoDialog info={rcInfo} />


            </tr>;
            rows.push(row);
            cnt++;
        }

        if (pageConfig.showField) {
            let full = <div className="rc-odds-row-m full">
                <div style={{ textAlign: "center" }}>F</div>
                <div className="rc-checkbox rc-odds-m">{t('LB_RC_POOL_FULL')}</div>
                {props.columns.win ? <div className="rc-checkbox rc-odds-m">
                    <div className="win">
                    <span></span>
                        <CheckBox  id={`wpleg_WIN_${j}_F`} disabled={isExpired || winDisable || disableF} onClick={(e) => handleClickOdds('WIN', j, 'leg', 'F', e)} defaultChecked={IsBetSelected("WIN", j, 'leg', "F")} />
                    </div>
                </div> : null}
                {props.columns.place ? <div className="rc-checkbox rc-odds-m">
                    <div className="pla">
                    <span></span>
                        <CheckBox  id={`wpleg_PLA_${j}_F`} disabled={isExpired || plaDisable || disableF} onClick={(e) => handleClickOdds('PLA', j, 'leg', 'F', e)} defaultChecked={IsBetSelected("PLA", j, 'leg', "F")} />
                    </div>
                </div> : null}
                {props.columns.wp ? <div style={{ textAlign: "center" }} className="rc-checkbox rc-odds-m">
                    <div className="wp">
                    <span></span>
                        <CheckBox  id={`wpleg_W-P_${j}_F`} disabled={isExpired || w_pDisable || disableF} onClick={(e) => handleClickOdds('W-P', j, 'leg', 'F', e)} defaultChecked={IsBetSelected("W-P", j, 'leg', "F")} />
                    </div>
                    </div> : null}
                <div></div>
            </div>;
            rows.push(full);
        }
    }


    let output = [];
    if (cnt > 0) {
        output.push(<RCOddsHeaderM columns={props.columns} />);
    }
    else {
        rows.push(<div style={{ padding: "12px" }}>{isLocalMeeting(mtg.venueCode) ? t('LB_RC_JTC_NOINFO') : <>{t('LB_RC_OVERSEA_NOINFO1')}<br/>{t('LB_RC_OVERSEA_NOINFO2')}</> }</div>);
    }
    output = output.concat(rows);
    return output;
}

function Gear({ raceNo, runnerNo, gearinfo }) {
    const [showGearLegend, setGearLegendStatus] = useState(false)

    const handleClickGear = (e) => {
        if (!gearinfo) return
        setGearLegendStatus(true)
    }
    const handleLeaveGear = (e) => {
        setGearLegendStatus(false)
    }
    return (<td id={`gear_${raceNo}_${runnerNo}`} className='gearinfo-box pr rc-gear' onClick={handleClickGear} onMouseLeave={handleLeaveGear} >
        <span className='cp gear-overflow'>{gearinfo}</span>
        {showGearLegend && <GearLegend gearData={gearinfo} className='race-gear-legend pa' />}
    </td>)
}

// function clipboardMeetingRow(props, t, i18n) {
//     const {
//         context,
//         displayRunnerRow,
//         isinJTComboCriteria
//     } = props
//     let rows = [];
//     let startRace = context.content.raceNo;
//     let endRace = context.content.raceNo;
//     let mtg = context.content.meeting;
//     if (props.allRaces) {
//         startRace = 1;
//         endRace = Math.max(...mtg.races.map(x=>x.no));
//     }

//     let isLocal = isLocalMeeting(context.content.venue);
//     let cnt = 0;
//     let showIntRating = false;
//     for (let j = startRace; j <= endRace; j++) {
//         let race = mtg.races.filter((data) => data.no == j)[0];
//         if ( !race ) {
//             continue;
//         }
//         showIntRating = race.ratingType==="I";
//         let runners = race.runners;

//         for (let i = 0; i < runners.length; i++) {
//             if ( !displayRunnerRow(props.columns, runners[i], isLocal) ) {
//                 continue;
//             }

//             let runner = getRunnerData(props.columns, context, t, isLocal, mtg, runners[i], j, mtg.pmPools, i18n.language);

//             if (!isinJTComboCriteria(runner, mtg, j))
//                 continue;

//             let scratchCol = 4;
//             if(context.content.page=="HOME")
//             {
//                 scratchCol = (props.columns.rtg && showIntRating) ? 9 : 8;
//             }

//             let colorHtml = document.getElementById(`colour_${j}_${runner.no}`)?.innerHTML;

//             let row = `<tr>
//                 ${props.columns.raceNo ? `<td>${j}</td>` : ""}
//                 ${props.columns.tp ? `<td>
//                     <span>${runner.tp.trumpCard ? `<img className="symbol-plus" src="${symbol_plus}" />` : ''}</span>
//                     <span>${runner.tp.priority ? `<img className="symbol-star" src="${symbol_star}" />` : ''}</span>
//                 </td>` : ""}
//                 ${props.columns.no ? `<td>${runner.no}</td>` : ""}
//                 ${props.columns.colour ? `<td>${colorHtml}</td>` : ""}
//                 ${props.columns.horseName ? `<td>${runner.name}</td>` : ""}
//                 ${runner.isScratch ? `<td colSpan="${scratchCol}">(${t('LB_RC_SCR')})</td>` : ""}
//                 ${!runner.isScratch && props.columns.draw ? `<td>${!props.columns.showStandby ? runner.draw : ""}</td>` : ""}
//                 ${!runner.isScratch && props.columns.wt ? `<td>${runner.wt}</td>` : ""}
//                 ${!runner.isScratch && props.columns.jocky && !props.columns.showStandby ?  `<td>${runner.jocky} ${props.columns.alw && runner.alw ? `(${runner.alw})` : ""}</td>` : ""}
//                 ${!runner.isScratch && props.columns.trainer ? `<td>${runner.trn}</td>` : ""}
//                 ${!runner.isScratch && props.columns.bodyWT ? `<td>${runner.bodyWT}</td>` : ""}
//                 ${!runner.isScratch && props.columns.rtg && showIntRating ? `<td>${runner.intRtg}</td>` : ""}
//                 ${!runner.isScratch && props.columns.rtg ? `<td>${runner.rtg}</td>` : ""}
//                 ${!runner.isScratch && props.columns.gear ? `<td>${runner.gear}</td>` : ""}
//                 ${!runner.isScratch && props.columns.last6 ? `<td>${runner.last6}</td>` : ""}
//                 ${props.columns.pwin0 ? `<td>${runner.pwinOdds0}</td>` : ""}
//                 ${props.columns.pwin1 ? `<td>${runner.pwinOdds1}</td>` : ""}
//                 ${props.columns.win ? `<td style="${clipboardIndicatorStyle(context, runner.winOdds.hf, runner.winOdds.od)}">${runner.winOdds.odds}</td>` : ""}
//                 ${props.columns.place ? `<td style="${clipboardIndicatorStyle(context, runner.plaOdds.hf, runner.plaOdds.od)}">${runner.plaOdds.odds}</td>` : ""}
//             </tr>`;
//             rows.push(row);
//             cnt++;
//         }

//     }

//     let output = [];
//     if (cnt > 0) {
//         output.push(clipboardHeader({...props.columns, intRtg: props.columns.intRtg && showIntRating }, context, t));
//     }
//     output = output.concat(rows);
//     return `<table>${output.join('')}</table>`;

// }