import React, { useState, useRef, useEffect } from 'react';
import Slider from "react-slick";
import { useWindowSize } from '../../Common/CommonHooks';
import ArrowIcon from '../../Common/ui-components/ArrowIcon';

const MultiLegSlider = (props) => {

  const { isMobileSize } = useWindowSize();

  const [slideIdx, setSlideIdx] = useState(0);
  let sliderRef = useRef();

  const gotoNext = () => {
    setSlideIdx(slideIdx + 1)
  }

  const goToBack = () => {
    setSlideIdx(slideIdx - 1)
  }

  const scrollToRaceCard = (currentIdx, nextIdx) => {
   // if (props.starterList != null && (props.starterList[currentIdx] > 16 || props.starterList[nextIdx] > 16) && currentIdx != nextIdx) {
      // let raceCardTable = document.getElementsByClassName("rc-odds-table-main");
      // if(raceCardTable != null && raceCardTable.length > 0){
      //   raceCardTable[0].scrollIntoView();
      // }
  //  }
  }

  useEffect(() => {
    if (props.children != null && props.children.length == 1) {
      setSlideIdx(0)
    }

    if (props.cnt == slideIdx) {
      setSlideIdx(props.cnt - 1);
    }
  }, [props]);

  useEffect(() => {
    sliderRef.slickGoTo(slideIdx);
  }, [slideIdx]);

  const settings = {
    arrows: false,
    centerMode: true,
    centerPadding: "8px",
    infinite: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    focusOnSelect: false,
    draggable: true,
    beforeChange: (current, next) => { setSlideIdx(next); scrollToRaceCard(current, next); },
    // afterChange: (next) => { setSlideIdx(next) },

    responsive: [
      {
        breakpoint: 9999,
        settings:  isMobileSize ? {} : 'unslick'
      },
      {
        breakpoint: 10000,
        settings: 'unslick'
      }
    ]
  };

  const generateBottomNode = () => {
    let output = [];
    if (props.cnt > 1) {
      for (let i = 0; i < props.cnt; i++) {
        let nodeCss = 'multi-leg-slide-grey-node';
        if (slideIdx == i)
          nodeCss = 'multi-leg-slide-blue-node';
        output.push(<div className={nodeCss}></div>);
      }
    }
    return output;
  }

  let bottomNodePos = "12px";
  if (document.querySelector('.menu-shown') != null) {
    bottomNodePos = "162px";
  }

  return (<>
    <Slider {...settings} className={props.className} ref={c => sliderRef = c}>
      {props.children}
    </Slider>
    <div className={`multi-leg-slide-btn-prev ${slideIdx <= 0 ? "hide" : ""}`} onClick={goToBack}>
      {/* <li className="arrow arrow-left border-white"></li> */}
      <div className='expand-clickable-area'></div>
      <ArrowIcon direction='left' color='white' size={18}/>
    </div>
    <div className={`multi-leg-slide-btn-next ${slideIdx + 1 >= props.cnt ? "hide" : ""}`} onClick={gotoNext}>
      {/* <li className="arrow arrow-right border-white"></li> */}
      <div className='expand-clickable-area'></div>
      <ArrowIcon direction='right' color='white' size={18}/>
    </div>
    <div style={{ bottom: bottomNodePos }} className="multi-leg-slide-bottom-node">
      {generateBottomNode()}
    </div>
  </>);
}
export default MultiLegSlider
