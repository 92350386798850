import * as forgerock from '@forgerock/javascript-sdk';
import { useEffect, useRef, useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { removeCurrentSessionRecords } from '../Common/CurrentSessionRecords';
import { glassboxSetUserInfo } from '../Common/utils/glassbox';
import { WATrackerTrackClickEvent } from '../Common/utils/wa';
import { set } from 'react-hook-form';
import { jwtDecode } from 'jwt-decode'
import { detectIncognito } from "detectincognitojs";
import { delay } from '../Common/CommonHooks';
import { Exception } from 'sass';

const FATAL = 'Fatal';
const tempConfig = {
    AM_URL: window.globalConfig.ARKLE_URL,
    REALM_PATH: 'customer',
    SCOPE: 'ls_profile hs_txn_session',
    TIMEOUT: window.globalConfig.LOGIN_TIMEOUT_DURATION,
    TREE: 'hkjcHSLogin-Web',
    WEB_OAUTH_CLIENT: 'JCBW',
    NX_NO_CLOUD: 'true'
}

const addHeadersMiddleware = (req, action, next) => {
    switch(action.type) {
        case "START_AUTHENTICATE":
            req.init.headers.append('Channel-Id', 'JCBW');
            break;
    }
    
    next();
};

forgerock.Config.set({
  clientId: tempConfig.WEB_OAUTH_CLIENT, 
  middleware: [
    addHeadersMiddleware
  ],
  redirectUri: `${window.location.origin}/callback`, 
  scope: tempConfig.SCOPE, 
  serverConfig: {
    baseUrl: tempConfig.AM_URL, 
    timeout: parseInt(tempConfig.TIMEOUT), 
  },
  realmPath: tempConfig.REALM_PATH,
  tree: tempConfig.TREE, 
  type: 'code'
});

export const useLoginLogout_FR = (props) => {
    const { t, i18n } = useTranslation();
    const [isShowOTPAuth, setIsShowOTPAuth] = useState(false);
    const [isShowOTPToast,setIsShowOTPToast] = useState(false);
    const [invalidOTP, setInvalidOTP] = useState(false);
    const [currentStep, setCurrentStep] = useState();
    const [isShowEKBAQuestion, setIsShowEKBAQuestion] = useState(false);
    const [isShowTrustBrowser, setIsShowTrustBrowser] = useState(false);
    const [jwtToken, setJwtToken] = useState('');
    const [mobile, setMobile] = useState('');
    const [showErrorPopup, setShowErrorPopup] = useState(false);
    const [showLoginLockPopup,setShowLoginLockPopup] = useState(false);
    const [isIncognito, setIsIncognito] = useState(false);
    const [isOnline, setIsOnline] = useState(navigator.onLine);
    const [isMissingMobile,setIsMissingMobile] = useState(false);
    // const [password, setPassword] = useState('');
    // const [loginIncorrectMsg, setLoginIncorrectMsg] = useState('');

    // const closeLoginIncorrectMsg = () => {
    //     setLoginIncorrectMsg(null);
    // };
    const {
        password,
        setPassword,
        loginIncorrectMsg,
        setLoginIncorrectMsg,
        closeLoginIncorrectMsg,
        checkAccountPassword,
        checkNewCIAMJwt,
        setLoginAPILoading,
        getEwinSessionID
    } = props;

    useEffect(() => {
        detectIncognito().then((result) => {
            console.log(result.browserName, result.isPrivate);
            setIsIncognito(result.isPrivate)
        });
    }, []);

    const next = async (step) =>{

        try {
            const rsp = await forgerock.FRAuth.next(step);
            if(window.showException == true) {
                window.showException = false;
                throw new Exception("test exception");
            } 
            if(rsp===undefined){
                //cant get rsp
                throw new Exception("NetworkError");
            }else {
                return rsp;
            }
            return rsp;
        } catch (error) {
            if (error.message.includes('NetworkError')||error.response) {
                console.error('Network error: Please check your internet connection.');
                setShowErrorPopup('network');
            }
            else if (error instanceof TypeError && error.message === 'Failed to fetch' || error.message ==='Load failed') {
                // Handle network connection lost error here
                console.error('Network connection lost. Please check your network settings.');
                setShowErrorPopup('network');
            }else{
                console.log('Error in next', error);
                setShowErrorPopup('exception');
            }

        }
    }
    
    // step 1 init
    const FR_initStep = async() => {
        try {
            let step = await next(null);
            const stage = step?.getStage();
            switch(stage) {
                case "pre-namepass":
                    step = await getPreNamepass(step);
                    break;
                case "otp":
                    break;
                case "trust-browser-confirm":
                    break;
            }
            
            setCurrentStep(step);

            return step;
        } catch (error) {
            console.log('Error in init', error);
        }
    }

    const getPreNamepass = async (step) => {

        const choiceCallback = step.getCallbackOfType('ChoiceCallback');
        //0 sso, customer always need to enter password so always set choice index to 1
        //if(sessionStorage.getItem('sso_web_id') && sessionStorage.getItem('sso_web_id').length > 0) {
        //    choiceCallback.setChoiceIndex(0);
        //} else {
            choiceCallback.setChoiceIndex(1);
        //}
        
        const rsp = await next(step);

        return rsp;
    }

    // step 2 login with user name and password
    const FR_loginWithUsernamePassword = async (username, password) => {
        // for testing convenience only
        await FR_Logout();
        let step = await FR_initStep();

        //let step = currentStep;
        let nextStep = step; //currentStep;

        try{
            if(step?.payload?.status == "200" && step?.payload.stage == "namepass"){
                const nameCallback = step.getCallbackOfType('NameCallback');
                const passwordCallback = step.getCallbackOfType('PasswordCallback');
                nameCallback.setName(username);
                passwordCallback.setPassword(password);
                nextStep = await next(step); //await forgerock.FRAuth.next(step);
            }

            if(nextStep?.payload?.status == "200" && nextStep?.payload.stage == "otp"){
                
                const otpCallback = JSON.parse(nextStep.getCallbackOfType('TextOutputCallback').getMessage())              
                setMobile({
                    "mobileNo": otpCallback.mobile,
                    "timeout": otpCallback.timeout
                })
                closeLoginIncorrectMsg();
                setIsShowOTPAuth(true);
                WATrackerTrackClickEvent('eWin_CIAM_S1a-OTP_whitelistWithMobile');
                window.sessionStorage.setItem("LoginWA", sessionStorage.getItem("LoginWA") + "WithMobile");
                step = nextStep;
            }else if(nextStep?.payload?.status == "200" && nextStep?.payload.stage == "trust-browser-confirm") {
                setIsShowTrustBrowser(true);
                setIsShowOTPAuth(false);
                setCIAMLoginIncorrectMsg('')
                //step = nextStep;
            } else if(nextStep?.type == "LoginSuccess"){
                setIsShowTrustBrowser(false);
                setCIAMLoginIncorrectMsg('')
                setIsShowOTPAuth(false); // hide otp modal if login success
                step = nextStep;

                await FR_LoginSuccess(step);
            } else if(nextStep?.payload?.status != "200" && nextStep?.type == "LoginFailure"){
                setIsShowTrustBrowser(false);
                setCIAMLoginIncorrectMsg('100103');
                await FR_Logout().then(FR_initStep());
            } else {
                if(!nextStep?.getCallbacksOfType('TextOutputCallback')?.length) {
                    return;
                }
                
                const rawMessage = nextStep?.getCallbackOfType('TextOutputCallback')?.getMessage();
                let message = null;
                try {
                    message = JSON.parse(nextStep?.getCallbackOfType('TextOutputCallback')?.getMessage());
                } catch(e) {
                    
                }
                const result = message? message.messageId?.replace('ERR', '') : rawMessage;

                await FR_Logout();
                await FR_initStep();
                switch(result){
                    case '100011': //No mobile number available
                        if(window.globalConfig.NEW_CIAM ==2||(window.globalConfig.NEW_CIAM==1&&sessionStorage.getItem('cutoverLogin')==1)){  
                            setIsMissingMobile(true)
                            break;
                        }else{
                            await checkAccountPassword(username, password, true);
                            WATrackerTrackClickEvent('eWin_eKBA_whitelistWithoutMobile');
                            window.sessionStorage.setItem("LoginWA", sessionStorage.getItem("LoginWA") + "WithoutMobile");
                            break;
                        }
                        
                    case '100008': //Not associated with a betting account
                        setCIAMLoginIncorrectMsg(result);
                        break;
                    case '100103': //blacklisted account
                        setCIAMLoginIncorrectMsg('100103');
                        setIsShowOTPAuth(false);
                        break;
                    case '100014': //locked account
                        setCIAMLoginIncorrectMsg('100014');
                        setIsShowOTPAuth(false);
                        break;
                    case '100003': //invalid sso
                        return 'logout';                        
                    default:
                        setCIAMLoginIncorrectMsg(result);
                        break;

                }
            }

            setCurrentStep(step);
                
        }catch(err){
            console.log('exception', err, showErrorPopup)
            if(showErrorPopup != true) { //if already show error popup, no need to show another error
                if (err.message === 'Timeout') {
                    setLoginIncorrectMsg('TIMEOUT');
                } else {
                    setIsShowTrustBrowser(false);
                    setCIAMLoginIncorrectMsg('100103')
                    console.error(err)
                }
            }
        }
    };

    // step 3 login with OTP
    const FR_loginWithOTP = async (otp) => {
        
        // const step = await setNextStep({otp: otp})
        let step = currentStep;
        let message = "";
        try{
            const textInputCallback = step.getCallbackOfType('TextInputCallback');
            textInputCallback.setInputValue(otp);
            const rsp = await next(step); //await forgerock.FRAuth.next(step);

            if(rsp?.payload?.status == "200" && rsp?.type == "LoginSuccess"){
                setIsShowTrustBrowser(false);
                setCIAMLoginIncorrectMsg('')
                setIsShowOTPAuth(false);
                setCurrentStep(rsp);
                await FR_LoginSuccess(rsp);
            } else if(rsp?.type == "LoginFailure"){
                switch(rsp?.payload.detail.errorCode){
                    case "110":
                        setShowErrorPopup('session timeout');
                        break;
                    default:
                        setShowErrorPopup('otp failure');
                        break;
                }
            } else{
                switch(rsp?.payload.stage){
                    case "errorinfo":
                        message = JSON.parse(rsp.getCallbackOfType('TextOutputCallback').getMessage());
                        console.debug('message', message);
                        setInvalidOTP(message?.messageId);
                        // step = await next(step); //await forgerock.FRAuth.next(rsp);
                        // setCurrentStep(step);
                        break;
                    case "trust-browser-confirm":
                        await handleConfirmTrustBrowser(rsp);
                        // setCurrentStep(rsp);
                        // setIsShowOTPAuth(false);
                        // setIsShowTrustBrowser(true);
                        // WATrackerTrackClickEvent('eWin_CIAM_S2-Trust-overlay_whitelistWithMobile');
                        break;
                    default:
                        setIsShowOTPAuth(false);
                        setIsShowTrustBrowser(false);
                        break;
                }
                
                    
            }
        }catch(err){
            console.error(err)
        }
        
        return message;
    }

    const handleConfirmTrustBrowser = async (step) => {

        if(isIncognito){
            console.log('isIncognito', isIncognito)
            window.sessionStorage.setItem("LoginWA", sessionStorage.getItem("LoginWA") + "_Incognito");
            await FR_TurstBrowser(false, step).then(() => {
                setIsShowOTPAuth(false);
                setIsShowTrustBrowser(false);
            });
        }else{
            if(step.payload.stage == "trust-browser-confirm" ) {
                setCurrentStep(step);
                setIsShowOTPAuth(false);
                setIsShowTrustBrowser(true);
                if(window.sessionStorage.getItem("LoginWA").includes('WithMobile')) {
                    WATrackerTrackClickEvent('eWin_CIAM_S2-Trust-overlay_whitelistWithMobile');
                } else if(window.sessionStorage.getItem("LoginWA").includes('WithoutMobile')) {
                    WATrackerTrackClickEvent('eWin_CIAM_S2-Trust-overlay_whitelistWithoutMobile');
                }
            } else {
                if(step.payload.stage == "errorinfo") {
                    const message = JSON.parse(step.getCallbackOfType('TextOutputCallback').getMessage());
                    setShowErrorPopup(message?.messageId);
                } else {
                    setShowErrorPopup('exception');
                }
            }
        }
    }

    // step 4 turst browser
    const FR_TurstBrowser = async(trust, preStep = null) => {
        try{
            let step = preStep ? preStep : currentStep;
            const textInputCallback = step.getCallbackOfType('ConfirmationCallback');
            textInputCallback.setInputValue(trust ? 0 : 1);

            const rsp = await next(step);

            if(rsp?.payload?.status == 200){
                setCIAMLoginIncorrectMsg('')

                if(rsp?.type == "LoginSuccess"){

                    await FR_LoginSuccess(rsp);
                   
                }else{
                    await FR_Logout().then(FR_initStep);
                }
            } else if (rsp?.type == "LoginFailure"){
                switch(rsp?.payload.detail?.errorCode){
                    case "110":
                        setShowErrorPopup('session timeout');
                        break;
                    default:
                        setShowErrorPopup('trust browser failure');
                        break;
                }
            }else{
                setShowErrorPopup('trust browser failure');
            }
            
            setCurrentStep(rsp);

            return rsp;
        } catch(err) {
            console.log("trused browser err", err);
            return null;
        }
    }


    const FR_ResendOTP = async () => {
        try{
        let step = currentStep;

        //"ConfirmationCallback"
        
        const confirmationCallback = step.getCallbackOfType('ConfirmationCallback');
        confirmationCallback.setOptionIndex(1);

        const nextStep = await next(step);
        
        if(nextStep?.payload?.status == "200" && nextStep?.payload.stage == "otp"){
                
            const otpCallback = JSON.parse(nextStep.getCallbackOfType('TextOutputCallback').getMessage())          
            setMobile({
                "mobileNo": otpCallback.mobile,
                "timeout": otpCallback.timeout
            })
            closeLoginIncorrectMsg();
            setIsShowOTPAuth(true);
          
            setIsShowOTPToast(true);
            WATrackerTrackClickEvent('eWin_CIAM_S1a-OTP-withMobile');

            step = nextStep;
            
        }else if(nextStep?.type == "LoginFailure"){

            switch(nextStep?.payload.detail.errorCode){
                case "110":
                    setShowErrorPopup('session timeout');
                    break;
                default:
                    setShowErrorPopup('otp resend failure');
                    break;
            }
            step = nextStep;
        }

        setCurrentStep(step);
        }catch(err) {
            console.log("Err:",err)
            setShowErrorPopup('session timeout');
            return null;
        }
    }

    const FR_LoginSuccess = async (step) => {
        const token = step.getSessionToken();        
        const accessTokens = await refreshToken(token);
        const jwtDecodeStr = jwtDecode(accessTokens)
        const sid = await getEwinSessionID();
        window.sessionStorage.setItem('session_id', sid)
        window.sessionStorage.setItem('account', jwtDecodeStr.betAcct)
        window.sessionStorage.setItem('gu_id', '')
        window.sessionStorage.setItem('sso_guid', '')
        
        await checkNewCIAMJwt(accessTokens, setLoginAPILoading)

        return accessTokens;
    }

    const FR_GetToken = async() =>{

        let step = currentStep;
        const token = await refreshToken();

        setJwtToken(token)

        return token;
    }
    
    const refreshToken = async (token) => {
        //authorize token
        const tokens = await forgerock.TokenManager.getTokens({
            forceRenew: true,
            login: 'embedded',
            csrf: token,
            decision: 'allow',
            logger: (l) => console.log('get token', l),
        });
        const accessTokens = tokens.accessToken;

        setJwtToken(accessTokens);

        return accessTokens;
    }

    const FR_Logout = async () => {
        return await forgerock.FRUser.logout();
    }

    const FR_NewEKBA = async()=>{
        try {
            console.log('NEW EKBA FR_initStep...');

            let step = await next(null);
            const choiceCallback = step.getCallbackOfType('ChoiceCallback');
            //0 sso
            choiceCallback.setChoiceIndex(0);
            const rsp = await next(step);
            setCurrentStep(rsp);
            handleConfirmTrustBrowser(rsp)
            return rsp;
            
        } catch (error) {
            console.log('Error in init', error);
        }
    }
    const setCIAMLoginIncorrectMsg = (error) => {

        if(error?.length > 0){
            setLoginIncorrectMsg(`CIAM_${error}`)
        }else{
            setLoginIncorrectMsg('')
        }
        
    }

    const FR_GetMobile = () => {
        return mobile;
    }

    return {
        FR_initStep,
        FR_loginWithUsernamePassword,
        FR_loginWithOTP,
        FR_TurstBrowser,
        FR_GetToken,
        isShowOTPAuth,
        setIsShowOTPAuth,
        isMissingMobile,
        setIsMissingMobile,
        invalidOTP, 
        setInvalidOTP,
        password,
        setPassword,
        loginIncorrectMsg,
        setLoginIncorrectMsg,
        closeLoginIncorrectMsg,
        isShowEKBAQuestion,
        setIsShowTrustBrowser,
        isShowTrustBrowser,
        jwtToken,
        FR_Logout,
        FR_GetMobile,
        FR_ResendOTP,
        showErrorPopup,
        setShowErrorPopup,
        showLoginLockPopup,
        setShowLoginLockPopup,
        isShowOTPToast,
        setIsShowOTPToast,
        FR_NewEKBA
    };
}