import React, { useEffect, useState, useLayoutEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import ShowPara from '../../ShowPara';
import Version from '../../Version';
import ShowDeviceInfo from '../../ShowDeviceInfo';
import MsgInbox from '../../components/Home/MsgInbox';
import PersonalSettings from '../../components/Home/PersonalSettings';
import Login from '../../components/Home/Login';
import StandardPage from './StandardPage';
import RebateCalculator from '../../components/Racing/Page/RebateCalculator';
import M6Calculator from '../../components/Marksix/Page/M6Calculator';
import FTCalculator from '../../components/Football/Page/FTCalculator';
import AccountRecords from '../../components/BetSlip/AccountRecords';
import FundsTransfer from '../../components/BetSlip/FundsTransfer';
import RaceByRace from '../../components/Racing/FO/FO_RaceByRace';
import { ConfigContext, GetAllConfig, QueryConfig } from '../../components/Common/ConfigHelper';
import { MyBetSlipContext } from '../../components/BetSlip/BetSlipHooks';
import AllupCombination from '../../components/Football/Page/AllupCombination';
import PayoutTime from '../../components/Football/Page/PayoutTime';
import SellingHours from '../../components/Football/Page/SellingHours';
import Help from '../../components/Football/Page/Help';
import { clearLoginSessionStorage } from '../../components/BetSlip/LoginIBHooks';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { useLoginLogout_IB } from '../../components/BetSlip/LoginIBHooks';
import { useLoginLogout } from '../../components/BetSlip/LoginHooks';
import { useLoginLogout_FR } from '../../components/BetSlip/LoginFRHooks';
import CIAMCallback from '../../components/BetSlip/CIAMCallback';
import { useCommonControlQuery, usePoolConfig } from '../../components/Home/Data/DataRefreshHooks';
import { useRacingNotesQuery } from '../../components/Racing/Data/RacingNotesHooks';

const CustomScrollbars = (props) => {
    // const { isMobileSize } = useWindowSize();
    return (
        <Scrollbars
            autoHide
            autoHideTimeout={2000}
            autoHideDuration={200}
            style={{ height: '100vh' }}
            autoHeight={false}
            // thumbMinSize={600}
            {...props}
        />
    );
};

function App() {
    const { t, i18n } = useTranslation();
    const [config, setConfig] = useState({});
    const noOfBetslipTabs = window.globalConfig.IS_SPEEDBET == true ? window.globalConfig.SPEEDBET_BASKET.length : 1;
    const [betBaskets, setBetBaskets] = useState(Array(Number(noOfBetslipTabs)).fill([]));
    const [currentBasket, setCurrentBasket] = useState(0);
    const [balance, setBalance] = useState();
    const [isSpeedbet, setIsSpeedbet] = useState(window.globalConfig.IS_SPEEDBET);
    const [betBaskets_0, setbetBaskets_0] = useState(sessionStorage.getItem('betBaskets'));
    const [firstTimeFromJCRWRedirect, setFirstTimeFromJCRWRedirect] = useState(sessionStorage.getItem('1stTimeFromJCRWRedirect'));
    const [password, setPassword] = useState('');
    const [loginIncorrectMsg, setLoginIncorrectMsg] = useState('');
    const closeLoginIncorrectMsg = () => {
        setLoginIncorrectMsg(null);
    };
    const login_shared_states = {
        password,
        setPassword,
        loginIncorrectMsg,
        setLoginIncorrectMsg,
        closeLoginIncorrectMsg
    };
    const loginLogoutProps = {
        config,
        betBaskets,
        setBetBaskets,
        currentBasket,
        setCurrentBasket,
        balance,
        setBalance,
        isSpeedbet,
        setIsSpeedbet,
        betBaskets_0,
        firstTimeFromJCRWRedirect,
        ...login_shared_states,
    }
    const loginLogoutHook = window.globalConfig.IS_IB ? useLoginLogout_IB(loginLogoutProps) : useLoginLogout(loginLogoutProps);
    const loginLogoutFRHook = useLoginLogout_FR({...login_shared_states, ...loginLogoutHook});
    const [cCtrlLoading, cCtrlData] = useCommonControlQuery();
    usePoolConfig();
    const racingNotesData = useRacingNotesQuery();
    const [globalState, setGlobalState] = useState({
        betType: '',
        product: '',
        betslipState: 0,
        mtg: null,
        jtcPara: [],
        commonCtrl: {},
        commonCtrlLoading: true
    });

    useLayoutEffect(() => {
        document.title = t('TITLE');
        clearLogin();
        const init = async () => {
            await Promise.all([
                // QueryConfig('racing', '/Config/RacingConfig.json'),
                //QueryConfig('football', '/Config/FootballConfig.json'),
                QueryConfig('marksix', '/Config/MarksixConfig.json'),

                //QueryConfig('racingPages', '/Config/RacingPageConfig.json'),
                QueryConfig('racingComingleMsgs', '/Config/RacingComingleMsg.json'),
                QueryConfig('racingGearConfig', '/Config/RacingGearConfig.json'),

                //QueryConfig('footballPages', '/Config/FootballPageConfig.json'),
                QueryConfig('marksixPages', '/Config/MarksixPageConfig.json'),
                // QueryConfig('betslipMenu', '/Config/BetslipConfig.json'),

                retryQueryChannelParas()
            ]);
            setConfig(GetAllConfig());
        };
        init();
    }, []);

    const retryQueryChannelParas = async () => {
        if ( sessionStorage.getItem("eWinParaDone")!="1" ) {
            await QueryConfig('channelParas', window.globalConfig.CHANNEL_PARA_URL);
            setTimeout(() => {
                retryQueryChannelParas();
            }, 2000);
        }
    }


    const clearLogin = () => {
        //don't remove token if URL contain these
        const keepLogin = ['/showpara', '/betslip/'];

        if (window.racingConfig["redirectIsReplaceWindow"] /* && document.referrer.indexOf(window.globalConfig.JCRW_URL) > -1 */ && firstTimeFromJCRWRedirect == 'true') { // JCRW redirect
            console.debug('redirect from jcrw, no clear login')
            return;
        }
        if (!keepLogin.some((el) => window.location.href.includes(el))) {
            sessionStorage.removeItem('access_token');
            sessionStorage.removeItem('refresh_token');
            sessionStorage.removeItem('id_token');
            sessionStorage.removeItem('cutoverLogin');
            //sessionStorage.removeItem('customerSegment');
            clearLoginSessionStorage();
        }
    };

    // SQ0248-2185 Force a re-render when rotating the screen
    useEffect(() => {
        const orientationchange =  function() {
            setTimeout(() => {
                document.body.style.borderWidth = Math.random() + 'px'
            })
        }
        window.addEventListener("orientationchange",orientationchange, false);
        return () => {
            window.removeEventListener("orientationchange",orientationchange, false);
        }
    },[])

    useEffect(() => {
        if (!cCtrlLoading) {
            racingNotesData.setGlobalEnable(cCtrlData.peNotes?.JCBWButtonEnabled);
            globalState.commonCtrl = cCtrlData.jcbwControl || {};
            globalState.commonCtrlLoading = cCtrlLoading;
            if (cCtrlData.jcbwControl?.enableGlassBox && !window.WAGdpr?.isEU()) {
                document.head.appendChild(window.gbscript);
            }
            setGlobalState({ ...globalState });
        }
    }, [cCtrlLoading]);

    return (
        <Router>
            <ConfigContext.Provider value={config}>
                <MyBetSlipContext.Provider
                    value={{
                        betBaskets,
                        setBetBaskets,
                        currentBasket,
                        setCurrentBasket,
                        balance,
                        setBalance,
                        isSpeedbet,
                        setIsSpeedbet,
                        betBaskets_0,
                        firstTimeFromJCRWRedirect
                    }}
                >
                    <div className='flex'>
                    <div
                        className={`${i18n.language} browserVersion ${
                            sessionStorage.getItem('isDeskTopVersion') === '1' ? 'isDeskTopVersion' : 'isDeviceVersion'
                        }`}
                    >
                        <Routes>
                            {/* General standalone page */}
                            <Route path="/showpara" element={<ShowPara />} />
                            <Route path="/version" element={<Version />} />
                            <Route path="/showdeviceinfo" element={<ShowDeviceInfo />} />
                            <Route path="/:lang/msgInbox" element={<MsgInbox />} />
                            <Route path="/:lang/msgInbox/:pd" element={<MsgInbox />} />
                            <Route path="/:lang/login" element={<Login type="Speedbet" loginLogout={loginLogoutHook} loginLogoutFR={loginLogoutFRHook} login_shared_states={login_shared_states}/>} />
                            <Route path="/:lang/eWin2/login" element={<Login type="eWin2" loginLogout={loginLogoutHook} loginLogoutFR={loginLogoutFRHook} login_shared_states={login_shared_states}/>} />

                            {/* Betslip standalone page */}
                            <Route path="/:lang/betslip/acctstmt" element={<AccountRecords />} />
                            <Route path="/:lang/betslip/acctstmt/:curTab" element={<AccountRecords />} />
                            <Route path="/:lang/betslip/eft" element={<FundsTransfer />} />
                            <Route path="/:lang/betslip/settings" element={<PersonalSettings />} />

                            {/* Racing standalone page */}
                            <Route path="/:lang/racing/rbc" element={<CustomScrollbars className="StandardPage-scrollbars"><RebateCalculator /></CustomScrollbars>} />
                            <Route
                                path="/:lang/racing/racebyrace/:type/:mtgdate/:vencode/:instno"
                                element={<RaceByRace />}
                            />

                            {/* Marksix standalone page */}
                            <Route path="/:lang/marksix/calculator" element={<CustomScrollbars><M6Calculator /></CustomScrollbars>} />

                            {/* Football standalone page */}
                            <Route path="/:lang/football/calculator" element={<CustomScrollbars><FTCalculator /></CustomScrollbars>} />
                            <Route path="/:lang/football/bet_six" element={<CustomScrollbars><AllupCombination /></CustomScrollbars>} />
                            <Route path="/:lang/football/payouttime" element={<PayoutTime />} />
                            <Route path="/:lang/football/sellinghours" element={<SellingHours />} />
                            <Route path="/:lang/football/help/settle" element={<Help />} />
                            <Route path="/callback" element={<CIAMCallback />} />

                            {/* JCBW page */}
                            <Route path="/*" element={<StandardPage loginLogoutHook={loginLogoutHook} loginLogoutFR={loginLogoutFRHook} login_shared_states={login_shared_states} globalState={globalState} setGlobalState={setGlobalState} racingNotesData={racingNotesData}  />} />
                        </Routes>
                    </div>
                    <div id='racing_chrome_extension'></div>
                    </div>
                </MyBetSlipContext.Provider>
            </ConfigContext.Provider>
        </Router>
    );
}

export default App;
