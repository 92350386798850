import React from 'react';
import { useTranslation } from 'react-i18next';
import { lblBr } from '../../Common/GlobalFunc';

const oddsMatrixTable = [
    ['01:00', '00:00', '00:01'],
    ['02:00', '01:01', '00:02'],
    ['02:01', '02:02', '01:02'],
    ['03:00', '03:03', '00:03'],
    ['03:01', '', '01:03'],
    ['03:02', '', '02:03'],
    ['04:00', '', '00:04'],
    ['04:01', '', '01:04'],
    ['04:02', '', '02:04'],
    ['05:00', '', '00:05'],
    ['05:01', '', '01:05'],
    ['05:02', '', '02:05'],
    ['OHN', '', 'OAN'],
    ['HOAN', '', 'AOHN'],
    ['HO', 'DO', 'AO'],
    ['HOAS', 'DO', 'AOHS']
]


export const isCRSOtherSels = (combStr) => {
    return ['HO', 'DO', 'AO', 'OHN', 'OAN', 'HOAS', 'AOHS', 'HOAN', 'AOHN'].includes(combStr);
}

export const getFirstNCombStr = (line, count) => {
    const crsOddsOrder = window.fbConfig.crsOddsOrder
    line.combinations.sort((x,y)=> {
        if ( x.status!=="AVAILABLE" && y.status==="AVAILABLE" )
            return 1;
        if ( x.status==="AVAILABLE" && y.status!=="AVAILABLE" )
            return -1;
        let diff = parseFloat(x.currentOdds) - parseFloat(y.currentOdds);
        if ( diff===0 )
            return crsOddsOrder.indexOf(x.str) - crsOddsOrder.indexOf(y.str);
        return diff;
    });
    return line.combinations.slice(0, count).map(x=>  isCRSOtherSels( x.selections?.[0]?.str ) ?  x.selections?.[0]?.str : x.str );
}

export const toCRSLabel = (str, t) => {
    if ( str==='' )
        return null;
    if ( isCRSOtherSels(str) ) {
        return lblBr(t('LB_FB_'+str));
    }
    let tmp = str.split(':');
    return `${parseInt(tmp[0])}:${parseInt(tmp[1])}`;
}

const CRSTable = ({ foPool, isMobile, getOddsVal, isCloseLine }) => {
    let _table = [];
    const { t } = useTranslation();
    const line = isCloseLine ? foPool.closedLines[0] : foPool.lines[0];
    for (let i = 0; i < oddsMatrixTable.length; i++) {
        if ( oddsMatrixTable[i][0]==="OHN" && (line===null || line.combinations.filter(x => x.selections?.[0]?.str === "OHN").length===0)) {
            continue;
        }
        if ( oddsMatrixTable[i][0]==="HOAN" && (line===null || line.combinations.filter(x => x.selections?.[0]?.str === "HOAN").length===0)) {
            continue;
        }
        if ( oddsMatrixTable[i][0]==="HOAS" && (line===null || line.combinations.filter(x => x.selections?.[0]?.str === "HOAS").length===0)) {
            continue;
        }
        if ( oddsMatrixTable[i][0]==="HO" && (line.combinations.filter(x => ['HOAN', 'HOAS'].includes(x.selections?.[0]?.str) ).length > 0)) {
            continue;
        }

        let _row = [];
        for (let k = 0; k < oddsMatrixTable[i].length; k++) {
            let _oddsVal = getOddsVal(line, oddsMatrixTable[i][k], isMobile);
            _row.push(<div className='odds' key={`${i}_${k}_${_oddsVal}`}>
                <div className="crsSel">{toCRSLabel(oddsMatrixTable[i][k], t)}</div>
                <span></span>
                {_oddsVal}
            </div>)
        }
        _table.push(<div className={`match-row matrix`} key={oddsMatrixTable[i][0]}>{_row}</div>)
    }

    return <div className='crsTable'>{_table}</div>;
}
export default CRSTable;