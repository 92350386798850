import { useEffect, useContext, useRef, useState } from 'react';
import moment from 'moment';
import { useParams } from "react-router-dom";
import { GlobalContext } from '../../../../contexts/GlobalContext';
import useGetMatchList from '../useGetMatchList';
import useGetMatches  from '../useGetMatches';
import useGetTournaments from '../useGetTournaments';
import useGetPmPools from '../useGetPmPools';
import useGetTournList from '../useGetTournList';
import { isTournamentPage, isTournResultPage, isPmPoolResultPage, isInplayPage, isMatchResultPage } from '../../Common/Common';
import { parseMessage } from '../useFootballPush/parseMessage';
import { consoleLog, formatDateObj } from '../../../Common/GlobalFunc';
import { isLogonHigh } from '../../../BetSlip/LoginHooks';

const useFBDataRefresh = ({contentRef, setContent, content, fbSearchHooks}) => {
    const globalContext = useContext(GlobalContext);
    const enableOddsPushRef = useRef(globalContext.globalState.commonCtrl.enableOddsPushButton)
    enableOddsPushRef.current = globalContext.globalState.commonCtrl.enableOddsPushButton && window.globalConfig.FB_ODDS_PUSH

    const { isPushing, tmpMsgQueue, tmpEsMsgQueue, replayMsgQueue } = globalContext.mqttClient;
    const { esContentRef, filterFBDataInner } = globalContext.esHook;
    const { page, matchId } = useParams();
    const matchIdRef = useRef()
    matchIdRef.current = matchId
    const { loadTournListQuery } = useGetTournList(contentRef, setContent);
    const { loadMatchListQuery, getDisplayMatches, setMatchListToContent, resetMatchListToContent } = useGetMatchList({contentRef, setContent, fbSearchHooks, disableRefetch: !enableOddsPushRef.current || !isPushing });
    const { loadMatchResultQuery, loadFgsResultQuery, loadMatchQuery } = useGetMatches(contentRef, setContent);
    const { loadTournQuery, loadTournResultQuery, resetTournQueryDataToContent } = useGetTournaments(contentRef, setContent);
    const { loadPmResults } = useGetPmPools(contentRef, setContent);
    const isInitRef = useRef(true)
    const autoRefreshTimeConuterRef = useRef(0)
    const lastPushTimeRef = useRef(null)

    // for page first load 
    useEffect(()=> {
        if ( isTournamentPage(contentRef.current.page) ) {
            let tpId = "TOURNAMENT" === contentRef.current.page ? matchId : null;
            contentRef.current.isLoading = true;
            loadTournQuery({ page: contentRef.current.page, tournProfileId: tpId }, null, {disabledAbort: true});
            if ( !enableOddsPushRef.current || contentRef.current.rawTournList.length==0 ) {
                loadTournListQuery();
                loadMatchListQuery();
            }

            // if ( !enableOddsPushRef.current || !isPushing ) {
            //     // clearInterval(contentRef.current.autoRefreshIntervalId);
            //     // contentRef.current.autoRefreshIntervalId=null;
            //     // resetAutoRefresh();
            // }
        }
        else if (isTournResultPage(contentRef.current.page)) {
            let tpId = "TOURNRES" === contentRef.current.page ? matchId : null;
            loadTournResultQuery({ page: contentRef.current.page, tournId: tpId, tday: 60, tIdList:[] });
        }
        else if ("RESULTS"===contentRef.current.page) {
            loadMatchResultQuery({ variables: { startDate: null, endDate: null, startIndex:null, endIndex: null, teamId: null }});
        }
        else if (["FGSRES", "AGSRES", "LGSRES"].includes(contentRef.current.page)) {
            loadFgsResultQuery();
        }
        else if (isPmPoolResultPage(contentRef.current.page)) {
            loadPmResults({
                "startDate": null,
                "endDate": null,
                "oddsTypes": [contentRef.current.page==="DHCRES" ? "DHC" : "HFM"]
            });
        }
        else if ( !enableOddsPushRef.current || contentRef.current.rawMatchList.length==0 ) {
            loadTournListQuery();
            loadMatchListQuery(null, isInitRef.current);
        }
        else {
            if(enableOddsPushRef.current){
                let matchList = getDisplayMatches(contentRef.current.rawMatchList, page.toUpperCase());
                contentRef.current.listData = { matches : matchList };
                consoleLog("matchList", matchList);
                setMatchListToContent(isInitRef.current);
            }else{
                loadMatchListQuery(null, isInitRef.current)
            }
        }
        isInitRef.current = false
    }, [content.page, content.update]);

    const resetAutoRefresh = () => {
        if ( contentRef.current.autoRefreshIntervalId==null) {
            const autoRefreshInterval = contentRef.current.config[isInplayPage(contentRef.current.page) ? isLogonHigh() ? 'autoRefreshIntervalInplay_login' : "autoRefreshIntervalInplay" : "autoRefreshIntervalNormal"] * 1000
            contentRef.current.autoRefreshIntervalId = setInterval(() => {
                if ( contentRef.current.page != 'RESULTS' ) {
                    reload();
                    consoleLog("autorefresh contentRef", contentRef);
                }
            }, autoRefreshInterval);
        }
    }

    // for auto refresh
    useEffect(() => {
        if ( !enableOddsPushRef.current || !isPushing ) {
            if ( contentRef.current.autoRefreshIntervalId == null) {
                const isFasterIntervalPage = ["HOME", "INPLAY", "INPLAY_ALL", "HAD"].includes(contentRef.current.page);
                const isResultPage = isMatchResultPage(contentRef.current.page) || isTournResultPage(contentRef.current.page) || isPmPoolResultPage(contentRef.current.page);
                const autoRefreshInterval = contentRef.current.config[!isTournamentPage(contentRef.current.page) ? isLogonHigh() ? 'autoRefreshIntervalInplay_login' : "autoRefreshIntervalInplay" : "autoRefreshIntervalNormal"] * 1000
                const maxCounter = contentRef.current.config[isInplayPage(contentRef.current.page) ? isLogonHigh() ? 'autoRefreshIntervalInplay_login': "autoRefreshIntervalInplay" : "autoRefreshIntervalNormal"]
                contentRef.current.autoRefreshIntervalId = setInterval(() => {
                    if ( !isResultPage  ) {
                        let now = moment()
                        if(lastPushTimeRef.current && now.diff(lastPushTimeRef.current, 'second') < 30 ) return 
                        autoRefreshTimeConuterRef.current += (autoRefreshInterval/1000)
                        const inplayOnly = !isTournamentPage(contentRef.current.page) && contentRef.current.page != "MIXALLUP_DETAIL" && autoRefreshTimeConuterRef.current < maxCounter
                        reload({inplayOnly : false});
                        let currentTime = moment().format('hh:mm:ss')
                        const isInplayFetch = inplayOnly || isInplayPage(contentRef.current.page)
                        consoleLog("autorefresh contentRef",currentTime, isInplayFetch? 'inplay': 'all', contentRef);
                        if(maxCounter == autoRefreshTimeConuterRef.current) autoRefreshTimeConuterRef.current = 0
                    }
                }, autoRefreshInterval);
            }
        }
        if((enableOddsPushRef.current || contentRef.current.page==="CASHOUT") && isPushing){

            if ( contentRef.current.pushRefreshIntervalId==null) {
                contentRef.current.pushRefreshIntervalId = setInterval(() => {
                    const curDt = moment();
                    lastPushTimeRef.current = curDt
                    replayMsgQueue.current = replayMsgQueue.current.filter(item => curDt.diff(item.updTime, 'second') < 120);
                    let mQueue = [...tmpMsgQueue.current];
                    tmpMsgQueue.current = [];
                    mQueue.forEach(x=> {
                        if ( x?.topic.indexOf("early_stlmt")>=0 ) {
                            // globalContext.esHook.parseEsMessage(x.topic, x.message);
                        } else
                         if ( contentRef.current.page==="CASHOUT" ) {
                            parseMessage(x.topic, x.message, esContentRef, loadMatchQuery, mQueue);
                        }
                        else {
                            parseMessage(x.topic, x.message, contentRef, loadMatchQuery, mQueue);
                        }
                    });
                    if ( contentRef.current.page==="CASHOUT" ) {
                        filterFBDataInner();
                    }
                    consoleLog("push contentRef", contentRef, "esContentRef", esContentRef, "tmpQueue Cnt", mQueue.length, "replayQueue Cnt", replayMsgQueue.current.length);
                    setContent({...contentRef.current});
                }, 3000);
            }
            if ( contentRef.current.pushESRefreshIntervalId==null) {
                contentRef.current.pushESRefreshIntervalId = setInterval(() => {
                    let EsmQueue = [...tmpEsMsgQueue.current];
                    tmpEsMsgQueue.current = [];
                    EsmQueue.forEach(x=> {
                        if ( x?.topic.indexOf("early_stlmt")>=0 ) {
                            globalContext.esHook.parseEsMessage(x.topic, x.message);
                        }
                    });
                    setContent({...contentRef.current});
                }, 2500);
            }
        }
        return () => {
            clearInterval(contentRef.current.autoRefreshIntervalId);
            contentRef.current.autoRefreshIntervalId = null;
            clearInterval(contentRef.current.pushRefreshIntervalId);
            contentRef.current.pushRefreshIntervalId = null;
            clearInterval(contentRef.current.pushESRefreshIntervalId);
            contentRef.current.pushESRefreshIntervalId = null;
        }
    }, [isPushing, content.page, isLogonHigh()]);

    // for manual refresh
    const reload = (options) => {
        const inplayOnly = options?.inplayOnly || false
        if(contentRef.current.page == 'ALLODDS' && contentRef.current?.matchData?.matches?.[0]?.status === "PREEVENT" && inplayOnly){
            return 
        }
        if (contentRef.current.page=="CASHOUT") {
            // donothing
        }else if ( isTournamentPage(contentRef.current.page) ) {
            let tpId = "TOURNAMENT" === contentRef.current.page ? matchIdRef.current : null;
            loadTournListQuery()
            loadTournQuery({ page: contentRef.current.page, tournProfileId: tpId }, resetTournQueryDataToContent);
        }
        else if ("RESULTS"===contentRef.current.page) {
            const historyObj = window.history
            if(historyObj?.state?.para && ['#search', '#detail'].includes(window.location.hash)) {
                const pageState = historyObj?.state?.para
                loadMatchResultQuery({ startDate: formatDateObj(pageState.startDate)[0], endDate: formatDateObj(pageState.endDate)[0], startIndex: (pageState.pageNum-1) * 20 + 1, endIndex: pageState.pageNum*20, teamId:  pageState.teamId == '0' ? null : pageState.teamId })
            }else{
                loadMatchResultQuery({ variables: { startDate: null, endDate: null, startIndex:null, endIndex: null, teamId: null }});
            }
        }
        else {
            consoleLog('resetMatchListToContent')
            if(inplayOnly){
                if(window.fbPageConfig?.[contentRef.current?.page]?.showInplayMatch){
                    resetMatchListToContent({inplayOnly})
                }
            }else{
                loadMatchListQuery(()=> { resetMatchListToContent({inplayOnly})});
            }
        }
    }

    return {
        reload,
        resetAutoRefresh
    };
}

export default useFBDataRefresh