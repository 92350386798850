import React, { useEffect, useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import OddsTable, {clipboardMeetingRow} from '../Common/RCOddsTableCompact';
import OddsDropLegend from '../Common/OddsDropLegend';
import Investment from '../Common/Investment';
import InvestmentCalculator from '../Common/InvestmentCalculator';
import { getComingleMsgs, getIsShowPmuText } from '../Common/Common';
import {IOddsTable} from '../Common/IOddsTable';
import ComingleMsg from '../Common/ComingleMsg'
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import { RacingContext } from '../../Home/RacingPage';
import AddSlipBtn from '../Common/AddSlipBtn';
import { useWindowSize } from '../../Common/CommonHooks';
import RefreshTime from '../../Common/ui-components/RefreshTime';
import Loading from '../../Common/ui-components/Loading';

const OddsFF = (props) => {
    const { t, i18n } = useTranslation();
    const context = useContext(RacingContext);

    const RCColumns = {
        no: true,
        banker1: true,
        banker2: false,
        banker3: false,
        leg: true,
        horseName: true,
        rcNoteM: context.content.isPeNoteEnable,
        win: true,
        place: false,
        field: true,
        banker1F: false,
        banker2F: false,
        banker3F: false
    }

    const RCColumnLbls = {
        banker1: t('LB_RC_ODDS_BANKER'),
        leg: t('LB_RC_ODDS_LEG')
    }

    if (context == null) {
        return <Loading/>
    } else {
        const mtg = context.content.meeting;
        let tmpMsgs = getComingleMsgs(mtg, context.content.page, context.content.raceNo);
        let pageConfig = context.pageConfig;
        const isShowPmu = getIsShowPmuText(mtg, context.content.raceNo)
        let comingleMsg1 = [];
        let comingleMsg2 = [];
        if (tmpMsgs != null) {
            let comingleLang = i18n.language == "en" ? "E" : "C";
            if ( tmpMsgs['NOTE1_' + comingleLang] != '' )
                comingleMsg1.push(<div className="rc-comingle-remarks"><span style={{ fontWeight: "bold" }}>{t('LB_RC_COMINGLE_NOTE')}1: </span>{tmpMsgs['NOTE1_' + comingleLang]}</div>);
            if ( tmpMsgs['NOTE2_' + comingleLang] != '' )
                comingleMsg2.push(<div className="rc-comingle-remarks"><span style={{ fontWeight: "bold" }}>{t('LB_RC_COMINGLE_NOTE')}2: </span>{tmpMsgs['NOTE2_' + comingleLang]}</div>);
            if ( tmpMsgs['NOTE3_' + comingleLang] != '' )
                comingleMsg2.push(<div className="rc-comingle-remarks"><span style={{ fontWeight: "bold" }}>{t('LB_RC_COMINGLE_NOTE')}3: </span>{tmpMsgs['NOTE3_' + comingleLang]}</div>);
        }

        const { isMobileSize } = useWindowSize();
        const columnsCountBreakPoints = isMobileSize ? { 320: 1 } : { 350: 2 }

        context.racingFuncs.copyClipboardRightOddsTable = () => {
            return clipboardMeetingRow({ context, t, i18n, raceNo: context.content.raceNo, columns: RCColumns });
        }

        return (
            <section className={context.content.page}>
                <Investment betType={context.content.page} />
                <InvestmentCalculator  className='border-radius-down' enable={true} />
                <RefreshTime product="racing" />
                <ResponsiveMasonry columnsCountBreakPoints={columnsCountBreakPoints} style={{ margin: "12px 0px" }}>
                    <Masonry className="masonry-col">
                        <div>
                            <IOddsTable betType={context.content.page} msg={''} isDisableEnquiry={true} />
                        </div>
                        <div>
                            <OddsTable betType={context.content.page} columns={RCColumns} lbl={RCColumnLbls} showAddSlip={false}
                                firstRace={context.content.raceNo} raceNo={context.content.raceNo}
                                selectedBetType={context.content.page} startCnt={0} />
                            {/* {comingleMsg2} */}
                            {isShowPmu && <div className="rc-comingle-remarks"><ComingleMsg></ComingleMsg></div> }
                        </div>
                        <div className="OddsDropLegend-Content-ge1280">
                            <OddsDropLegend />
                        </div>
                    </Masonry>
                </ResponsiveMasonry>
                {/* {isShowPmu && <div className="rc-comingle-remarks"><ComingleMsg></ComingleMsg></div>} */}
                <div className="OddsDropLegend-Content-lt1280">
                    <OddsDropLegend />
                </div>
                <div className="AddSlipBtn-Content">
                    <AddSlipBtn />
                </div>
                <InvestmentCalculator  className='border-radius-down' enable={!context.windowSize.isMobileSize} />
            </section>
        );
    }
}
export default OddsFF;