import React, { useState, useEffect, useRef, useContext, useCallback, useLayoutEffect } from 'react';
import { Button, Dropdown, DropdownButton, ButtonGroup } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import Popup from 'reactjs-popup';
import { ConfigContext } from '../Common/ConfigHelper';
import { GlobalContext } from '../../contexts/GlobalContext';
import TrustIcon from '../../info/Include/images/TrustIcon.svg';
import NotTrustIcon from '../../info/Include/images/NotTrustIcon.svg';
import { useWindowSize } from '../Common/CommonHooks';
import { WATrackerTrackClickEvent } from '../Common/utils/wa';
import { SetLoginBusyStatus, callLogoutApi } from './LoginHooks';
import { TCModal } from './TandCModal';
export const TrustBrowser = ({
    showMsg = false,
    FR_TurstBrowser = FR_TurstBrowser,
    isShowTrustBrowser = isShowTrustBrowser,
    setIsShowTrustBrowser = setIsShowTrustBrowser
}) => {
    const { t, i18n } = useTranslation();
    const lang = i18n.language;
    const { betslipMenu } = useContext(ConfigContext);
    const globalContext = useContext(GlobalContext);
    const [menuInfo, setMenuInfo] = useState(betslipMenu);

    const { isMobileSize } = useWindowSize();
    const [selectTrust,setSelectTrust] = useState(null);
    const [showTandC,setshowTandC]= useState(false);
    let mobileStyle = isMobileSize ? 'is-mobile' : 'is-desktop';
    const [isClicked,setIsClicked] = useState(false);
    useEffect(() => {
        if (isMobileSize) {
            mobileStyle = 'is-mobile'
        } else {
            mobileStyle = 'is-desktop';
        }
    }, [isMobileSize]);
    
    
    const handleTrustBtn = async () => {
        if(!isClicked){
            if(window.sessionStorage.getItem("LoginWA").includes('WithMobile')) {
                WATrackerTrackClickEvent('eWin_CIAM_S2-Trust-Yes_whitelistWithMobile');
            }
            window.sessionStorage.setItem("LoginWA", sessionStorage.getItem("LoginWA") + "_Trust-Yes");
            // trust the browser
            // close overlay modal
            await FR_TurstBrowser(true);
            setshowTandC(true);
            setIsShowTrustBrowser(false);
            setIsClicked(false);
        }        
    };

    const handleDontTrustBtn = async () => {
   
        if(!isClicked){
            if(window.sessionStorage.getItem("LoginWA").includes('WithMobile')) {
                WATrackerTrackClickEvent('eWin_CIAM_S2-Trust-No_whitelistWithMobile');
            }
            window.sessionStorage.setItem("LoginWA", sessionStorage.getItem("LoginWA") + "_Trust-No");
            await FR_TurstBrowser(false);
            setshowTandC(true);
            setIsShowTrustBrowser(false);
            setIsClicked(false);
        }
        // close overlay modal
    };
    const handleSubmit=()=> {
        if(isClicked)return;
        setIsClicked(true)
        if(selectTrust)
            handleTrustBtn()
        else if (selectTrust==false)
            handleDontTrustBtn()
    }
    
    
    return (
        <Popup open={showMsg} closeOnEscape={false} closeOnDocumentClick={false} className={`comingSoonLogOutDialog logout-succ-msg trustbrowser ${mobileStyle}`}>
            <div className={`trustbrowser-container`} id='popup-trustbrowser-container'>
                <div className='trustbrowser-title-and-description'>
                    <div className='trustbrowser-header'>
                        <div className="trustbrowser-dialog-title">{t('LB_TRUSTED_BROWSER_TITLE')}</div>
                        <div className="trustbrowser-message" dangerouslySetInnerHTML={{ __html: t('LB_TRUSTED_BROWSER_MESSAGE') }}></div>
                    </div>
                    <div className='trustbrowser-content-container'>
                        <div className={`trustbrowser-container2 ${selectTrust&&'trustbrowser-active'}`} onClick={()=>{setSelectTrust(true)}}>
                            <div >
                                <img src={TrustIcon} alt="trust browser img" />
                            </div>
                            <div>
                                <div className="trustbrowser-title">{t('LB_TRUSTED_BROWSER_TRUST')}</div>
                                <div className="trustbrowser-message" dangerouslySetInnerHTML={{ __html: t('LB_TRUSTED_BROWSER_TRUST_MSG') }}></div>
                            </div>
                        </div>
                        <div className={`trustbrowser-container2 ${selectTrust===false&&'trustbrowser-active'}`} onClick={()=>{setSelectTrust(false)}} >
                            <div>
                                <img src={NotTrustIcon} alt="trust browser img" />
                            </div>
                            <div>
                                <div className="trustbrowser-title">{t('LB_TRUSTED_BROWSER_DONT_TRUST')}</div>
                                <div className="trustbrowser-message" dangerouslySetInnerHTML={{ __html: t('LB_TRUSTED_BROWSER_DONT_TRUST_MSG') }}></div>
                            </div>
                        </div>
                    </div>
                </div>
                
                <div className="trustbrowser-btn-group">
                    {/* <div className="trustbrowser-btn-donttrust" onClick={handleDontTrustBtn}>{t('LB_TRUSTED_BROWSER_DONT_TRUST')}</div> */}
                    <div className={`trustbrowser-btn-next ${selectTrust!==null&&"trustbrowser-btn-trust"}`} onClick={()=>{handleSubmit()}}>{t('LB_NEXT')}</div>
                </div>
               
            </div>
        </Popup>
    );
};