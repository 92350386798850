import { gql } from '@apollo/client';

export function getAllMatchListQuery() {
  return `
    query allMatchList {
      timeOffset {
        fb
      }
      matches: matchList {
        id
        frontEndId
        matchDate
        kickOffTime
        status
        updateAt
        sequence
        esIndicatorEnabled
        homeTeam {
          id
          name_en
          name_ch
        }
        awayTeam {
          id
          name_en
          name_ch
        }
        tournament {
          id
          frontEndId
          nameProfileId
          sequence
          isInteractiveServiceAvailable
          code
          name_en
          name_ch
        }
        isInteractiveServiceAvailable
        inplayDelay
        venue {
          code
          name_en
          name_ch
        }
        tvChannels {
          code
          name_en
          name_ch
        }
        liveEvents {
          id
          code
        }
        featureStartTime
        featureMatchSequence
        poolInfo {
          normalPools
          inplayPools
          sellingPools
          ntsInfo
          entInfo
          definedPools
        }
        runningResult {
          homeScore
          awayScore
          corner
          homeCorner
          awayCorner
        }
        runningResultExtra {
          homeScore
          awayScore
          corner
          homeCorner
          awayCorner
        }
        adminOperation {
          remark {
            typ
          }
        }
      }
    }
  `;
}

export function getAllTournListQuery() {
  return `
    query tournamentList {
      tournamentList {
        id
        code
        frontEndId
        nameProfileId
        isInteractiveServiceAvailable
        name_ch
        name_en
        sequence
      }
    }
  `;
}

export function getESMatchQuery() {
    return  `
      query matchList($startIndex: Int, $endIndex: Int,$startDate: String, $endDate: String, $matchIds: [String], $tournIds: [String], $fbOddsTypes: [FBOddsType]!, $fbOddsTypesM: [FBOddsType]!, $inplayOnly: Boolean, $featuredMatchesOnly: Boolean, $frontEndIds: [String], $earlySettlementOnly: Boolean, $showAllMatch: Boolean) {
        matches(startIndex: $startIndex,endIndex: $endIndex, startDate: $startDate, endDate: $endDate, matchIds: $matchIds, tournIds: $tournIds, fbOddsTypes: $fbOddsTypesM, inplayOnly: $inplayOnly, featuredMatchesOnly: $featuredMatchesOnly, frontEndIds: $frontEndIds, earlySettlementOnly: $earlySettlementOnly, showAllMatch: $showAllMatch) {
          id
          frontEndId
          matchDate
          kickOffTime
          status
          updateAt
          sequence
          esIndicatorEnabled
          homeTeam {
            id
            name_en
            name_ch
          }
          awayTeam {
            id
            name_en
            name_ch
          }
          tournament {
            id
            frontEndId
            nameProfileId
            isInteractiveServiceAvailable
            code
            name_en
            name_ch
          }
          isInteractiveServiceAvailable
          inplayDelay
          venue {
            code
            name_en
            name_ch
          }
          tvChannels {
            code
            name_en
            name_ch
          }
          liveEvents {
            id
            code
          }
          featureStartTime
          featureMatchSequence
          poolInfo {
            normalPools
            inplayPools
            sellingPools
            ntsInfo
            entInfo
            definedPools
          }
          runningResult {
            homeScore
            awayScore
            corner
            homeCorner
            awayCorner
          }
          runningResultExtra {
            homeScore
            awayScore
            corner
            homeCorner
            awayCorner
          }
          results {
            sequence
            resultType
            stageId
            homeResult
            awayResult
            resultConfirmType
            payoutConfirmed
          }
          adminOperation {
            remark {
              typ
            }
          }
          foPools(fbOddsTypes: $fbOddsTypes) {
            id
            status
            oddsType
            instNo
            inplay
            name_ch
            name_en
            updateAt
            expectedSuspendDateTime
            lines {
              lineId
              status
              condition
              main
              combinations {
                combId
                str
                status
                offerEarlySettlement
                currentOdds
                selections {
                  selId
                  str
                  name_ch
                  name_en
                }
              }
            }
          }
        }
      }
      `;
}


export function getAllUpMatchQuery() {
    return  `
      query matchList($startIndex: Int, $endIndex: Int,$startDate: String, $endDate: String, $matchIds: [String], $tournIds: [String], $fbOddsTypes: [FBOddsType]!, $fbOddsTypesM: [FBOddsType]!, $inplayOnly: Boolean, $featuredMatchesOnly: Boolean, $frontEndIds: [String], $earlySettlementOnly: Boolean, $showAllMatch: Boolean) {
        matches(startIndex: $startIndex,endIndex: $endIndex, startDate: $startDate, endDate: $endDate, matchIds: $matchIds, tournIds: $tournIds, fbOddsTypes: $fbOddsTypesM, inplayOnly: $inplayOnly, featuredMatchesOnly: $featuredMatchesOnly, frontEndIds: $frontEndIds, earlySettlementOnly: $earlySettlementOnly, showAllMatch: $showAllMatch) {
          id
          frontEndId
          status
          isInteractiveServiceAvailable
          foPools(fbOddsTypes: $fbOddsTypes) {
            id
            status
            oddsType
            lines {
              lineId
              status
              combinations {
                combId
                status
              }
            }
          }
        }
      }
      `;
}

export function getMatchQuery() {
    return  `
      query matchList($startIndex: Int, $endIndex: Int,$startDate: String, $endDate: String, $matchIds: [String], $tournIds: [String], $fbOddsTypes: [FBOddsType]!, $fbOddsTypesM: [FBOddsType]!, $inplayOnly: Boolean, $featuredMatchesOnly: Boolean, $frontEndIds: [String], $earlySettlementOnly: Boolean, $showAllMatch: Boolean) {
        matches(startIndex: $startIndex,endIndex: $endIndex, startDate: $startDate, endDate: $endDate, matchIds: $matchIds, tournIds: $tournIds, fbOddsTypes: $fbOddsTypesM, inplayOnly: $inplayOnly, featuredMatchesOnly: $featuredMatchesOnly, frontEndIds: $frontEndIds, earlySettlementOnly: $earlySettlementOnly, showAllMatch: $showAllMatch) {
          id
          frontEndId
          matchDate
          kickOffTime
          status
          updateAt
          sequence
          esIndicatorEnabled
          homeTeam {
            id
            name_en
            name_ch
          }
          awayTeam {
            id
            name_en
            name_ch
          }
          tournament {
            id
            frontEndId
            nameProfileId
            isInteractiveServiceAvailable
            code
            name_en
            name_ch
          }
          isInteractiveServiceAvailable
          inplayDelay
          venue {
            code
            name_en
            name_ch
          }
          tvChannels {
            code
            name_en
            name_ch
          }
          liveEvents {
            id
            code
          }
          featureStartTime
          featureMatchSequence
          poolInfo {
            normalPools
            inplayPools
            sellingPools
            ntsInfo
            entInfo
            definedPools
          }
          runningResult {
            homeScore
            awayScore
            corner
            homeCorner
            awayCorner
          }
          runningResultExtra {
            homeScore
            awayScore
            corner
            homeCorner
            awayCorner
          }
          adminOperation {
            remark {
              typ
            }
          }
          foPools(fbOddsTypes: $fbOddsTypes) {
            id
            status
            oddsType
            instNo
            inplay
            name_ch
            name_en
            updateAt
            expectedSuspendDateTime
            lines {
              lineId
              status
              condition
              main
              combinations {
                combId
                str
                status
                offerEarlySettlement
                currentOdds
                selections {
                  selId
                  str
                  name_ch
                  name_en
                }
              }
            }
          }
        }
      }
      `;
}

export function getTournamentQuery() {
  return `
      query tournamentList($fbOddsTypes: [FBOddsType]!, $tournId: String, $tournProfileId: String, $subType: Int, $tournIds: [String]) {
        tournaments(fbOddsTypes: $fbOddsTypes, tournId: $tournId, tournProfileId: $tournProfileId, subType: $subType, tournIds: $tournIds) {
          id
          nameProfileId
          frontEndId
          code
          sequence
          name_en
          name_ch
          isInteractiveServiceAvailable
          poolInfo {
            normalPools
            inplayPools
            sellingPools
          }
          foPools(fbOddsTypes: $fbOddsTypes) {
            id
            instNo
            oddsType
            status
            name_en
            name_ch
            inplay
            expectedSuspendDateTime
            chpType
            lines {
              lineId
              status
              combinations {
                combId
                str
                status
                currentOdds
                selections {
                  selId
                  name_en
                  name_ch
                  str
                  sequence
                }
              }
            }
            match {
              id
              status
              homeTeam {
                name_en
              }
            }
          }
        }
      }
    `;
}

export function getFootballMenuQuery() {
  return `
      query footballData($tday: Int!, $tIdList:[String]!) {
        timeOffset {
          fb
        }
        fullTourns: tournaments(fbOddsTypes:  [ALL]) {
          frontEndId
          nameProfileId
        }
        chpTypes: tournaments(fbOddsTypes:  [CHP]) {
          foPools(fbOddsTypes: [CHP]) {
            chpType
          }
        }
        tournaments(fbOddsTypes:  [TQL, GPF, GPW, TPS, TSP]) {
          nameProfileId
          name_en
          name_ch
          sequence
        }
        tournamentResult(oddsTypes: [TQL, GPF, GPW, TPS, TSP], tournDayFilter: { days: $tday, exceptionalTournIDs: $tIdList }) {
          id
          name_en
          name_ch
          sequence
        }
        featuredMatches : matches(fbOddsTypes: [ALL, HAD, EHA], featuredMatchesOnly: true) {
          id
        }
      }
    `;
}

export function getMatchResultQuery() {
  return `
    query matchResults($startDate: String, $endDate: String, $startIndex: Int,$endIndex: Int,$teamId: String) {
      timeOffset {
        fb
      }
      matchNumByDate(startDate: $startDate, endDate: $endDate, teamId: $teamId) {
        total
      }
      matches: matchResult(startDate: $startDate, endDate: $endDate, startIndex: $startIndex,endIndex: $endIndex, teamId: $teamId) {
        id
        status
        frontEndId
        matchDayOfWeek
        matchNumber
        matchDate
        kickOffTime
        sequence
        homeTeam {
          id
          name_en
          name_ch
        }
        awayTeam {
          id
          name_en
          name_ch
        }
        tournament {
          code
          name_en
          name_ch      
        }
        results {
          homeResult
          awayResult
          ttlCornerResult
          resultConfirmType
          payoutConfirmed
          stageId
          resultType
          sequence
        }
        poolInfo {
          payoutRefundPools
          refundPools
          ntsInfo
          entInfo
          definedPools
        }
      }
    }
  `;
}

export function getMatchResultDetailsQuery() {
  return gql`
    query matchResultDetails($matchId: String, , $fbOddsTypes: [FBOddsType]! ) {
      matches: matchResult(matchId: $matchId) {
        id
        foPools(fbOddsTypes: $fbOddsTypes, resultOnly: true) {
          id
          status
          oddsType
          instNo
          name_ch
          name_en
          lines(resultOnly: true) {
            combinations {
              str
              status
              winOrd
              selections {
                selId
                str
                name_ch
                name_en
              }
            }
          }
        }
        additionalResults {
          resSetId
          results {
            awayResult
            homeResult
            ttlCornerResult
            mask
            payoutConfirmed
            resultConfirmType
            resultType
            sequence
            stageId
          }
        }
      }
    }
  `;
}

export function getFgsResultQuery() {
  return `
    query matchResults($fbOddsTypes: [FBOddsType]!) {
      timeOffset {
        fb
      }
      matches: matchResult(fbOddsTypes: $fbOddsTypes) {
        id
        status
        frontEndId
        matchDayOfWeek
        matchNumber
        matchDate
        kickOffTime
        sequence
        homeTeam {
          id
          name_en
          name_ch
        }
        awayTeam {
          id
          name_en
          name_ch
        }
        tournament {
          code
          name_en
          name_ch      
        }
        foPools(fbOddsTypes: $fbOddsTypes, lastOdds: true) {
          id
          status
          oddsType
          instNo
          name_ch
          name_en
          lines(resultOnly: true) {
            combinations {
              str
              status
              winOrd
              selections {
                selId
                str
                name_ch
                name_en
              }
            }
          }
        }
      }
    }
  `;
}

export function getLastOddsQuery() {
  return gql`
    query lastOdds($matchId: String, $fbOddsTypes: [FBOddsType]! ) {
      matches: matchResult(matchId: $matchId) {
        id
        updateAt
        statusUpdatedAt
        foPools(fbOddsTypes: $fbOddsTypes, lastOdds: true) {
          id
          status
          oddsType
          instNo
          inplay
          name_ch
          name_en
          updateAt
          lines(resultOnly: true) {
            lineId
            status
            condition
            main
            combinations {
              combId
              str
              status
              currentOdds
              selections {
                selId
                str
                name_ch
                name_en
              }
            }
          }
          closedLines {
            lineId
            status
            condition
            combinations {
              combId
              str
              status
              currentOdds
              selections {
                selId
                str
                name_ch
                name_en
              }
            }
          }
        }
      }
    }
    `;
}

export function getTournamentResultQuery() {
  return `
    query tournamentResults($fbOddsTypes: [FBOddsType]!, $tournId: String, $tday: Int!, $tIdList:[String]!) {
      tournaments : tournamentResult(oddsTypes: $fbOddsTypes, tournId: $tournId, tournDayFilter: { days: $tday, exceptionalTournIDs: $tIdList }) {
        id
        frontEndId
        name_en
        name_ch
        code
        sequence
        foPools(fbOddsTypes: $fbOddsTypes, resultOnly: true) {
          oddsType
          status
          instNo
          updateAt
          name_en
          name_ch
          lines(resultOnly: true) {
            combinations {
              combId
              status
              winOrd
              selections {
                str
                name_ch
                name_en
              }
            }
          }
        }
      }
    }
  `;
}

export function getMatchRemarksQuery() {
  return gql`
    query matchRemarks {
      timeOffset {
        fb
        ts
      }
      matches(fbOddsTypes: [], remarksOnly: true) {
        id
        frontEndId
        kickOffTime
        updateAt
        adminOperation {
          remark {
            seq
            typ
            updAt
            cont {
              lang
              content
            }
          }
        }
      }
    }
  `;
}

export function getPMPoolResultsQuery() {
  return `
    query pmPoolResults($startDate: String, $endDate: String, $oddsTypes: [FBOddsType]!) {
      matchResult(startDate: $startDate, endDate: $endDate, fbOddsTypes: $oddsTypes) {
        id
        matchDayOfWeek
        matchNumber
        kickOffTime
        fbPmPools(oddsTypes: $oddsTypes) {
          id
          oddsType
          status
          winningCombination
          dividends
          winningInvestment
          consolationDividends
          consolationInvestment
          jackpotGen
          investment
          jackpotAlc
          lastUpdateTime
          leg {
            no
            match {
              id
              status
              matchDayOfWeek
              matchNumber
              kickOffTime
              homeTeam {
                name_en
                name_ch
              }
              awayTeam {
                name_en
                name_ch
              }
              tournament {
                code
                name_ch
                name_en
              }
              results {
                stageId
                homeResult
                awayResult
                resultType
                resultConfirmType
              }
            }
          }
        }
      }
    }
  `;
}

export function getTeamListQuery() {
  return gql`
    query teamList {
      teamList {
        id
        code
        name_ch
        name_en
      }
    }
  `;
}

export function getBSFootballMatchQuery() {
  return `
    query bsMatchList($fbOddsTypes: [FBOddsType]!, $matchIds: [String],) {
      matches(fbOddsTypes: $fbOddsTypes, matchIds: $matchIds,) {
        id
        inplayDelay
        isInteractiveServiceAvailable
        status
      }
    }
  `;
}

export function getQueryOddsTypes(page) {
    let queryArr = [];
    switch (page) { 
        case "FHA":
            queryArr = ["FHA"];
            break;
        case "HHA":
            queryArr = ["HHA", 'EHH'];
            break;
        case "FHH":
            queryArr = ["FHH"];
            break;
        case "HDC":
            queryArr = ["HDC", "EDC"];
            break;
        case "HIL":
            queryArr = ["HIL", "EHL"];
            break;
        case "FHL":
            queryArr = ["FHL"];
            break;
        case "CHL":
            queryArr = ["CHL", "ECH"];
            break;
        case "FHC":
            queryArr = ["FHC"];
            break;
        case "CHD":
            queryArr = ["CHD", "ECD"];
            break;
        case "FCH":
            queryArr = ["FCH"];
            break;
        case "CRS":
            queryArr = ["CRS", "ECS"];
            break;
        case "FCS":
            queryArr = ["FCS"];
            break;
        case "AGS":
        case "AGSRES":
            queryArr = ["AGS"];
            break;
        case "LGS":
        case "LGSRES":
            queryArr = ["LGS"];
            break;
        case "FGS":
        case "FGSRES":
            queryArr = ["FGS"];
            break;
        case "MSP":
            queryArr = ["MSP"];
            break;
        case "SGA":
            queryArr = ["SGA"];
            break;
        case "FTS":
            queryArr = ["FTS"];
            break;
        case "OOE":
            queryArr = ["OOE"];
            break;
        case "TTG":
            queryArr = ["TTG", "ETG"];
            break;
        case "HFT":
            queryArr = ["HFT"];
            break;
        case "OFM":
        case "INPLAY":
            queryArr = ["HAD", "EHA"];
            break;
        case "CHP":
        case "FINALIST":
        case "WINCNTY":
        case "WINCNTT":
        case "CHPRES":
          queryArr = ["CHP"];
          break;
        case "TQL":
          queryArr = ["TQL"];
          break;
        case "TOURNAMENT":
        case "TOURNRES":
          queryArr = ["CHP", "TQL", "GPW", "GPF", "TPS", "TSP"];
          break;
        case "ALLODDS":
        case "CASHOUT":
            queryArr = ["HAD", "EHA", "SGA", "CHP", "TQL", "FHA", "HHA", "HDC", "EDC",
                        "HIL", "EHL", "FHL", "CHL", "ECH", "FCH", "CRS", "ECS", "FCS",
                        "FTS", "TTG", "ETG", "OOE", "FGS", "HFT", "MSP", "NTS", "ENT",
                        "FHH", "FHC", "CHD", "ECD", "EHH","AGS",'LGS'
                      ];
            break;
        case "INPLAY_ALL":
            queryArr = ["HAD", "EHA", "CHP", "TQL", "FHA", "HHA", "HDC", "EDC",
                        "HIL", "EHL", "FHL", "CHL", "ECH", "FCH", "CRS", "ECS", "FCS",
                        "FTS", "TTG", "ETG", "NTS", "ENT", "FHH", "FHC", "CHD", "ECD", "EHH"];
            break;
        case "MIXALLUP":
        case "MIXALLUP_DETAIL":
            queryArr = ["HAD", "EHA", "FHA", "HHA", "HDC", "EDC",
                        "HIL", "EHL", "FHL", "CHL", "ECH", "FCH", "CRS", "ECS", "FCS",
                        "FTS", "TTG", "ETG", "OOE", "FGS", "HFT",
                        "FHH", "FHC", "CHD", "ECD", "EHH","AGS",'LGS'
                      ];
            break;
        default:
            queryArr = ["HAD", "EHA"];
            break;
    }
    return queryArr;
}
function getQueryOddsTypesM(page) {
  let queryArr = [];
  switch (page) { 
      case "OFM":
      case "INPLAY":
          queryArr = ["ALL"];
          break;
      case "CHP":
      case "FINALIST":
      case "WINCNTY":
      case "WINCNTT":
      case "CHPRES":
        queryArr = ["CHP"];
        break;
      case "TQL":
        queryArr = ["TQL"];
        break;
      case "TOURNAMENT":
      case "TOURNRES":
        queryArr = ["CHP", "TQL", "GPW", "GPF", "TPS", "TSP"];
        break;
      case "ALLODDS":
      case "CASHOUT":
          queryArr = ["HAD", "EHA", "SGA", "CHP", "TQL", "FHA", "HHA", "HDC", "EDC",
                      "HIL", "EHL", "FHL", "CHL", "ECH", "FCH", "CRS", "ECS", "FCS",
                      "FTS", "TTG", "ETG", "OOE", "FGS", "HFT", "MSP", "NTS", "ENT"];
          break;
      case "INPLAY_ALL":
          queryArr = ["HAD", "EHA", "CHP", "TQL", "FHA", "HHA", "HDC", "EDC",
                      "HIL", "EHL", "FHL", "CHL", "ECH", "FCH", "CRS", "ECS", "FCS",
                      "FTS", "TTG", "ETG", "NTS", "ENT"];
          break;
      case "MIXALLUP":
      case "MIXALLUP_DETAIL":
          queryArr = ["HAD", "EHA", "FHA", "HHA", "HDC", "EDC",
                      "HIL", "EHL", "FHL", "CHL", "ECH", "FCH", "CRS", "ECS", "FCS",
                      "FTS", "TTG", "ETG", "OOE", "FGS", "HFT"];
          break;
      default:
          queryArr = ["ALL"];
          break;
  }
  return queryArr;
}

function getQuerySubType(page) {
    switch (page) { 
      case "CHP":
        return 0;
      case "WINCNTY":
        return 1;
      case "WINCNTT":
        return 2;
      case "FINALIST":
        return 3;
    }
    return null;
}

export function getQueryVariables( {page, dateFrom, dateTo, tournIds, matchIds, tournId, tournProfileId, startIndex, endIndex, frontEndIds, earlySettlementOnly, showAllMatch, tday, tIdList, inplayOnly } ) {
  let paras = { "fbOddsTypes": getQueryOddsTypes(page),
          "fbOddsTypesM":  ["OFM", "INPLAY"].includes(page) ? ["ALL"] : getQueryOddsTypes(page),
          "inplayOnly" : page.indexOf("INPLAY")>=0 || inplayOnly,
          "featuredMatchesOnly" : page==="OFM",
          "startDate" : dateFrom ? dateFrom.substring(0,10).replace(/-/g, "") : null,
          "endDate" : dateTo ? dateTo.substring(0,10).replace(/-/g, "") : null,
          "tournIds": tournIds ? tournIds : null,
          "matchIds" : matchIds ? matchIds : null,
          "tournId": tournId ? tournId : null,
          "tournProfileId": tournProfileId ? tournProfileId : null,
          "subType": getQuerySubType(page),
          "startIndex": startIndex || null,
          "endIndex": endIndex || null,
          "frontEndIds": frontEndIds || null,
          "earlySettlementOnly": earlySettlementOnly===true,
          "showAllMatch": showAllMatch===true,
          "tday": tday || null,
          "tIdList": tIdList || null
       };
  return paras;
}