import ArrowIcon from './../ArrowIcon';
import { useTranslation } from 'react-i18next';
import './index.scss'
const MoreButton = ({expand,MoreButtonClick,showLessText="Hide",showMoreText="More", showLine=false, id, isShowRed=false})=>{
    const { t } = useTranslation();
    return (
        <div
            className={`MoreButton sgaMoreButton ${showLine? 'showLine': ''}`}
            onClick={MoreButtonClick}
            id={id}
        >
            <div className={`MoreButtonText ${(isShowRed && !expand) ? 'showRed' : ''}`}>{expand ? t(showLessText) : t(showMoreText)}</div>
            <ArrowIcon color="green" className="" direction={expand ? 'up' : 'down'} size={24} />
        </div>)
}

export default  MoreButton