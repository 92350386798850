import { GetRaceConfig, GetFootballConfig } from '../Common/ConfigHelper';
import { alupFormulaItem, consoleLog } from '../Common/GlobalFunc';
import getUuid from 'uuid-by-string';

export const IsPassBetlinesValidFn = (betArr, opt) => {

    let betStrLength = '';
    let betStr = '';
    let interval = ' ';
    let sendbetArr = [];
    let FBdetail = '';
    for (let i = 0; i < betArr.length; i++) {
        const element = betArr[i];
        const betObj = element.betObj;
        if ( betObj.isStopSell && opt?.pushFilter ){
            continue;
        }
        if (element.prod === 'M6') {
            betStr += 'MK6' + interval;
            if (betObj.multidraw > 1) {
                betStr += betObj.multidraw + 'D' + interval;
            }
            betStr += getM6BetLineStr(betObj, interval);
            const initPartialUnitBet = sessionStorage.getItem('Mk6PartialUnitBetAmount')
                ? Number(sessionStorage.getItem('Mk6PartialUnitBetAmount'))
                : 5;
            if (element.unitBet == initPartialUnitBet) {
                betStr += 'P';
            }
            if (betObj.random) {
                betStr += '(RG)';
            }
            if (betObj.pageName.indexOf('SNOWBALL') > -1) {
                betStr += 'S';
            }
        }
        if (element.prod === 'FB') {
            betStr += 'FBF' + interval;
            FBdetail += 'FBF' + interval;
            if (element.counterOfferData) {
                FBdetail += `(${element?.counterOfferData?.counterOfferKey}) `;
                betStr += `(${element?.counterOfferData?.counterOfferKey}) `;
            }
            if (betObj.betType === 'ALUP') {
                betStr += getFBAllupBetLineStr(betObj, interval);
                FBdetail += getFBAllupBetLineStr(betObj, interval, 'atDetail');

                betObj.betList.map((item) => {
                    if (item.matchInfo?.inplayDelay == true) {
                        betObj.inplayDelay = true;
                        return;
                    }
                });
            } else {
                betStr += getFBBetLineStr(betObj, interval);
                FBdetail += getFBBetLineStr(betObj, interval, 'atDetail');
            }
        }
        if (element.prod === 'HR') {
            const racingConfig = GetRaceConfig();
            const isMultiRace = [...racingConfig?.MultiRacePage, ...racingConfig?.ALUP_PAGE, 'CWAALLALUP'].includes(
                betObj.betType
            );
            const isCWIN = ['CWA', 'CWB', 'CWC'].includes(betObj.betType);
            const isFOBet = ['JKC', 'TNC'].includes(betObj.betType);
            if (isFOBet) {
                betStr += `FOR ${betObj.currentPool.poolId} `;
                let tmp = [];
                betObj.combs.forEach((comb) => {
                    tmp.push(`0,${comb.combId}@${comb.currentOdds}`);
                });
                betStr += tmp.join('+');
            } else {
                betStr += betObj.venueid + interval + betObj.dayid + interval;
                if (isMultiRace || isCWIN || betObj.allupFormula) {
                    const isAllUp = [...racingConfig?.ALUP_PAGE, 'CWAALLALUP'].includes(betObj.betType);
                    if (isAllUp) {
                        betStr += 'ALUP' + interval + betObj.allupFormula;
                    }
                    betStr += getHRAllupBetLineStr(betObj, interval, isAllUp, isCWIN);
                } else {
                    betStr += getHRBetLineStr(betObj, interval);
                }
            }
            if (betObj.isRandom) {
                betStr += '(RG)';
            }
        }

        if (betObj.isFlexi) {
            betStr += '$$' + betObj.betTotal;
        } else {
            const unitBet = element.unitBet; // IB sendbet
            if (betObj.inplayDelay) {
                betStr += ' I$' + unitBet; // inplay
            } else {
                if (element.prod === 'M6') {
                    betStr += '$' + unitBet;
                } else if (element.prod === 'HR') {
                    const isFOBet = ['JKC', 'TNC'].includes(betObj.betType);
                    if (isFOBet) {
                        betStr += ' $' + unitBet;
                        if (element.counterOfferData) {
                            betStr += '$' + element.counterOfferData.systemCounterOfferCount;
                            FBdetail += '$' + element.counterOfferData.systemCounterOfferCount;
                        }
                    } else {
                        betStr += '$' + unitBet;
                    }
                } else {
                    betStr += ' $' + unitBet;
                }
            }
            // const unitBet = betObj.unitBet || element.unitBet * betObj.noOfBets;
            if (element.prod === 'FB') {
                FBdetail += '$' + unitBet;
                if (element.counterOfferData) {
                    betStr += '$' + element.counterOfferData.systemCounterOfferCount;
                    FBdetail += '$' + element.counterOfferData.systemCounterOfferCount;
                } else {
                    betStr += '$0000';
                }
            }
        }

        if (element.prod === 'HR') {
            const isFOBet = ['JKC', 'TNC'].includes(betObj.betType);
            if (isFOBet) {
                betStr += '$0000';
            }
        }

        if (opt?.validType === 'sendbet') {
            element.betLineGuid= !element.betLineGuid ? getUuid(betStr) : element.betLineGuid;
        }

        sendbetArr.push({
            betline: betStr,
            betLineGuid: element.betLineGuid,
            atDetail: FBdetail
        });
        betStr += '\\\\';

        betStrLength += betStr;
        betStr = '';
        FBdetail = '';
    }
    consoleLog('sendbet info',sendbetArr)
    if (opt?.validType === 'sendbet') {
        return sendbetArr;
    }

    return betStrLength.length;
};

const getM6BetLineStr = (betObj, interval) => {
    const plusSign = '+';
    let bankerArr = [];
    let legArr = [...betObj.numList];
    let betStr = '';
    if (betObj.bankerList && betObj.bankerList.length > 0) {
        bankerArr = [...betObj.bankerList];
        bankerArr.map((item, index) => {
            betStr += item;
            if (index !== bankerArr.length - 1) {
                betStr += plusSign;
            }
        });
        betStr += '>';
    }
    legArr.map((item, index) => {
        betStr += item + (index === legArr.length - 1 ? interval : plusSign);
    });
    return betStr;
};
const getFBAllupBetLineStr = (betObj, interval, detail) => {
    const { betList, betType, selectedFormula } = betObj;
    let betStr = betType + interval + selectedFormula;
    betList.map((item, index) => {
        betStr += '/';
        betStr += getFBBetLineStr(item, interval, detail);
    });
    return betStr;
};
const getFBBetLineStr = (item, interval, detail) => {
    let betStr = '';
    let detailStr = '';
    const plusSign = '+';
    let curPoolId = null;
    const { TournamentAllupPools } = GetFootballConfig();
    item.combs.map((subItem, index) => {
        const [betType, lineId, combId, poolId, comb] = subItem.id.split('_');
        if (TournamentAllupPools.includes(betType)) {
            if (curPoolId != poolId) {
                curPoolId = poolId;
                if (index !== 0) {
                    betStr += '/';
                    detailStr += '/';
                }
                betStr += poolId + interval;
                detailStr += item.frontEndId + interval;
            } else {
                betStr += plusSign;
                detailStr += plusSign;
            }
        } else {
            if (index === 0) {
                betStr += poolId + interval;
                detailStr += item.frontEndId + interval;
            } else if (index === item.combs.length - 1) {
                betStr += plusSign;
                detailStr += plusSign;
            } else {
                betStr += plusSign;
                detailStr += plusSign;
            }
        }
        betStr += lineId + ',' + combId + '@' + subItem.oddsVal;

        detailStr += betType + getCurrentCombDetail(subItem, interval) + '@' + subItem.oddsVal;
    });
    return detail ? detailStr : betStr;
};

const getCurrentCombDetail = (item, interval) => {
    const [betType, lineId, combId, poolId, comb] = item.id.split('_');
    const { TournamentAllupPools, ShowTeamNameType, GoalLinePools, HandicapPools } = GetFootballConfig();
    let curComb = interval + comb;
    if (ShowTeamNameType.includes(betType)) {
        const { currentPool, currentCombNameObj } = item;
        curComb = `${TournamentAllupPools.includes(betType) ? currentPool?.instNo : interval + currentCombNameObj?.str}` + interval + currentCombNameObj?.name_en;
    }
    if (GoalLinePools.includes(betType)) {
        const condition = !item.condition || item.condition === '0.0' ? '' : item.lineNum ? `[${item.lineNum}]` : `[${item.condition}]`;
        const { currentCombNameObj } = item;
        curComb = interval + currentCombNameObj?.name_en + condition;
    }
    if (HandicapPools.includes(betType)) {
        const condition = `[${item.condition}]`;
        curComb = interval + comb + condition;
    }
    return curComb;
};
const getHRAllupBetLineStr = (betObj, interval, isAllUp, isCWIN) => {
    const { betList } = betObj;
    let betStr = '';
    betList.map((item, index) => {
        if (betObj.betType === 'TT' && betList.length === 6 && index === 3) {
            betStr += '|';
        } else if (!(!isAllUp && index === 0)) {
            if (isCWIN) {
                index == 0?betStr += ' ': betStr += '+';
            } else {
                betStr += '/';
            }
        }
        betStr += getHRBetLineStr(item, interval, index, isAllUp, isCWIN, betObj);

        if (index !== betList.length - 1) {
            betStr = betStr.substring(0, betStr.length - 1);
        } else {
            if ( isCWIN ) {
                 betStr = betStr.substring(0, betStr.length - 1) + ' ';
            }
        }
    });
    return betStr;
};
export const getHRBetLineStr = (supItem, interval, curIndex = 0, isAllUp = false, isCWIN, betObj, addInterval) => {
    let betStr = '';
    const plusSign = '+';
    if (supItem.betType) {
        if (curIndex === 0 || isAllUp) {
            let bType = supItem.betType.length === 2 ? supItem.betType.split('').join('-') : supItem.betType;
            if (bType == 'SixUP') bType = '6UP';
            betStr += bType + interval;
        }
        if (supItem.subType && supItem.subType != undefined) {
            betStr += supItem.subType + interval;
        }
    }
    if ((!isAllUp && curIndex === 0) || isAllUp) {
        const raceno = supItem.raceno || supItem.raceNo || betObj.raceno;
        if (isCWIN) {
            betStr += betObj.betType + ' ';
        }

        betStr += raceno + '*';
    }

    if (isCWIN) {
        betStr += supItem.composite + plusSign;
    } else {
        betStr += getHRBankAndLegStr(supItem, addInterval);
    }
    return betStr;
};

export const getHRBankAndLegStr = (supItem, addInterval) => {
    let betStr = '';
    const interval = ' ';
    const plusSign = supItem.betType === 'IWN' ? '#' : '+';
    const isSubCWIN = ['CWA', 'CWB', 'CWC'].includes(supItem.betType);
    supItem.bankers?.map((item) => {
        if (item.length > 0) {
            item.map((subItem, subIndex) => {
                betStr += subItem.no;
                if (subIndex !== item.length - 1) {
                    betStr += plusSign;
                } else {
                    betStr += '>';
                }
            });
        }
    });
    supItem.combs.map((item, index) => {
        let hNo = isSubCWIN ? item.composite : item.no;
        betStr += hNo + (index === supItem.combs.length - 1 ? interval : plusSign);
    });
    if (addInterval) {
        if (isSubCWIN) {
            betStr = betStr.split('+').join(' + ');
        } else {
            // const reg=/[\+\/\$#]/g;
            const reg = /[\+]/g;
            betStr = betStr.replace(reg, (s) => ` ${s} `);
        }
    }
    return betStr;
};

// panel allup function  //
export const fbAlupCalc = (panelAllupObjj, newPanelAllupData) => {
    let selsByMatch = panelAllupObjj.allupCheckList.reduce((init, next) => {
        const { id, lineNum, matchId, oddsVal } = next.combs[0];
        let key = panelAllupObjj.allupGroup === 1 ? matchId : next.raceno;
        let [tempOddsType, line, combId, _, comb] = id.split('_');
        init[key] = init[key] || [];
        init[key].push({ type: tempOddsType, line: lineNum, combId: combId, comb: comb, odds: parseFloat(oddsVal) });
        return init;
    }, {});
    // FBconfig context.content.config
    const linePools = GetFootballConfig().GoalLinePools;
    const HandicapPools = GetFootballConfig().HandicapPools;
    let noSelArr = [];
    let maxOddsArr = [];
    for (let idx in selsByMatch) {
        if (selsByMatch.hasOwnProperty(idx)) {
            selsByMatch[idx].sort((a,b) => {
                if(!a.line || !b.line) return 0
                let aLines = a.line.split('/');
                let bLines = b.line.split('/');
                if (parseFloat(aLines[0]) != parseFloat(bLines[0]))
                    return parseFloat(aLines[0]) - parseFloat(bLines[0]);
                else if (aLines.length==1 && bLines.length > 1)
                    return -1;
                else if (aLines.length>1 && bLines.length==1)
                    return 1;
                return parseInt(a.combid) - parseInt(b.combid);
            });
            noSelArr.push(selsByMatch[idx].length);
            var maxodds = Math.max(...selsByMatch[idx].map((x) => x.odds));
            if (linePools.includes(selsByMatch[idx][0].type)) {
                maxodds = getLinePoolsMaxOdds(selsByMatch[idx]);
            }
            if (HandicapPools.includes(selsByMatch[idx][0].type)) {
                maxodds = getHandicapPoolsMaxOdds(selsByMatch[idx]);
            }
            if (['AGS'].includes(selsByMatch[idx][0].type)) {
                maxodds = getAgsPoolsMaxOdds(selsByMatch[idx]);
            }
            maxOddsArr.push(maxodds);
        }
    }
    return fbCalMultiSel(noSelArr, maxOddsArr, newPanelAllupData);
};
export const getLinePoolsMaxOdds = (pool) => {
    try {
    let maxodds = Math.max(...pool.map((x) => x.odds));
    let maxHdcScore = 0, minHdcScore = 0, goalList = [];
    for (let j = 0; j < pool.length; j++) {
        pool[j].line.split('/').map(condItem => {
            if (minHdcScore > +condItem) {
                minHdcScore = condItem;
            }
            if (maxHdcScore < +condItem) {
                maxHdcScore = condItem;
            }
        })
    }
    maxHdcScore = Math.floor(Number(maxHdcScore) + 1);
    minHdcScore = Math.floor(Number(minHdcScore) - 1);
    const maxScore = (Math.abs(maxHdcScore) - Math.abs(minHdcScore)) > 0 ? Math.abs(maxHdcScore) : Math.abs(minHdcScore);
    const scoreNum = maxScore * 2 + 1;
    for (let j = 0; j < scoreNum; j++) {
        goalList.push(j - maxScore);
    }
    let maxOddsList = [];
    
    goalList.forEach(goal => {
        let addOdds = 0;
        pool.forEach(item => {
            let tempOdds = 0;
            let lOdds = 0;
            let rOdds = 0;
            const lineList = item.line.split('/');
            lineList.forEach((condItem, index) => {
                if ((goal - Number(condItem) > 0 && item.comb === 'H') || (goal - Number(condItem) < 0 && item.comb === 'L')) {
                    if (index == 0) {
                        lOdds += (lineList.length > 1) ? (item.odds / 2) : item.odds;
                    } else {
                        rOdds += item.odds / 2;
                    }
                }
                if ((goal - Number(condItem) == 0 && item.comb === 'H') || (goal - Number(condItem) == 0 && item.comb === 'L')) {
                    if (index == 0) {
                        lOdds += (lineList.length > 1 ? 1 : 2) / 2;
                    } else {
                        rOdds += 0.5;
                    }
                }
            })
            tempOdds = lOdds + rOdds;
            addOdds += tempOdds;
            
        })
        maxOddsList.push(addOdds);
    })
    maxodds = Math.max(...maxOddsList);
    return maxodds;
    } catch (err) {
        console.error('LinePools err', err);
    }
}
export const getHandicapPoolsMaxOdds = (pool) => {
    try {
    let maxodds = Math.max(...pool.map((x) => x.odds));
    let maxHdcScore = 0, minHdcScore = 0, goalList = [];
    for (let j = 0; j < pool.length; j++) {
        pool[j].line.split('/').map(condItem => {
            if (minHdcScore > +condItem) {
                minHdcScore = condItem;
            }
            if (maxHdcScore < +condItem) {
                maxHdcScore = condItem;
            }
        })
    }
    maxHdcScore = Math.floor(Number(maxHdcScore) + 1);
    minHdcScore = Math.floor(Number(minHdcScore) - 1);
    const maxScore = (Math.abs(maxHdcScore) - Math.abs(minHdcScore)) > 0 ? Math.abs(maxHdcScore) : Math.abs(minHdcScore);
    const scoreNum = maxScore * 2 + 1;
    for (let j = 0; j < scoreNum; j++) {
        goalList.push(j - maxScore);
    }
    let maxOddsList = [];

    if (['EHH', 'HHA'].includes(pool[0].type)) {
        goalList.forEach(goal => {
            let addOdds = 0;
            pool.forEach(item => {
                let tempOdds = 0;
                if ((goal + Number(item.line) > 0 && item.comb === 'H') || (-goal - Number(item.line) > 0 && item.comb === 'A') || (goal + Number(item.line) == 0 && item.comb === 'D')) {
                    tempOdds = item.odds;
                }
                addOdds += tempOdds;
            })
            maxOddsList.push(addOdds);
        })
    } else {
        goalList.forEach(goal => {
            let addOdds = 0;
            pool.forEach(item => {
                let tempOdds = 0;
                let lOdds = 0;
                let rOdds = 0;
                const lineList = item.line.split('/');
                lineList.forEach((condItem, index) => {
                    if ((goal + Number(condItem) > 0 && item.comb === 'H') || (-goal - Number(condItem) > 0 && item.comb === 'A')) {
                        if (index == 0) {
                            lOdds += (lineList.length > 1) ? (item.odds / 2) : item.odds;
                        } else {
                            rOdds += item.odds / 2;
                        }
                    }
                    if ((goal + Number(condItem) == 0 && item.comb === 'H') || (-goal - Number(condItem) == 0 && item.comb === 'A')) {
                        if (index == 0) {
                            lOdds += (lineList.length > 1 ? 1 : 2) / 2;
                        } else {
                            rOdds += 0.5;
                        }
                    }
                })
                tempOdds = lOdds + rOdds;
                addOdds += tempOdds;
            })
            maxOddsList.push(addOdds);
        })
    }
    maxodds = Math.max(...maxOddsList);
    return maxodds;
    } catch (err) {
        console.error('HandicapPools err', err);
    }
}
export const getAgsPoolsMaxOdds = (pool) => {
    let agsOdds = 0;
    pool.forEach(item=>{
        agsOdds += Number(item.odds);
    })
    return agsOdds;
}
export const fbCalMultiSel = (noOfSelArr, maxOddsArr, newPanelAllupData) => {
    const allSelection = alupFormulaItem[newPanelAllupData.alupFormula];
    let totalSel = 0;
    let tmpSel = 0;
    let tmpMaxDiv = 0;
    let estMaxDiv = 0;
    for (let i = 0; i < allSelection?.split('#').length; i++) {
        //allSelection == 1#2#3#12#13#23#123
        tmpSel = 1;
        tmpMaxDiv = 1;
        let tvalue = allSelection.split('#')[i]; //tvalue == 1, tvalue == 2, tvalue == 3, tvalue == 12
        if (tvalue.length == 1) {
            tmpSel = noOfSelArr[tvalue - 1];
            tmpMaxDiv = maxOddsArr[tvalue - 1];
        } else {
            for (var j = 0; j < tvalue.length; j++) {
                tmpSel *= noOfSelArr[tvalue.substr(j, 1) - 1];
                tmpMaxDiv *= maxOddsArr[tvalue.substr(j, 1) - 1];
            }
        }
        totalSel += tmpSel;
        estMaxDiv += tmpMaxDiv;
    }

    estMaxDiv *= newPanelAllupData.unitBet;

    return {
        noOfComb: totalSel,
        dividend: estMaxDiv.toFixed(1)
    };
};
