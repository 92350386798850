import { useTranslation } from 'react-i18next';
import React, { useEffect, useRef, useState } from 'react';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { Loading } from '../../Common/ui-components';
import { useWindowSize } from '../../Common/CommonHooks';
import { getTicketImg_IB } from '../BetSlipIBAPI';
import Popup from 'reactjs-popup';
import ImgLoading from '../../Common/ui-components/Loading';
import { textFormatAmountFn } from '../../Common/GlobalFunc';

// same format as transaction records
const formatDetails = (raw, lang) => {
    let formatted = raw.replace(/&nbsp;/g, ' ');
    const re = lang === 'eng' ? /\s\d+\s{5}\d/g : /\s場\s{4}\d/g;
    const indexes = [];
    while (re.test(formatted)) {
        indexes.unshift(re.lastIndex - 1);
    }
    for (const index of indexes) {
        formatted = formatted.slice(0, index) + '<br>' + formatted.slice(index);
    }

    // formatted = formatted.replace(/\+ <br>\s{16}/g, '+ ');
    formatted = formatted
        .split('<br>')
        .map((item) => item.trim())
        .join('<br>');

    return formatted;
};

export const formatDetailText = (rowData, lang, t) => {
    let text = rowData?.detail?.[lang];
    if (rowData.payoutType === 'HR' && text) {
        const raceLocalCode = rowData.detail?.raw?.split(' ')[0];
        let slNumber = ['HV', 'ST'].includes(raceLocalCode) && lang === 'eng' ? 4 : 3;
        if (lang === 'eng') {
            const betTypeCode = rowData.betType.raw;
            if (['QPL', 'F-F', 'CWB', 'CWC', 'D-T', 'T-T', '6UP', 'JKC', 'TNC', 'IWN'].includes(betTypeCode)) {
                slNumber += 1;
            }
            if (['W-P', 'CWA'].includes(betTypeCode)) {
                slNumber += 2;
            }
            if (['QQP'].includes(betTypeCode)) {
                slNumber += 3;
            }
        }
        text = text.split(' ')?.slice(slNumber).join(' ');
    }

    if (rowData?.txnFlag == '1') {
        text = text + t('ACCOUNT_STATEMENT_TRAN_CANCEL');
    }
    return formatDetails(text, lang);
};

export const formatBettypeText = (rowData, lang) => {
    let text = rowData?.betType?.[lang];
    if (rowData.payoutType === 'HR' && text) {
        const betTypeCode = rowData.betType.raw;
        if (['FCT', 'TCE', 'QTT'].includes(betTypeCode)) {
            text = text.split(' ')[0];
        }
    }
    return text;
};

export const checkDateTimeText = (rowData, delimiter = '/') => {
    const balanceStr = 'FUNDS AVAILABLE BALANCE';
    if (rowData?.betType?.raw?.includes(balanceStr)) {
        return '';
    }
    const dateTime = rowData.transactionDateTime.replace(/-/g, delimiter);
    return dateTime;
};

export const TableRowMobile = ({ rowData }) => {
    const { t, i18n } = useTranslation();
    const lang = i18n.language === 'en' ? 'eng' : 'chi';

    let raceDay = '';
    if (window.globalConfig.IS_IB) {
        raceDay = rowData.venueDay[lang].replace('<br>', ' ');
    } else if (rowData.payoutType === 'HR') {
        const raceDayCode = rowData.detail?.raw?.split(' ')?.slice(0, 2);
        raceDay = t(`LB_${raceDayCode[0]}`) + ' ' + t(`LB_${raceDayCode[1]}`);
    }

    return (
        <div className="ARResultTableRowM">
            <div className="ARResultTableRowM-header">
                <div>
                    {t('LB_BS_AR_TABLE_HEADER_REFNO')} {rowData?.transactionNumber?.[lang]}
                </div>
                {rowData?.eWallet == 1 && <EWalletDialog data={rowData} colorWhite />}
            </div>
            <div className="ARResultTableRowM-main">
                <div>
                    <div className="cell-l">{t('LB_BS_AR_TABLE_HEADER_DATETIME')}</div>
                    <div className="cell-r">{checkDateTimeText(rowData)}</div>
                </div>
                <div>
                    <div className="cell-l">{t('LB_BS_AR_TABLE_HEADER_RACEDAY')}</div>
                    <div className="cell-r">{raceDay}</div>
                </div>
                <div>
                    <div className="cell-l">{t('LB_BS_AR_TABLE_HEADER_BETTYPE')}</div>
                    <div
                        className="cell-r"
                        dangerouslySetInnerHTML={{ __html: formatBettypeText(rowData, lang) }}
                    ></div>
                </div>
                <div>
                    <div className="cell-l">{t('LB_BS_AR_TABLE_HEADER_DETAIL')}</div>
                    <div className="cell-r" dangerouslySetInnerHTML={{ __html: formatDetailText(rowData, lang, t) }}></div>
                </div>
                <div>
                    <div className="cell-l">{t('LB_BS_AR_TABLE_HEADER_DEBIT')}</div>
                    <div className="cell-r">{formatAmount(rowData?.debitAmount?.[lang])}</div>
                </div>
                <div>
                    <div className="cell-l">{t('LB_BS_AR_TABLE_HEADER_CREDIT')}</div>
                    <div className="cell-r">{formatAmount(rowData?.creditAmount?.[lang])}</div>
                </div>
            </div>
        </div>
    );
};

const TableMobile = ({ data, isLoading, isNextPageLoading }) => {
    const { t, i18n } = useTranslation();
    const tableRef = useRef();

    const table = data.map((item, index) => (
        <TableRowMobile rowData={item} key={item.transactionNumber.eng + 'm' + index} />
    ));

    return (
        <div className="ARResultTableM" ref={tableRef}>
            <div className="ARResultTableM-container">
                {table}
                {(isLoading || isNextPageLoading) && (
                    <div className="wait-mobile">
                        <Loading />
                    </div>
                )}
            </div>
        </div>
    );
};

export const formatAmount = (amount) => {
    if (amount == '') {
        return amount;
    }
    return '$' + textFormatAmountFn(amount.replace('$', ''), 2, true);
};

export const EWalletDialog = (props) => {
    const { data, colorWhite } = props;
    const [imgSrc, setImgSrc] = useState('');
    const { isMobileSize } = useWindowSize();
    const { t, i18n } = useTranslation();

    const handleGetImg = async () => {
        if (imgSrc) {
            return;
        }
        const params = data.rowStr;
        const responseData = await getTicketImg_IB(params);
        if (responseData?.image) {
            setImgSrc(responseData.image);
        }
    };
    const handleImgClose = () => {};

    const saveImg = () => {
        if (!imgSrc) {
            return;
        }
        const base64 = window.atob(imgSrc);
        let byteNumbers = new Array(base64.length);
        for (let i = 0; i < base64.length; i++) {
            byteNumbers[i] = base64.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], {
            type: 'image/png'
        });
        let aLink = document.createElement('a');
        aLink.download = 'receipt.png';
        aLink.href = URL.createObjectURL(blob);
        document.body.appendChild(aLink);
        aLink.click();
        aLink.parentNode.removeChild(aLink);
    };

    const CustomScrollbars = (props) => <Scrollbars autoHeight autoHeightMin={140} autoHeightMax={340} {...props} />;

    return (
        <Popup
            className="ewalletDialog"
            onOpen={handleGetImg}
            onClose={handleImgClose}
            trigger={
                <div className={`icon-receipt-${isMobileSize || colorWhite ? 'white' : 'black'} cursor-pointer`}></div>
            }
            modal
            nested
        >
            <div className="ewalletDialog-container">
                <div className={`downloadBtn ${!imgSrc ? 'dimm' : ''}`} onClick={saveImg}>
                    {t('LB_BS_AR_DOWNLOAD')}
                </div>
                <div className="imgArea">
                    <CustomScrollbars>
                        {imgSrc ? <img src={`data:image/png;base64,${imgSrc}`} /> : <ImgLoading />}
                    </CustomScrollbars>
                </div>
            </div>
        </Popup>
    );
};

const SearchResult = ({
    data,
    isShowResult,
    searchParams,
    currentDate,
    handleClickNewSearch,
    paginationData,
    updatePaginationDate,
    handleSearchData,
    isLoading,
    isNextPageLoading,
    currentTab
}) => {
    const { t, i18n } = useTranslation();
    const throttleSwitchRef = useRef(true);
    const tableMRef = useRef();
    const [isMobileSize, setIsMobileSize] = useState(true);
    const mobileSizeRef = useRef(true);

    useEffect(() => {
        function handleResize() {
            let isMobileSize = window.innerWidth < 800 || document.documentElement?.clientWidth < 800;
            setIsMobileSize(isMobileSize);
            mobileSizeRef.current = isMobileSize;
        }
        window.addEventListener('resize', handleResize);
        handleResize();
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        let ARResultTable = document.querySelector('.ARInbox-scrollbars>div:first-child');
        // let ARResultTableM = document.querySelector('.ARtableM-scrollbars>div:first-child');
        window?.addEventListener('scroll', onScrollHandleAR);
        ARResultTable?.addEventListener('scroll', onScrollHandleAR);

        return () => {
            window?.removeEventListener('scroll', onScrollHandleAR);
            ARResultTable?.removeEventListener('scroll', onScrollHandleAR);
        };
    }, [onScrollHandleAR, data, isLoading, isNextPageLoading, isMobileSize, currentTab]);
    const toBottomHandle = useRef()
    const onScrollHandleAR = (e) => {
        const _window = e.target;
        clearTimeout(toBottomHandle.current)
        const isCurTab = currentTab == 2;
        if (isLoading || isNextPageLoading || !isCurTab) return;
        toBottomHandle.current = setTimeout(() => {
            let scrollTop = _window.scrollTop || document.documentElement.scrollTop;
            let scrollHeight = _window.scrollHeight || document.documentElement.scrollHeight;
            let windowHeight = window.innerHeight;
            // let triggerPoint = mobileSizeRef.current ? 219 : 0;
            let isBottom = scrollHeight <= windowHeight + scrollTop && scrollTop > 0;
            let triggerPoint = scrollHeight <= windowHeight + scrollTop + 70 && scrollTop > 0;

            if (triggerPoint && throttleSwitchRef.current && isMobileSize) {
                // throttleSwitchRef.current = false;
                onChangePageNumber('NEXT');
    
                // setTimeout(() => {
                //     throttleSwitchRef.current = true;
                //     e.target.scrollTo(0, scrollTop - 1);
                // }, 100);
            }
        },100)
    };

    const onChangePageNumber = (action) => {
        if (isLoading || isNextPageLoading) {
            return;
        }

        switch (action) {
            case 'PREV':
                if (paginationData.pageNumber > 1) {
                    updatePaginationDate({
                        ...paginationData,
                        pageNumber: paginationData.pageNumber - 1
                    });
                }
                return;
            case 'NEXT':
                if (paginationData.pageNumber < paginationData.totalPage || paginationData.nextPage) {
                    if (paginationData.pageNumber < paginationData.totalPage) {
                        updatePaginationDate({
                            ...paginationData,
                            pageNumber: paginationData.pageNumber + 1
                        });
                    } else {
                        handleSearchData(true);
                    }
                }
                return;
            default:
                return;
        }
    };

    const onClickShowMore = () => {
        onChangePageNumber('NEXT');
    };

    // dd/MM/yyyy
    const getDateString = (Date) => {
        return typeof Date === 'string' ? Date : Date.toLocaleDateString('zh-HK')
            .split('/')
            .map((i) => {
                if (i < 10) {
                    return '0' + i;
                }
                return i;
            })
            .join('/');
    };

    const ResultHeader = () => {
        const headerLabel =
            searchParams.searchType === 'last'
                ? t(`LB_BS_AR_HEADER_TEXT_${searchParams.searchType.toUpperCase()}`).replace(
                      '{0}',
                      searchParams.lastDay
                  )
                : t(`LB_BS_AR_HEADER_TEXT_RANGE`);

        return (
            <div className="acctstmt-header">
                <div className="header-l">
                    <div>
                        {headerLabel}
                        {searchParams.searchType !== 'last' ? (
                            <span className="ARDateRange">
                                {' '}
                                {getDateString(currentDate.startDate)} - {getDateString(currentDate.endDate)}
                            </span>
                        ) : (
                            ''
                        )}
                        <span></span>
                    </div>
                </div>
                <div className="header-r">
                    <div className="new-search-btn cta_brand" onClick={handleClickNewSearch}>
                        {t('LB_BS_AR_NEW_SEARCH')}
                    </div>
                </div>
            </div>
        );
    };

    const TableHeader = () => {
        return (
            <tr className="table-row table-header">
                <td className="table-cell">{t('LB_BS_AR_TABLE_HEADER_REFNO')}</td>
                <td className="table-cell ar-header-datetime">{t('LB_BS_AR_TABLE_HEADER_DATETIME')}</td>
                <td className="table-cell">{t('LB_BS_AR_TABLE_HEADER_RACEDAY')}</td>
                <td className="table-cell">{t('LB_BS_AR_TABLE_HEADER_BETTYPE')}</td>
                <td className="table-cell">{t('LB_BS_AR_TABLE_HEADER_DETAIL')}</td>
                <td className="table-cell ar-receipt">{t('LB_BS_AR_TABLE_HEADER_RECEIPT')}</td>
                <td className="table-cell">{t('LB_BS_AR_TABLE_HEADER_DEBIT')}</td>
                <td className="table-cell">{t('LB_BS_AR_TABLE_HEADER_CREDIT')}</td>
            </tr>
        );
    };

    const TableRow = ({ rowData, className }) => {
        const lang = i18n.language === 'en' ? 'eng' : 'chi';
        let raceDay = '';
        if (window.globalConfig.IS_IB) {
            raceDay = rowData.venueDay[lang];
        } else if (rowData.payoutType === 'HR') {
            const raceDayCode = rowData.detail?.raw?.split(' ')?.slice(0, 2);
            raceDay = t(`LB_${raceDayCode[0]}`) + '\n' + t(`LB_${raceDayCode[1]}`);
        }

        return (
            <tr className={`table-row ${className}`}>
                <td className="table-cell">{rowData?.transactionNumber?.[lang]}</td>
                <td className="table-cell">{checkDateTimeText(rowData)}</td>
                <td className="table-cell ar-raceday" dangerouslySetInnerHTML={{ __html: raceDay }}></td>
                <td
                    className="table-cell ar-bettype"
                    dangerouslySetInnerHTML={{ __html: formatBettypeText(rowData, lang) }}
                ></td>
                <td
                    className="table-cell ar-detail"
                    dangerouslySetInnerHTML={{ __html: formatDetailText(rowData, lang, t) }}
                ></td>

                <td className="table-cell ar-receipt">{rowData?.eWallet == 1 && <EWalletDialog data={rowData} />}</td>
                <td className="table-cell ">{formatAmount(rowData?.debitAmount?.[lang])}</td>
                <td className="table-cell ar-credit-amount">{formatAmount(rowData?.creditAmount?.[lang])}</td>
            </tr>
        );
    };

    const Table = ({ className, tableData }) => {
        const table = tableData.map((item, index) => (
            <TableRow
                rowData={item}
                className={index % 2 ? 'sec' : ''}
                key={item.transactionNumber?.eng + 'd' + index}
            />
        ));

        return (
            <div>
                {!className && <div className={`table-header-shadow`}></div>}
                <table className={`ARResultTable ${className ? className : ''}`}>
                    <TableHeader />
                    {table}
                </table>
            </div>
        );
    };

    return (
        <>
            {isShowResult ? (
                <div className="search-result-container">
                    <ResultHeader />
                    <Table className="print-table" tableData={data} />
                    <Table tableData={data} />
                    {isLoading ? (
                        <div className="wait">
                            <Loading />
                            {/* {t("LB_BETSLIP_INPLAYMSGTEXT2")}.. */}
                        </div>
                    ) : null}
                    {!isLoading && data.length <= 0 ? (
                        <p className="no-records">{t('LB_BETSLIP_ACCOUNTRECORDS_AR_NO_RECORDS')}</p>
                    ) : null}
                    {!isLoading && !isMobileSize && data.length > 0 && paginationData.nextPage ? (
                        <div className="FBMatchListLoadMoreContainer" onClick={onClickShowMore}>
                            <div className="FBMatchListLoadMoreButton">{t('LB_FB_SHOW_MORE')}</div>
                        </div>
                    ) : null}
                    <TableMobile
                        ref={tableMRef}
                        data={data}
                        isLoading={isLoading}
                        isNextPageLoading={isNextPageLoading}
                        onChangePageNumber={onChangePageNumber}
                    />
                </div>
            ) : null}
        </>
    );
};

export default SearchResult;
