import React, { useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { HorseName, MobileHorseName, Jockey, Trainer, getRunnerData } from './RCOddsTableBase';
import { RacingContext } from '../../Home/RacingPage';
import { isLocalMeeting } from '../../Common/home-common';
import { useWindowSize } from '../../Common/CommonHooks';

const ResultTable = () => {
    const context = useContext(RacingContext);
    const mtg = context.content.meeting;
    const race = mtg.races.filter((x) => x.no == context.content.raceNo)[0];
    const winPool = mtg.poolInvs.filter((x) => x.leg.races[0] === context.content.raceNo && x.oddsType === 'WIN')[0];
    const showWinOddsCol = winPool?.status.indexOf('REFUND') < 0;
    const { isMobileSize } = useWindowSize();
    return (
        <div className={`race-result-table-container ${isMobileSize ? 'mobile' : ''}`}>
            <Table showWinOddsCol={showWinOddsCol} race={race} />
        </div>
    );
};

const Row = ({ runner, className, showWinOddsCol }) => {
    const { t, i18n } = useTranslation();
    const context = useContext(RacingContext);
    const mtg = context.content.meeting;
    const isLocal = isLocalMeeting(context.content.venue);
    const { isMobileSize } = useWindowSize();

    const runnerData = getRunnerData(
        { win: true },
        context,
        t,
        isLocal,
        mtg,
        runner,
        context.content.raceNo,
        null,
        i18n.language
    );

    return (
        <>
            {!isMobileSize ? (
                <div className={`${className} race-result-table-row`}>
                    <div>
                        {runner.finalPosition}
                        {runner.deadHeat ? ` (${t('LB_RC_RESULT_DH')})` : ''}
                    </div>
                    <div>{runner.no}</div>
                    <div>
                        <HorseName
                            t={t}
                            isLocal={isLocal}
                            name={runnerData.name}
                            racingColorFileName={runnerData.racingColorFileName}
                        />
                    </div>
                    <div>
                        <Jockey t={t} isLocal={isLocal} jockyId={runnerData.jockyId} jocky={runnerData.jocky} alw="" />
                    </div>
                    <div>
                        <Trainer t={t} isLocal={isLocal} trnId={runnerData.trnId} trn={runnerData.trn} />
                    </div>
                    <div>{runnerData.wt}</div>
                    <div>{runnerData.draw}</div>
                    {showWinOddsCol && <div>{runner.winOdds}</div>}
                </div>
            ) : (
                <div className={`${className} race-result-table-row-m`}>
                    <div>
                        <div className="race-position-m">{runner.finalPosition}</div>
                        <br />
                        {runner.deadHeat ? ` (${t('LB_RC_RESULT_DH')})` : <div style={{ opacity: 0 }}>{` (${t('LB_RC_RESULT_DH')})`}</div>}
                    </div>
                    <div className="runner-no">{runner.no}</div>
                    <div>
                        <MobileHorseName t={t} hyperlink={true} runner={runnerData} isLocal={isLocal} columns={{}} />
                    </div>
                    <div className="wt">{runnerData.wt}</div>
                    <div className="draw">{runnerData.draw}</div>
                    {showWinOddsCol && <div className="odds">{runner.winOdds}</div>}
                </div>
            )}
        </>
    );
};

const Table = ({ showWinOddsCol, race }) => {
    let cnt = 0;
    let table = [];
    for (let i = 1; i <= 4; i++) {
        let runners = race.runners.filter((x) => x.finalPosition == i);
        runners.forEach((item, index) => {
            table.push(
                <Row
                    showWinOddsCol={showWinOddsCol}
                    runner={item}
                    className={`table-row ${cnt++ % 2 ? '' : 'sec'}`}
                    key={item.no}
                />
            );
        });
    }

    return (
        <div className="race-result-table" id='raceResultTable'>
            <TableHeader showWinOddsCol={showWinOddsCol} />
            {table}
        </div>
    );
};

const TableHeader = ({ showWinOddsCol }) => {
    const { t } = useTranslation();
    const { isMobileSize } = useWindowSize();
    return (
        <>
            {!isMobileSize ? (
                <div className="table-row race-result-table-header">
                    <div style={{ width: '10%' }}>{t('LB_RC_RESULT_POSITION')}</div>
                    <div style={{ width: '6%' }}>{t('LB_RC_ODDS_TABLE_NO')}</div>
                    <div className="horseName">{t('LB_RC_ODDS_TABLE_HRNAME')}</div>
                    <div style={{ width: '16%' }} className="jocky">
                        {t('LB_RC_ODDS_TABLE_JOCKEY')}
                    </div>
                    <div style={{ width: '16%' }} className="trainer">
                        {t('LB_RC_ODDS_TABLE_TRAINER')}
                    </div>
                    <div style={{ width: '8%' }}>{t('LB_RC_ODDS_TABLE_WT')}</div>
                    <div style={{ width: '8%' }}>{t('LB_RC_ODDS_TABLE_DRAW')}</div>
                    {showWinOddsCol && <div style={{ width: '8%' }}>{t('LB_RC_RESULT_WINODDS')}</div>}
                </div>
            ) : (
                <div className="table-row race-result-table-header-m">
                    <div className="position" >{t('LB_RC_RESULT_POSITION')}</div>
                    <div className="runner-no" >{t('LB_RC_ODDS_TABLE_NO')}</div>
                    <div></div>
                    <div className="wt" >{t('LB_RC_ODDS_TABLE_WT')}</div>
                    <div className="draw" >{t('LB_RC_ODDS_TABLE_DRAW')}</div>
                    {showWinOddsCol && <div className="odds" >{t('LB_RC_RESULT_WINODDS')}</div>}
                </div>
            )}
        </>
    );
};

export default ResultTable;
