import Modal from 'react-bootstrap/Modal';
import { useTranslation } from 'react-i18next';
import { useBetSlipMsgQuery } from '../Home/Data/SitecoreDataHooks';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { TandCText } from '../BetSlip/LoginMenu';
import '../Home/Login/index.scss';
import { useEffect } from 'react';

const CustomScrollbars = (props) => (
    <Scrollbars
        autoHeight
        autoHeightMin={440}
        autoHeightMax={440}
        autoHide
        autoHideTimeout={1000}
        autoHideDuration={200}
        {...props}
    />
);

// const closeCandTFn = () => {
//     setIsShowTandC(false);
//     if (accessToken) {
//         setShowUserInfo(true);
//         if(window.history.state.idx > 0) {
//             navigate(-1);
//         } else {
//             navigate(`/${i18n.language}/racing`);
//         }

//     }
// };


export const TCModal = (props) => {
    const { onHide,show } = props;
    const { t } = useTranslation();
    const { msgLoading, getBetSlipLoginTnC } = useBetSlipMsgQuery();

    let textData = '';
    if (!msgLoading) {
        textData = getBetSlipLoginTnC();
        if (textData === '') {
            onHide();
        }
    }
    const handleEnterKey = (event) => {
        if (event.key === "Enter") {
            onHide();
        }
    };
    useEffect(() => {

        if(show)
            window.addEventListener("keydown", handleEnterKey);
        return () => {
            window.removeEventListener("keydown", handleEnterKey);
        };
    }, [show]);

    return !msgLoading && textData !== '' ? (
        <Modal
            backdrop="static"
            style={{ zIndex: 100001 }}
            {...props}
            size="lg"
            dialogClassName="custom-modal tc-modal"
            aria-labelledby="tc-modal"
            centered
            backdropClassName="tc-modal-container"
            keyboard = {false}
        >
            <Modal.Header>
                <Modal.Title id="tc-modal">{t('LB_BS_LOGIN_TANDC')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <CustomScrollbars>
                    <div className="tc-content">
                        <TandCText textData={textData} />
                    </div>
                </CustomScrollbars>
                <div className="button-c">
                    <button
                        className="button proceed-button"
                        onClick={() => {
                            onHide();
                        }}
                    >
                        {t('LB_BS_LOGIN_TANDCBTN')}
                    </button>
                </div>
            </Modal.Body>
        </Modal>
    ) : null;
};